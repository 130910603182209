import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";
import { RestService } from 'app/_services/rest.service';
import { map, startWith } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent, MatSnackBar } from '@angular/material';
import { ClasseProduto } from 'app/_models/classeProduto';
import { Banco } from 'app/_models/produtoNovo';
import { Convenio } from 'app/_enums/convenio.enum';
import { WebCertificadoTipo } from 'app/_models/webCertificadoTipo';
import { WebCertificadoTipoPessoa } from 'app/_models/webCertificadoTipoPessoa';


@Component({
    selector: 'app-produto-novo',
    templateUrl: './produto-novo.component.html',
    styleUrls: ['./produto-novo.component.less']
})
export class ProdutoNovoComponent implements OnInit {
    pathId = null;
    isLoading: boolean = false;
    certificadoFlag: boolean = false;
    certificadoRequestFlag: boolean = false;

    classesProdutos: ClasseProduto[] = [];
    filteredClassesProdutos!: Observable<ClasseProduto[]>;
    classeControl = new FormControl('', Validators.required);

    bancos: Banco[] = [];
    filteredBancos!: Observable<Banco[]>;
    bancoControl = new FormControl('', Validators.required);

    tiposCertificado: WebCertificadoTipo[] = [];
    filteredTiposCertificado!: Observable<WebCertificadoTipo[]>;
    tipoCertificadoControl = new FormControl('');

    tiposPessoaCertificado: WebCertificadoTipoPessoa[] = [];
    filteredTiposPessoaCertificado!: Observable<WebCertificadoTipoPessoa[]>;
    tipoPessoaCertificadoControl = new FormControl('');

    // TODO: verificar o controle de required de tipoCert e tipoPessoaCert
    produtoForm = new FormGroup({
        id: new FormControl(''),
        descricao: new FormControl('', [Validators.required, Validators.maxLength(150)]),
        imagemUrl: new FormControl(''),
        imagemBase64: new FormControl(null),
        tabela: new FormControl(''),
        classe: new FormControl(this.classeControl),
        banco: new FormControl(this.bancoControl),
        promocoes: new FormControl(''),
        ativo: new FormControl(false, Validators.required),
        tipoCert: new FormControl(''),
        tipoPessoaCert: new FormControl(''),
        descricaoEcommerce: new FormControl(''),
        imagemEcommerce: new FormControl(''),
        valAdesao: new FormControl(0.0),
        valAtivacao: new FormControl(null),
    });
    

    constructor(
        private _restService: RestService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private _snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                const id = params.get('id');
                this.pathId = id;

                this.getProduto(parseInt(id));
            }

            this.getClassesAndBancos();
        });

        this.setUpperCase();
        this.checkSelectedClasse();
    }

    setUpperCase() {
        this.produtoForm.get('descricao').valueChanges.subscribe(value => {
            if (value) {
                this.produtoForm.get('descricao').setValue(value.toUpperCase(), { emitEvent: false });
            }
        });

        this.produtoForm.get('tabela').valueChanges.subscribe(value => {
            if (value) {
                this.produtoForm.get('tabela').setValue(value.toUpperCase(), { emitEvent: false });
            }
        });
    }

    checkSelectedClasse() {
        this.classeControl.valueChanges.subscribe(value => {
            if (value && value.convenio && value.convenio.id === Convenio.ID_CERTIFICADOS_DIGITAIS) {
                this.certificadoFlag = true;

                // Faz a requisição apenas uma vez
                if (!this.certificadoRequestFlag) {
                    this.getCertificadoTipoAndTipoPessoa();
                    this.certificadoRequestFlag = true;
                } 
            } else {
                // Reseta os campos quando a classe escolhida não for um certificado digital
                this.resetControls();

                this.certificadoFlag = false;
            }
        })
    }

    resetControls() {
        this.produtoForm.get('tipoCert').reset();
        this.produtoForm.get('tipoPessoaCert').reset();

        this.tipoCertificadoControl.reset();
        this.tipoPessoaCertificadoControl.reset();
    }

    getClassesAndBancos() {
        const classes$ = this._restService.getAllClassesProdutos();
        const bancos$ = this._restService.getAllBancos();

        forkJoin([classes$, bancos$]).subscribe(([classesRes, bancosRes]) => {
            this.classesProdutos = classesRes;
            this.filteredClassesProdutos = this.classeControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filterClasses(value))
            );

            this.bancos = bancosRes;
            this.filteredBancos = this.bancoControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filterBancos(value))
            );
        }, error => {
            console.error('Erro: ', error.error.errors[0]);
            alert(`Erro: ${error.error.errors[0]}`);
        })
    }

    getCertificadoTipoAndTipoPessoa() {
        const certificadoTipos$ = this._restService.getWebCertificadoTipo();
        const certificadoTiposPessoa$ = this._restService.getWebCertificadoTipoPessoa();

        forkJoin([certificadoTipos$, certificadoTiposPessoa$]).subscribe(([certificadoTiposRes, certificadoTiposPessoasRes]) => {
            this.tiposCertificado = certificadoTiposRes;
            this.filteredTiposCertificado = this.tipoCertificadoControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filterCertificadoTipo(value))
            );

            this.tiposPessoaCertificado = certificadoTiposPessoasRes;
            this.filteredTiposPessoaCertificado = this.tipoPessoaCertificadoControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filterCertificadoTipoPessoa(value))
            );
        }, error => {
            console.error('Erro: ', error.error.errors[0]);
            alert(`Erro: ${error.error.errors[0]}`);
        })
    }

    // Controla a visualização da classe no campo de autocomplete
    displayClasseProduto(classeProduto: ClasseProduto): string {
        return classeProduto && classeProduto.descricao ? classeProduto.descricao : '';
    }

    // Controla a visualização do banco no campo de autocomplete
    displayBanco(banco: Banco): string {
        return banco && banco.descricao ? banco.descricao : '';
    }

    // Controla a visualização do tipoCert no campo de autocomplete
    displayTipoCert(tipoCert: WebCertificadoTipo): string {
        return tipoCert && tipoCert.tipo ? tipoCert.tipo : '';
    }

    // Controla a visualização do tipoPessoaCert no campo de autocomplete
    displayTipoPessoaCert(tipoPessoaCert: WebCertificadoTipoPessoa): string {
        return tipoPessoaCert && tipoPessoaCert.tipoPessoa ? tipoPessoaCert.tipoPessoa : '';
    }

    // Filtro para o autocomplete de Classe Produto
    private _filterClasses(value: ClasseProduto | string): ClasseProduto[] {
        if (!this.classesProdutos || this.classesProdutos.length === 0) {
            return [];
        }

        let filterValue: string;

        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && value.descricao) {
            filterValue = value.descricao.toLowerCase();
        } else {
            filterValue = '';
        }

        return this.classesProdutos.filter(classe => classe.descricao.toLowerCase().includes(filterValue));
    }

    // Filtro para o autocomplete de Banco
    private _filterBancos(value: Banco | string): Banco[] {
        if (!this.bancos || this.bancos.length === 0) {
            return [];
        }

        let filterValue: string;

        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && value.descricao) {
            filterValue = value.descricao.toLowerCase();
        } else {
            filterValue = '';
        }

        return this.bancos.filter(banco => banco.descricao.toLowerCase().includes(filterValue));
    }

    // Filtro para o autocomplete de WebCertificadoTipo
    private _filterCertificadoTipo(value: WebCertificadoTipo | string): WebCertificadoTipo[] {
        if (!this.tiposCertificado || this.tiposCertificado.length === 0) {
            return [];
        }

        let filterValue: string;

        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && value.tipo) {
            filterValue = value.tipo.toLowerCase();
        } else {
            filterValue = '';
        }

        return this.tiposCertificado.filter(tipoCertificado => tipoCertificado.tipo.toLowerCase().includes(filterValue));
    }

    // Filtro para o autocomplete de WebCertificadoTipoPessoa
    private _filterCertificadoTipoPessoa(value: WebCertificadoTipoPessoa | string): WebCertificadoTipoPessoa[] {
        if (!this.tiposPessoaCertificado || this.tiposPessoaCertificado.length === 0) {
            return [];
        }

        let filterValue: string;

        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && value.tipoPessoa) {
            filterValue = value.tipoPessoa.toLowerCase();
        } else {
            filterValue = '';
        }

        return this.tiposPessoaCertificado.filter(tipoPessoaCertificado => tipoPessoaCertificado.tipoPessoa.toLowerCase().includes(filterValue));
    }

    onClasseOptionSelected(event: MatAutocompleteSelectedEvent) {
        const selectedClasseProduto = event.option.value as ClasseProduto;
        this.classeControl.setValue(selectedClasseProduto);
        this.produtoForm.get('classe').setValue(this.classeControl.value);
    }

    onBancoOptionSelected(event: MatAutocompleteSelectedEvent) {
        const selectedBanco = event.option.value as Banco;
        this.bancoControl.setValue(selectedBanco);

        this.produtoForm.get('banco').setValue(this.bancoControl.value);
    }

    onTipoCertOptionSelected(event: MatAutocompleteSelectedEvent) {
        const selectedTipoCert = event.option.value as WebCertificadoTipo;
        this.tipoCertificadoControl.setValue(selectedTipoCert);

        this.produtoForm.get('tipoCert').setValue(this.tipoCertificadoControl.value);
    }

    onTipoPessoaCertOptionSelected(event: MatAutocompleteSelectedEvent) {
        const selectedTipoPessoaCert = event.option.value as WebCertificadoTipoPessoa;
        this.tipoPessoaCertificadoControl.setValue(selectedTipoPessoaCert);

        this.produtoForm.get('tipoPessoaCert').setValue(this.tipoPessoaCertificadoControl.value);
    }

    getProduto(id: number) {
        this._restService.getProdutoPorId(id).subscribe(
            res => {
                this.produtoForm.patchValue(res)

                if (res.imagemBase64 != null && !res.imagemBase64.startsWith('data')) {
                    this.produtoForm.get('imagemBase64').setValue('data:image/jpg;base64,' + res.imagemBase64);
                }

                // Necessário para associar ao autocomplete
                this.patchAutoComplete(res);
            }, error => {
                console.error('Erro: ', error.error.errors[0]);
                alert(`Erro: ${error.error.errors[0]}`);
            }
        )
    }

    patchAutoComplete(res) {
        this.classeControl.setValue(res.classe);
        this.bancoControl.setValue(res.banco);
        this.tipoCertificadoControl.setValue(res.tipoCert);
        this.tipoPessoaCertificadoControl.setValue(res.tipoPessoaCert);
    }

    onSubmit() {
        if (this.produtoForm.valid) {
            this.isLoading = true;

            if (this.pathId) {
                this.atualizarProduto();
            } else {
                !this.produtoForm.get('valAtivacao').value ? this.produtoForm.get('valAtivacao').setValue(null) : '';
                
                this.criarProduto();
            }
        } else {
            this.isLoading = false;
            console.error('Formulário inválido');
            alert('Erro: formulário inválido');
        }
    }

    criarProduto() {
        this._restService.criarProduto(this.produtoForm.value).subscribe(
            res => {
                console.log("Produto cadastrado com sucesso", res);
                this._snackBar.open("Produto cadastrado com sucesso", "fechar", {
                    duration: 4000,
                    panelClass: ['success-snackbar']
                });
                this.router.navigate(['/produtos']);
            }, error => {
                console.error('Erro: ', error.error.errors[0]);
                alert(`Erro: ${error.error.errors[0]}`);
            }
        )
    }

    atualizarProduto() {
        this._restService.atualizarProduto(this.produtoForm.value).subscribe(
            _ => {
                this._snackBar.open("Produto atualizado com sucesso", "fechar", {
                    duration: 4000,
                    panelClass: ['success-snackbar']
                });
                this.router.navigate(['/produtos']);
            }, error => {
                console.error('Erro: ', error.error.errors[0]);
                alert(`Erro: ${error.error.errors[0]}`);
            }
        )
    }

    back() {
        this.location.back();
    }

    handleInputChange(e) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        var pattern = /image-*/;
        var reader = new FileReader();

        if (!file.type.match(pattern)) {
            alert('Formato inválido. Por favor, selecione uma imagem.');
            return;
        }

        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
    }

    _handleReaderLoaded(e) {
        let reader = e.target;
        this.produtoForm.get('imagemBase64').setValue(reader.result);
    }
}
