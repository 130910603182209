export class User {
    public token: string;
    public remember: string;
    public nome: string;
    public id: string;
    public vwUsuarioPessoas: any;

    constructor(json: any) {
        this.token = json.token;
        this.remember = json.remember;
        this.nome = json.nome;
        this.id = json.id;
    }

    public get(attribute: string): any {
        return this[attribute];
    }

    public set(attribute: string, value: any) {
        this[attribute] = value;
    }
}