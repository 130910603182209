import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "../_services/authentication.service";
import { Observable } from "rxjs";

import { User } from "../../_models/user";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router, 
                private _authenticationService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!this._authenticationService.isAuthenticated()) {
            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }       

        let currentUser: User;

        let currentUserJson: String = JSON.parse(sessionStorage.getItem('currentUser'));
        if (currentUserJson != null) currentUser = new User(currentUserJson);

        if (currentUserJson !== null && (currentUser.get("remember") || currentUser.get("remember") == "RememberAfterLogin")) {
            if (currentUser.get("remember") == "RememberAfterLogin") {
                currentUser.set("remember", "false");
                sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
            }

            return true;
        }
        else { // error when verify so redirect to login page with the return url
            if (!(localStorage.getItem("currentUser") === null)) sessionStorage.removeItem('currentUser');

            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}