import { LinkPagamento } from './link-pagamento';
import { CreditOnline } from './creditonline';
import { FormasPagamentoEnum } from 'app/_enums/formaPagamento.enum';
import { CartaCredito } from './cartacredito';

export class Payment {
  public id: number;
  public paymentForm: FormasPagamentoEnum | null;
  public valor: number;
  public codigoNSU: string;
  public qntParcelas: number;
  public maquineta: number;
  public maquinetaString: string;
  public banco: number;
  public comprovante: string;
  public editable: boolean;
  public online: CreditOnline;
  public link: LinkPagamento;
  public cartaCredito: CartaCredito;
  public conciliado: string;
  public removible: boolean;

  constructor(json: any) {
    this.id = json.id;
    this.paymentForm = json.paymentForm;
    this.valor = json.valor;
    this.codigoNSU = json.codigoNSU;
    this.qntParcelas = json.qntParcelas;
    this.maquineta = json.maquineta;
    this.banco = json.banco;
    this.comprovante = json.comprovante;
    this.editable = json.editable;
    this.online = json.online;
    this.link = json.link;
    this.cartaCredito = json.cartaCredito;
    this.conciliado = json.conciliado;
    this.removible = json.removible;
  }

  public get(attribute: string): any {
    return this[attribute];
  }

  public set(attribute: string, value: any) {
    this[attribute] = value;
  }

  public getEmpty() {
    return new Payment({
      id: null,
      paymentForm: undefined,
      valor: undefined,
      codigoNSU: null,
      qntParcelas: undefined,
      maquineta: null,
      banco: null,
      comprovante: undefined,
      editable: null,
      online: null,
      link: null,
      cartaCredito: null,
      conciliado: null,
      removible: null,
      maquinetaString: null
    });
  }
}
