import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DefaultComponent } from '../pages/default/default.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { FooterComponent } from './footer/footer.component';

import { AsyncPipe, CommonModule, JsonPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HrefPreventDefaultDirective } from '../../_directives/href-prevent-default.directive';
import { UnwrapTagDirective } from '../../_directives/unwrap-tag.directive';

import { RestService } from "../../_services/rest.service"
import { UtilsService } from "../../_services/utils.service"
import { CepService } from "../../_services/cep.service"
import { StorageService } from "../../_services/storage.service"
import { CompraService } from "../../_services/compra.service"
import { SelectService } from "../../_services/select.service"
import { FormService } from "../../_services/form.service"
import { DataService } from "../../_services/data.service"

import { LightboxModule } from 'ngx-lightbox';

import { HeaderCheckoutComponent } from '../shared/header-checkout/header-checkout.component';
import { NgxPermissionsModule } from 'ngx-permissions';

import { ConstantsService } from "../../_services/constants.service";

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        DefaultComponent,
        HeaderNavComponent,
        FooterComponent,
        HrefPreventDefaultDirective,
        UnwrapTagDirective,
        HeaderCheckoutComponent,
    ],
    exports: [
        DefaultComponent,
        HeaderNavComponent,
        FooterComponent,
        HrefPreventDefaultDirective,
        HeaderCheckoutComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        LightboxModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        NgxPermissionsModule.forChild(),
    ],
    providers: [
        RestService,
        UtilsService,
        CepService,
        StorageService,
        CompraService,
        FormService,
        DataService,
        SelectService,
        ConstantsService,
        JsonPipe,
        AsyncPipe,
    ],
})

export class LayoutModule {
}
