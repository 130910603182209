export const environment = {
  empresa: 'vallorizasolucoes',
  logo: './assets/app/media/img/logos/logo_valloriza.svg',
  logo_branca: './assets/app/media/img/logos/logo_valloriza_branca.svg',
  production: false,
  login: '',
  password: '',
  version: '10.1.0',
  url_api: 'https://dev.vendasapi.valloriza.net.br',
  url_api_nota: 'https://dev.vendasapi-nfse.valloriza.net.br'
};
