import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from 'app/_services/rest.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Convenio } from 'app/_models/classeProduto';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-convenioclasseprod',
  templateUrl: './convenios.component.html',
  styleUrls: ['./convenios.component.less']
})
export class ConveniosComponent implements OnInit,  AfterViewInit {
  displayedColumns: string[] = ['id', 'descricao', 'codigo','descricaoTipo', 'descricaoSubtipo', 'acao'];
  dataSource: MatTableDataSource<Convenio> = new MatTableDataSource();
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  convenioControl = new FormControl('');

  constructor(
    private _restService: RestService,
    private router: Router,
  ) { }

  goToConvenio(){
    this.router.navigate(['/convenio'])
  }

  goToEdicao(id: number){
    this.router.navigate(['/convenio/', id])
  }

  ngOnInit() {
    this.getAllConvenios();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.createFilter();
    this.convenioControl.valueChanges.subscribe(value => {
      this.applyFilter(value);
    });
    this.dataSource.sortingDataAccessor = (row: Convenio, columnName: string): string | number => {
      if (columnName == "id"){
        return row.id ? row.id : '';
      }
      else if (columnName === "descricaoTipo") {
        return row.tipo ? row.tipo.descricao : '';
      }
      else if (columnName === "descricaoSubtipo") {
        return row.subTipo ? row.subTipo.descricao : '';
      }
      else if (columnName === "codigo") {
        if (row.codigo){
          var numero = true;
          for (let i = 0; i < row.codigo.length; i++){
            if (row.codigo[i] < '0' || row.codigo[i] > '9') {
              numero = false;
            }
            i = i + 1;
          }
          if (numero){
            return parseInt(row.codigo);
          }
          return '';
        }
      }
      return (row[columnName as keyof Convenio] || '').toString();
    };
  }

  getAllConvenios() {
    this._restService.getAllConvenios().subscribe(
      (ConvenioRes) => {
        this.dataSource.data = ConvenioRes;
        this.applyFilter(this.convenioControl.value);
        this.conveniosOrdenado();
      },
      (error) => {
        console.error('Erro ao carregar convenios:', error);
        this.dataSource.data = [];
      }
    );
  }

  conveniosOrdenado(){
    this.sort.active = 'id';  // Coluna que será ordenada
    this.sort.direction = 'asc';  // 'asc' para crescente, 'desc' para decrescente
    this.dataSource.sort = this.sort;
  }

  createFilter(): (data: Convenio, filter: string) => boolean {
    return (data: Convenio, filter: string): boolean => {

      const searchTerms = filter;
      const canalMatch = searchTerms
      ? (
          data.id && data.id.toString().toLowerCase().includes(searchTerms) ||
          data.descricao && data.descricao.toLowerCase().includes(searchTerms) ||
          data.codigo && data.codigo.toLowerCase().includes(searchTerms) ||
          data.tipo && data.tipo.descricao.toString().toLowerCase().includes(searchTerms) ||
          data.subTipo && data.subTipo.descricao.toString().toLowerCase().includes(searchTerms)
      ) : true

      return canalMatch
    };
  }

  applyFilter(value: string) {
    const filterValue = value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
