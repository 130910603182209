import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class StorageService {

    private storageSub = new Subject<boolean>();

    watchStorage(): Observable<any> {
        return this.storageSub.asObservable();
    }

    setItem(key: string, data: any) {
        localStorage.setItem(key, data);
        this.storageSub.next(true);
    }

    removeItem(key) {
        localStorage.removeItem(key);
        this.storageSub.next(true);
    }
}