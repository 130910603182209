import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ClasseProduto } from 'app/_models/classeProduto';
import { RestService } from 'app/_services/rest.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-classes-produtos',
  templateUrl: './classes-produtos.component.html',
  styleUrls: ['./classes-produtos.component.less']
})
export class ClassesProdutosComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['id', 'descricao', 'convenio', 'vigencia', 'ativa', 'comissaoSupervisor', 'comissaoGestor', 'formaComissao', 'acao'];
  dataSource: MatTableDataSource<ClasseProduto> = new MatTableDataSource();
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  classeProdutoControl = new FormControl('');

  constructor(
    private router: Router,
    private _restService: RestService,
    private route: ActivatedRoute

  ) { }

  ngOnInit() {
    this.getAllClassesProdutos();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.createFilter();

    this.classeProdutoControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(value => {
      this.applyFilter(value);
    });
  }

  getAllClassesProdutos() {
    this._restService.getAllClassesProdutos().subscribe(
      (classesProdutosRes) => {
        this.dataSource.data = classesProdutosRes ? classesProdutosRes : [];
        this.applyFilter(this.classeProdutoControl.value);
        this.classesProdutosOrdenados();
      }, (_) => {
        this.dataSource.data = [];
      }
    );
  }

  classesProdutosOrdenados() {
    this.sort.active = 'id';
    this.sort.direction = 'asc';
    this.dataSource.sort = this.sort;
  }

  applyFilter(value: string) {
    const filterValue = value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  createFilter(): (data: ClasseProduto, filter: string) => boolean {
    return (data: ClasseProduto, filter: string): boolean => {
      return data.descricao.toLowerCase().includes(filter);
    };
  }

  editClasseProduto(id: number) {
    this.router.navigate(['classe-produto/', id], {
      relativeTo: this.route
    });
  }
}
