import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource, MatSort, MatAutocompleteSelectedEvent, PageEvent } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Canal } from 'app/_models/canal';
import { PoliticaProduto } from 'app/_models/politicaProdutos';
import { ProdutoNovo } from 'app/_models/produtoNovo';
import { RestService } from 'app/_services/rest.service';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith, debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-politica-produtos',
  templateUrl: './politica-produtos.component.html',
  styleUrls: ['./politica-produtos.component.less']
})
export class PoliticaProdutosComponent implements OnInit {
  displayedColumns: string[] = ['id', 'descricao', 'tabela', 'idCanal', 'valAdesao', 'valAtivacao', 'acao'];
  dataSource: MatTableDataSource<PoliticaProduto> = new MatTableDataSource();
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  filteredCanais!: Observable<Canal[]>;
  canais: Canal[] = [];
  canalControl = new FormControl('');

  filteredProdutos!: Observable<ProdutoNovo[]>;
  produtos: ProdutoNovo[] = [];
  produtoControl = new FormControl('');

  pageSize: number | undefined;
  pageNo: number | undefined;
  totalItems: number | undefined;
  pageSizeOptions: number[] = [30, 50, 100, 150];

  constructor(
    private _restService: RestService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const page = params['page'];
      const size = params['size'];

      this.pageNo = page ? +page : 0;
      this.pageSize = size ? +size : 50;
      const search = params['search'] || '';
      const filter = params['filter'] || '';

      if (this.pageSize == this.pageSizeOptions[this.pageSizeOptions.length - 1]) {
        const lastPageSize = this.pageSizeOptions[this.pageSizeOptions.length - 1];
        this.pageSizeOptions = [...this.pageSizeOptions, lastPageSize + 50, lastPageSize + 100, lastPageSize + 200, lastPageSize + 300];
      }

      this.produtoControl.setValue(search);
      this.canalControl.setValue(filter);

      this.getCanaisAndProdutos();
      this.getPoliticas(this.pageNo, this.pageSize, search, filter);
    });

    this.canalControl.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => this.applyFilter())
      )
      .subscribe();

    this.produtoControl.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => this.applyFilter())
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (row: PoliticaProduto, columnName: string): string => {
      if (columnName === "idCanal") {
        return row.canal ? row.canal.idCanal.toString() : '';
      } else if (columnName === "tabela") {
        return row.produto ? row.produto.tabela : '';
      } else if (columnName === "descricao") {
        return row.produto ? row.produto.descricao : '';
      }
      return (row[columnName as keyof PoliticaProduto] || '').toString();
    };
  }

  displayCanal(canal: Canal): string {
    return canal && canal.descricao ? canal.descricao : '';
  }

  displayProduto(produto: ProdutoNovo): string {
    return produto && produto.descricao ? produto.descricao : '';
  }

  private _filterCanais(value: string | Canal): Canal[] {
    if (!this.canais || this.canais.length === 0) {
      return [];
    }

    let filterValue: string;

    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else if (value && value.descricao) {
      filterValue = value.descricao.toLowerCase();
    } else {
      filterValue = '';
    }

    return this.canais.filter(canal => canal.descricao.toLowerCase().includes(filterValue));
  }

  private _filterProdutos(value: string | ProdutoNovo): ProdutoNovo[] {
    if (!this.produtos || this.produtos.length === 0) {
      return [];
    }

    let filterValue: string;

    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else if (value && value.descricao) {
      filterValue = value.descricao.toLowerCase();
    } else {
      filterValue = '';
    }

    return this.produtos.filter(canal => canal.descricao.toLowerCase().includes(filterValue));
  }

  onCanalOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedCanal = event.option.value as Canal;
    this.canalControl.setValue(selectedCanal);
  }

  onProdutoOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedProduto = event.option.value as ProdutoNovo;
    this.produtoControl.setValue(selectedProduto);
  }

  getPoliticas(pageNo: number, pageSize: number, search: string, filter: string): void {
    this._restService.getPoliticasComPaginacao(pageNo, pageSize, search, filter).subscribe(politicasRes => {
      this.totalItems = politicasRes.totalElements;
      this.dataSource.data = this.sortPoliticas(politicasRes.content);
    });
  }

  sortPoliticas(politicas: PoliticaProduto[]) {
    return politicas.sort((a: PoliticaProduto, b: PoliticaProduto) => b.id - a.id);
  }

  getCanaisAndProdutos() {
    const canais$ = this._restService.getCanais();
    const produtos$ = this._restService.getAllProdutos();

    forkJoin([canais$, produtos$]).subscribe(([canaisRes, produtosRes]) => {
      this.canais = canaisRes;
      this.filteredCanais = this.canalControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterCanais(value))
      );

      this.produtos = produtosRes;
      this.filteredProdutos = this.produtoControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterProdutos(value))
      );
    }, error => {
      console.error('Erro: ', error.error.errors[0]);
      alert(`Erro: ${error.error.errors[0]}`);
    })
  }

  applyFilter(): Observable<void> {
    let filterValue: string = '';
    let searchValue: string = '';

    if (this.canalControl.value) {
      if (typeof this.canalControl.value === 'object') {
        filterValue = this.canalControl.value.descricao || '';
      } else {
        filterValue = this.canalControl.value.toLowerCase();
      }
    }

    if (this.produtoControl.value) {
      if (typeof this.produtoControl.value === 'object') {
        searchValue = this.produtoControl.value.descricao || '';
      } else {
        searchValue = this.produtoControl.value.toLowerCase();
      }
    }

    return this._restService.getPoliticasComPaginacao(this.pageNo!, this.pageSize!, searchValue, filterValue).pipe(
      map(politicasRes => {
        this.totalItems = politicasRes.totalElements;
        this.dataSource.data = this.sortPoliticas(politicasRes.content);
      })
    );
  }

  onPaginate(pageEvent: PageEvent) {
    this.pageNo = +pageEvent.pageIndex;
    this.pageSize = +pageEvent.pageSize;

    this.applyFilter().subscribe();
  }

  editarPolitica(politica: PoliticaProduto) {
    this.router.navigate(['politica-produto/', politica.id]);
  }
}
