import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ClasseProduto } from 'app/_models/classe-produto';

@Injectable({
  providedIn: 'root'
})
export class LogisticaService {

  constructor(private http: HttpClient) { }

  getLogistica(): Observable<ClasseProduto[]> {
    const ID_CLASSE = 62;
    return this.http.get<ClasseProduto[]>(environment.url_api + '/api/produto/ativo/classe/' + ID_CLASSE);
  }
}
