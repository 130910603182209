export enum pessoaTipoEnum {
    DIRETOR = "DI",
    GERENTE_COMERCIAL = "GC",
    SUPERVISOR_COMERCIAL = "SC",
    GESTOR_FRANQUIA = "GF",
    PDV = "PV",
    FORNECEDOR = "FO",
    CLIENTE = "CL",
    CONTA_CONCILIACAO = "CC",
    CAIXA = "CX",
    CONTA_CORRENTE_BANCO = "CB",
    FINANCEIRO = "FI",
    SUPORTE_MOTOS = "SM",
    SUPORTE_CERTIFICADO_DIGITAL = "SD",
    CONTROLE_DE_SALDO = "CS",
    INDICADOR = "IN",

    CONTA_QUALQUER = "CQ"
}