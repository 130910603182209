import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { RestService } from '../../../../_services/rest.service';
import { UtilsService } from '../../../../_services/utils.service';
import { Canal } from 'app/_models/canal';
import { FormControl } from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-canal',
  templateUrl: './canais.component.html',
  styleUrls: []
})
export class CanaisComponent implements OnInit {
  dataSourceCanais: MatTableDataSource<Canal> = new MatTableDataSource();
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  displayedColumns: string[] = [/*'idN',*/ 'idCanal', 'descricao', 'descontoMaximo', 'descontoMaximoMoto', 'acao'];
  canalControl = new FormControl('');

  constructor(
    private _restService: RestService,
    private _utilsService: UtilsService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  goToCadastro() {
    this.router.navigate(['/canal']);
  }

  goToEdicao(id: number) {
    this.router.navigate(['/canal/', id])
  }

  ngOnInit() {
    this.getCanais();
  }

  ngAfterViewInit() {
    this.sort.active = 'idCanal';
    this.sort.direction = 'asc';
    this.dataSourceCanais.sort = this.sort;
    this.dataSourceCanais.filterPredicate = this.createFilter();
    this.canalControl.valueChanges.subscribe(() => this.applyFilter());
    this.cdr.detectChanges();
  }

  private getCanais() {
    this._restService.getCanais().subscribe(
      canais => {
        this.dataSourceCanais.data = canais;
      }, error => {
        this._utilsService.alertErro(error);
      }
    )
  }

  private createFilter(): (data: Canal, filter: string) => boolean {
    return (data: Canal, filter: string): boolean => {
      const canalMatch = filter
        ? (
          //data.id && data.id.toString().toLowerCase().includes(searchTerms) ||
          data.idCanal && data.idCanal.toString().toLowerCase().includes(filter) ||
          data.descricao && data.descricao.toLowerCase().includes(filter) ||
          data.descontoMaximo && data.descontoMaximo.toString().toLowerCase().includes(filter) ||
          data.descontoMaximoMoto && data.descontoMaximoMoto.toString().toLowerCase().includes(filter)
        )
        : true

      return canalMatch
    };
  }

  private applyFilter() {
    const filterValue = this.canalControl.value ? this.canalControl.value.trim().toLowerCase() : ''
    this.dataSourceCanais.filter = filterValue;
  }
}