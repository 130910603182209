import { Address } from "./address";

export class Cliente {

    public id: number;
    public nome: string;
    public cpfCnpj: string;
    public sexo: string;
    public dataNascimento: Date;
    public email: string;
    public fone: string;
    public celular: string;
    public pessoaReferencia: string;
    public telefoneReferencia: string;
    public cpfPessoaReferencia: string;
    public cep: string;
    public endereco: string;
    public nro: string;
    public bairro: string;
    public cidade: string;
    public uf: string;
    public tipoPessoa: string;

    public inscricaoEstadual: string;

    public idIndicador: string;
    public viewIndicador: string;
    public prospeccaoIndicador: string;

    constructor(json: any) {
        this.id = json.id;
        this.nome = json.nome;
        this.cpfCnpj = json.cpfCnpj;
        this.sexo = json.sexo;
        this.dataNascimento = json.dataNascimento;
        this.email = json.email;
        this.fone = json.fone;
        this.celular = json.celular;
        this.pessoaReferencia = json.pessoaReferencia;
        this.telefoneReferencia = json.telefoneReferencia;
        this.cpfPessoaReferencia = json.cpfPessoaReferencia;
        this.cep = json.cep;
        this.endereco = json.endereco;
        this.nro = json.nro;
        this.bairro = json.bairro;
        this.cidade = json.cidade;
        this.uf = json.uf;
        this.tipoPessoa = json.tipoPessoa;
        this.inscricaoEstadual = json.inscricaoEstadual;
        this.idIndicador = json.idIndicador;
        this.viewIndicador = json.viewIndicador;
        this.prospeccaoIndicador = json.prospeccaoIndicador;
    }

    public getEmpty() {
        return new Cliente({
            id: null,
            cpfCnpj: undefined,
            nome: undefined,
            tipoPessoa: undefined,
            endereco: null,
            nro: null,
            bairro: null,
            cep: null,
            cidade: null,
            dataNascimento: null,
            email: undefined,
            fone: null,
            celular: null,
            pessoaReferencia: null,
            sexo: null,
            telefoneReferencia: null,
            cpfPessoaReferencia: null,
            uf: undefined,
            idIndicador: null,
            viewIndicador: null,
            prospeccaoIndicador: null,
            inscricaoEstadual: null
        });
    }

    public get(attribute: string): any {
        return this[attribute];
    }

    public set(attribute: string, value: any) {
        this[attribute] = value;
    }
}
