import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from '../../../helpers';

import { environment } from '../../../../environments/environment';

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./footer.component.less"
    ]
})
export class FooterComponent implements OnInit {
    logo = environment.logo;
    public SEMVER: string;

    constructor() {
        this.SEMVER = environment.version;
    }

    ngOnInit() {

    }
}
