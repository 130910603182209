import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { RestService } from 'app/_services/rest.service';
import { Banco } from 'app/_models/banco';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bancos',
  templateUrl: './bancos.component.html',
  styles: ['./bancos.component.less']
})
export class BancosComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['id', 'descricao', 'sigla', 'acao'];
  dataSource: MatTableDataSource<Banco> = new MatTableDataSource();
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  
  bancoControl = new FormControl('');
  // router: any;

  constructor(
    private router: Router,
    private _restService: RestService
  ) {}

  ngOnInit() {
    this.getAllBancos();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.createFilter();

    this.bancoControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(value => {
      this.applyFilter(value);
    });
  }

  getAllBancos() {
    this._restService.getAllBancos().subscribe(
      (bancosRes) => {
        this.dataSource.data = bancosRes; // Dados carregados
        console.log('Dados Carregados',this.dataSource.data)
        this.applyFilter(this.bancoControl.value); // Aplicar o filtro inicial, caso tenha algum valor no campo de busca
        this.bancosOrdenados();
      },
      (error) => {
        console.error('Erro ao carregar bancos:', error);
        this.dataSource.data = [];
      }
    );
  }

  bancosOrdenados() {
    this.sort.active = 'id';  // Coluna que será ordenada
    this.sort.direction = 'asc';  // 'asc' para crescente, 'desc' para decrescente
    this.dataSource.sort = this.sort;
  }

  applyFilter(value: string) {
    const filterValue = value.trim().toLowerCase();
    this.dataSource.filter = filterValue; // Atualiza o filtro no dataSource
    console.log('Filtro:', this.dataSource.filter)
  }

  createFilter(): (data: Banco, filter: string) => boolean {
    return (data: Banco, filter: string): boolean => {
      // Filtra por descrição do banco, ignorando maiúsculas e minúsculas
      return data.descricao.toLowerCase().includes(filter);
    };
  }

  editBanco(id: number) {
    this.router.navigate(['/bancos/banco/', id])
  }
}
