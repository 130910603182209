export class CartaCredito {
    public grupo: string;
    public cota: string;
    public gravame: number;

    constructor(json: any) {
        this.grupo = json.grupo;
        this.cota = json.cota;
        this.gravame = json.gravame;
      }

      public getEmpty(): CartaCredito {
        return new CartaCredito({ grupo: null, cota: null, gravame: 580});
      }
}