import { Injectable } from '@angular/core';

import { UtilsService } from '../_services/utils.service';

@Injectable()
export class FormService {

  DECIMAL_SEPARATOR = '.';
  GROUP_SEPARATOR = ',';
  pureResult: any;
  maskedId: any;

  constructor(private _utilsService: UtilsService) { }

  //CPF | CNPJ
  formatCpfCnpj(valString) {
    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    this.pureResult = parts;
    if (parts[0].length <= 11) {
      this.maskedId = this.cpf_mask(parts[0].substring(0, 11));
      return this.maskedId;
    } else {
      this.maskedId = this.cnpj(parts[0].substring(0, 14));
      return this.maskedId;
    }
  };

  public validaCpfCnpj(cpfCnpj, flagInput?): string {
    if (cpfCnpj.length !== 11 && cpfCnpj.length !== 14) {
      return 'Quantidade de dígitos inválida. Digite um CPF/CNPJ válido.';
    } else if ((cpfCnpj.length === 11 || flagInput === 'PF') && !this.validaCPF(cpfCnpj)) {
      return 'CPF inválido. Confirme e tente novamente.';
    } else if ((cpfCnpj.length >= 14 || flagInput === 'PJ') && !this.validaCNPJ(cpfCnpj.substring(0, 14))) {
      return 'CNPJ inválido. Confirme e tente novamente.';
    }

    return null;
  }

  cpf_mask(v) {
    v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
    // de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  public validaCPF(strCPF): boolean {
    var soma;
    var resto;
    soma = 0;

    if (strCPF == '00000000000' ||
      strCPF == '11111111111' ||
      strCPF == '22222222222' ||
      strCPF == '33333333333' ||
      strCPF == '44444444444' ||
      strCPF == '55555555555' ||
      strCPF == '66666666666' ||
      strCPF == '77777777777' ||
      strCPF == '88888888888' ||
      strCPF == '99999999999') return false;

    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;
    }

    if ((resto == 10) || (resto == 11)) {
      resto = 0;
    }

    if (resto != parseInt(strCPF.substring(9, 10))) {
      return false;
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;
    }

    if ((resto == 10) || (resto == 11)) {
      resto = 0;
    }
    if (resto != parseInt(strCPF.substring(10, 11))) {
      return false;
    }

    return true;
  }

  cnpj(v) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito

    v = v.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
    return v;
  }

  public validaCNPJ(strCNPJ): boolean {
    strCNPJ = strCNPJ.replace(/[^\d]+/g, '');

    // Elimina CNPJs invalidos conhecidos
    if (strCNPJ == '' ||
      strCNPJ == '00000000000000' ||
      strCNPJ == '11111111111111' ||
      strCNPJ == '22222222222222' ||
      strCNPJ == '33333333333333' ||
      strCNPJ == '44444444444444' ||
      strCNPJ == '55555555555555' ||
      strCNPJ == '66666666666666' ||
      strCNPJ == '77777777777777' ||
      strCNPJ == '88888888888888' ||
      strCNPJ == '99999999999999')
      return false;

    // Valida DVs
    var tamanho = strCNPJ.length - 2
    var numeros = strCNPJ.substring(0, tamanho);
    var digitos = strCNPJ.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = strCNPJ.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;
  }

  // FONE
  phone_mask(valString) {
    if (!valString) {
      return '';
    }

    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    this.pureResult = parts;
    this.maskedId = this.phone(parts[0]);

    if (parts[0].length <= 10) {
      this.maskedId = this.phone(parts[0].substring(0, 10));
    } else {
      this.maskedId = this.phone2(parts[0].substring(0, 11));
    }

    return this.maskedId;
  };

  phone(v) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{0})(\d)/, '$1($2'); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{0})\((\d{2})(\d)/, '$1($2)$3'); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/\)(\d{4})(\d)/, ')$1-$2'); //Coloca hifen entre o quarto e o quinto dígitos
    return v;
  }

  phone2(v) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{0})(\d)/, '$1($2'); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{0})\((\d{2})(\d)/, '$1($2)$3'); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/\)(\d{5})(\d)/, ')$1-$2'); //Coloca hifen entre o quarto e o quinto dígitos
    return v;
  }

  formatPhone(fone) {
    if (fone != null) {
      fone = this.unMask(fone);
      if (fone.length < 10) {
        return this.phone(fone.substring(0, 10));
      } else {
        return this.phone2(fone.substring(0, 11));
      }
    }
    else return null;
  }

  // CEP
  cep_mask(valString) {
    if (!valString) {
      return '';
    }

    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    this.pureResult = parts;
    this.maskedId = this.cep(parts[0].substring(0, 8));

    return this.maskedId;
  };

  cep(v) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{5})(\d)/, '$1-$2'); //Coloca hifen entre o quinto e o sexto dígitos
    return v;
  }

  // DATA
  data_mask(valString) {
    if (!valString) {
      return '';
    }

    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    this.pureResult = parts;
    this.maskedId = this.dataNascimento(parts[0].substring(0, 8));

    return this.maskedId;

  };

  dataNascimento(v) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, '$1/$2'); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/(\d{2})(\d)/, '$1/$2'); //Coloca hifen entre o quinto e o sexto dígitos
    return v;
  }

  // UTILS
  unMask(v) {
    if (v != null || v != undefined) {
      v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    }
    return v;
  }

  unFormat(val) {
    if (!val) {
      return '';
    }
    val = val.replace(/\D/g, '');

    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
  };

  format(valString) {
    if (!valString) {
      return '';
    }

    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    this.pureResult = parts;
    if (parts[0].length <= 11) {
      this.maskedId = this.cpf_mask(parts[0].substring(0, 11));
      return this.maskedId;
    } else {
      this.maskedId = this.cnpj(parts[0].substring(0, 14));
      return this.maskedId;
    }
  };

  public static isFieldValid(field): boolean {
    if (!field || field == '')
      return false;

    return true;
  }


  digit_mask(valString) {
    return valString.replace(/\s+/g, '').replace(/[^0-9]/gi, '').replace(/\D/g, '');
  }

  card_mask(cc) {
    if (!cc) {
      return '';
    }

    var cc = cc.replace(/\s+/g, '').replace(/[^0-9]/gi, '').replace(/\D/g, '');
    var matches = cc.match(/\d{4,16}/g);
    var match = matches && matches[0] || ''
    var parts = []

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }

    if (parts.length) {
      return parts.join(' ')
    } else {
      return cc
    }
  }

  isValidEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
