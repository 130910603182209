import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "./_services/authentication.service";
import { AlertService } from "./_services/alert.service";
import { AlertComponent } from "./_directives/alert.component";

import { UtilsService } from "../_services/utils.service";
import { environment } from "../../../src/environments/environment";
import { JwtAuthenticationPessoa } from "./interfaces/jwt-authentication-pessoa";
import { User } from "../_models/user";

@Component({
  selector: "app-auth",
  templateUrl: "./templates/login.component.html",
  styleUrls: ["./templates/login.component.less"],
})
export class AuthComponent implements OnInit {
  user: User;
  loginForm;
  logo = environment.logo;
  userData: JwtAuthenticationPessoa = {
    login: environment.login,
    senha: environment.password,
    remember: true,
    vwUsuarioPessoaId: null,
  };

  loading = false;
  isLoginDisabled = false;
  returnUrl: string;

  @ViewChild("alertSignin", { read: ViewContainerRef, static: true })
  alertSignin: ViewContainerRef;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    private _alertService: AlertService,
    private cfr: ComponentFactoryResolver,
    private _utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "/";
    console.log(this.returnUrl);
    this._router.navigate([this.returnUrl]);
  }

  signin() {
    this.loading, this.isLoginDisabled = true;
    if (
      this.userData.vwUsuarioPessoaId !== null &&
      this.user.id !== undefined &&
      this.userData.vwUsuarioPessoaId === parseInt(this.user.id)
    ) {
      this._router.navigate([this.returnUrl]);
      return;
    }

    this._authService.signin(this.userData).subscribe(
      () => {
        this.hideAlert();
        this.user = JSON.parse(sessionStorage.getItem("currentUser"));
        if (
          this.userData.vwUsuarioPessoaId !== null ||
          this.user.vwUsuarioPessoas.length < 2
        ) {
          this._router.navigate([this.returnUrl]);
        } else {
          this.userData.vwUsuarioPessoaId = parseInt(this.user.id);
          this.loading = false;
        }
      },
      (error) => {
        this.showAlert("alertSignin");

        if (error.status == 400)
          this._alertService.error(JSON.parse(error._body).errors[0]);
        else this._alertService.error("Usuário ou senha incorretos");
        
        this.loading, this.isLoginDisabled = false;
      }
    );
  }

  showAlert(target) {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(AlertComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  hideAlert() {
    this.alertSignin.clear();
  }
}
