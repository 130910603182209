import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RestService } from '../../../../_services/rest.service';
import { UtilsService } from '../../../../_services/utils.service';
import { CadastroCanal, Canal } from 'app/_models/canal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UpperCasePipe } from '@angular/common';

@Component({
  selector: 'app-cadastro-canal',
  templateUrl: './canal.component.html',
  styleUrls: ['./canal.component.less']
})
export class CanalComponent implements OnInit {
  pathId = null
  
  canalForm = new FormGroup({
    idCanal: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
    descricao: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    descontoMaximo: new FormControl(0, [Validators.required, Validators.min(0.0), Validators.max(100.0)]),
    descontoMaximoMoto: new FormControl(0, [Validators.required, Validators.min(0.0), Validators.max(100.0)])
  })

  constructor(
    private router: Router,
    private _restService: RestService,
    private _utilsService: UtilsService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {}

  goBack() {
    this.router.navigate(['/canais']);
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id') !== null) {
        const id = params.get('id');
        this.pathId = id;
        this.getCanal(parseInt(id));
      }
    })
  }

  onSubmit() {
    if (!this.canalForm.valid) return;
    this.toUppercase();
    if (this.pathId) this.updateCanal();
    else this.addCanal();
  }

  getCanal(id: number){
    this._restService.getCanal(id).subscribe(
      res => {
        this.canalForm.patchValue(res);
      }
    )
  }

  addCanal() {
    this._restService.addCanal(this.canalForm.value as CadastroCanal).subscribe(
        canais => {
            //console.log('Canal adicionado com sucesso:', canais);
            this._snackBar.open("Canal cadastrado com sucesso", "fechar", {
              duration: 4000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/canais']);
        },
        error => {
            this._utilsService.alertErro(error);
        }
    );
  }

  updateCanal() {
    const canal = this.copyCanal()

    this._restService.updateCanal(canal).subscribe(
      _ => {
        this._snackBar.open("Canal atualizado com sucesso", "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/canais']);
      },
      error => {
        const errorMessage = (error && error.error && error.error.errors && error.error.errors.length) 
        ? error.error.errors.join(', ') 
        : 'Erro desconhecido';
        console.error('Erro: ', errorMessage);
        alert(`Erro: ${errorMessage}`);
      }
    )
  }

  copyCanal(): Canal {
    const canal = {
      id: this.pathId,
      idCanal: this.canalForm.get('idCanal').value,
      descricao: this.canalForm.get('descricao').value,
      descontoMaximo:  this.canalForm.get('descontoMaximo').value,
      descontoMaximoMoto: this.canalForm.get('descontoMaximoMoto').value
    }
    return canal
  }

  toUppercase() {
    const uppercaseValue = this.canalForm.get('idCanal').value.toUpperCase();
    this.canalForm.get('idCanal').setValue(uppercaseValue);
  }

}