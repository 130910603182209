import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { RestService } from "app/_services/rest.service";
import { MatSnackBar } from "@angular/material";
import { VwUsuario } from "app/_models/vw-usuario";
import { Response } from "app/_models/response";
import { UtilsService } from "app/_services/utils.service";

@Component({
  selector: "app-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.less"],
})
export class UsuariosComponent implements OnInit {
  searchTerm: string = "";
  vwUsuarios: VwUsuario[];
  displayedColumns: string[] = ["codIntegracao", "cpfCnpj", "nome", "edit"];

  constructor(
    private _utilsService: UtilsService,
    private location: Location,
    private _restService: RestService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this._restService
      .findAllVwUsuario()
      .subscribe((usuariosRes: Response<VwUsuario[]>) => {
        this.vwUsuarios = usuariosRes.data;
      });
  }

  get filtered(): VwUsuario[] {
    const term = this.searchTerm.trim().toLowerCase();
    if (!term) {
      return this.vwUsuarios;
    }
    return this.vwUsuarios.filter((u) =>
      this._utilsService.objContainsTerm(u, term)
    );
  }
}
