import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilsService } from 'app/_services/utils.service';
import { CadastroConvenio, Convenio } from 'app/_models/classeProduto';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RestService } from 'app/_services/rest.service';
import { Tipo } from 'app/_models/tipo';
import { Observable, forkJoin } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';

@Component({
  selector: 'app-cadastro-convenio',
  templateUrl: './convenio.component.html',
  styleUrls: ['./convenio.component.less']
})
export class ConvenioComponent implements OnInit {

  pathId = null;
  isLoading = false;

  tipos: Tipo[] = [];
  filteredTipos!: Observable<Tipo[]>;
  tipoControl = new FormControl('', Validators.required);

  subTipos: Tipo[] = [];
  filteredSubTipos!: Observable<Tipo[]>;
  subTipoControl = new FormControl('', Validators.required);

  convenioForm = new FormGroup({
    id: new FormControl(''),
    descricao: new FormControl('', [Validators.required, Validators.maxLength(60)]),
    codigo: new FormControl('', [Validators.required, Validators.maxLength(10)]),
    tipo: this.tipoControl,
    subTipo: this.subTipoControl
  });

  

  constructor(
    private router: Router,
    private _restService: RestService,
    private _utilsService: UtilsService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id') !== null) {
        const id = params.get('id');
        this.pathId = id;
        this.getConvenio(parseInt(id));
      }

      this.getTiposAndSubTipos();
    }); 
  }

  getTiposAndSubTipos() {
    const tipos$ = this._restService.getAllTipos();
    const subtipos$ = this._restService.getAllSubTipos()

    forkJoin([tipos$, subtipos$]).subscribe(([tiposRes, subtiposRes]) => {
      this.tipos = tiposRes;
      this.filteredTipos = this.tipoControl.valueChanges.pipe(
        startWith(''),
        map((value: string | Tipo) => this._filterTipos(value))
      );

      this.subTipos = subtiposRes
      this.filteredSubTipos = this.subTipoControl.valueChanges.pipe(
        startWith(''),
        map((value: string | Tipo) => this._filterSubTipos(value))
      );

    }, error => {
    
      console.log(error)
    })
  }
  
  onTipoSelected(event: MatAutocompleteSelectedEvent) {
    const selectedTipo = event.option.value as Tipo;
    this.tipoControl.setValue(selectedTipo);
    this.convenioForm.get('tipo').setValue(this.tipoControl.value);
}

  onSubtipoSelected(event: MatAutocompleteSelectedEvent) {
    const selectedSubTipo = event.option.value as Tipo;
    this.subTipoControl.setValue(selectedSubTipo);
    this.convenioForm.get('subTipo').setValue(this.subTipoControl.value);
}


  private _filterTipos(value: string | Tipo): Tipo[] {
    if (!this.tipos || this.tipos.length === 0) {
      return [];
    }

    const filterValue = typeof value === 'string'
      ? value.toLowerCase()
      : value.descricao.toLowerCase();
      return this.tipos
      .filter(tipo => tipo && tipo.descricao && tipo.descricao.toLowerCase().includes(filterValue));  // Filtra tipos nulos ou sem descrição
  }

  displayTipo(tipo: Tipo): string {
    return tipo && tipo.descricao ? tipo.descricao : '';
  }

  private _filterSubTipos(value: string | Tipo): Tipo[] {
    if (!this.subTipos || this.subTipos.length === 0) {
      return [];
    }

    const filterValue = typeof value === 'string'
      ? value.toLowerCase()
      : value.descricao.toLowerCase();
      
    return this.subTipos
    .filter(subTipo => subTipo.descricao.toLowerCase().includes(filterValue));
  }

  displaySubTipo(subTipo: Tipo): string {
    return subTipo && subTipo.descricao ? subTipo.descricao : '';
  }

  onSubmit() {
    if (!this.convenioForm.valid) {
      return;
    }
    
    this.isLoading = true;
    this.toUppercase()
    
    if (this.pathId) {
      this.updateConvenio();
    } else {
      this.addConvenio();
    }
  }

  addConvenio() {
    
    this._restService.addConvenio(this.convenioForm.value as CadastroConvenio).subscribe(
      res => {
        console.log('Convênio cadastrado com sucesso:', res);

        this._snackBar.open("Convênio cadastrado com sucesso", "Fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });

        this.router.navigate(['/convenios']);
      }, error => {
        this._utilsService.alertErro(error);
      }
    );
  }

  updateConvenio() {
    this._restService.updateConvenio(this.convenioForm.value as Convenio).subscribe(
      _ => {
        this._snackBar.open("Convênio atualizado com sucesso", "Fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/convenios']);
      },
      error => {
        this._utilsService.alertErro(error);
      }
    );
  }

  getConvenio(id: number) {
    this._restService.getConvenio(id).subscribe(
      res => {
        this.convenioForm.patchValue(res);
        this.tipoControl.setValue(res.tipo);
        this.subTipoControl.setValue(res.subTipo);
      }, error => {
        console.error('Erro: ', error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    );
  }

  goBack() {
    this.router.navigate(['/convenios']);
  }

  toUppercase() {
    const uppercaseValue = this.convenioForm.get('descricao').value.toUpperCase();
    this.convenioForm.get('descricao').setValue(uppercaseValue);
  }
}




