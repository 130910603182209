import { Component, OnInit } from '@angular/core';
import { ConsorcioService } from 'app/_services/consorcio.service';
import { Router } from '@angular/router';
import { Cart } from 'app/_models';
import { CompraService } from 'app/_services/compra.service';
import { StorageService } from 'app/_services/storage.service';

@Component({
  selector: 'app-consorcio',
  templateUrl: './consorcio.component.html',
  styles: []
})
export class ConsorcioComponent implements OnInit {
  listConsorcio: any;

  constructor(private consorcioService: ConsorcioService, private compraService: CompraService, private _storageService: StorageService, private _router: Router) { }

  ngOnInit() {
    this.consorcioService.getConsorcio().subscribe(res => {
      console.log(res.data);
      this.listConsorcio = res.data;
    });
  }

  adicionarCarrinho(id: String): void {
    const self = this;
    console.log(id);
    this.compraService.checkIfProductActiveToCart(id).then((cart: Cart) => {
      console.log(cart);
      self._storageService.setItem('cartUser', JSON.stringify(cart));
      self._router.navigate(['/carrinho']);
    });
  }

}
