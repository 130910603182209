import { FormasPagamentoEnum } from './../_enums/formaPagamento.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import * as decode from 'jwt-decode';

import { Cart } from '../_models/cart';
import { Product } from '../_models/product';
import { User } from '../_models/user';
import { environment } from 'environments/environment';

@Injectable()
export class UtilsService {
  empresa = environment.empresa;
  
  constructor(private http: HttpClient,
    private sanitizer: DomSanitizer) {
  }

  getTokenPayload() {
    let currentUser: User = new User(JSON.parse(sessionStorage.getItem('currentUser')));
    const token = currentUser.get('token');

    // decode the token to get its payload
    return decode(token);
  }

  getTipoByRole(role: string) {
    return role.replace('ROLE_', '');
  }

  getNumber(valor) {
    return Number(valor);
  }

  public roundTwoDecimals(num) {
    return Math.round(num * 100) / 100;
  }

  public getPlural(word, value, sufix): string {
    if (value > 1) {
      if (sufix) {
        word = word.substring(0, word.length - 1);
        word += sufix;
      } else {
        word = word + 's';
      }
    }

    return word;
  }

  public formatCpfCnpj(cpfCnpj) {
    return cpfCnpj.replace('-', '').replace('/', '').replace('.', '').replace('.', '');
  }

  public countItemCart(cart: Cart): number {
    if (cart.products == null) {
      return 0;
    } else {
      let count = 0;

      for (const prod of cart.products) {
        count += prod.quantidade;
      }

      return count;
    }
  }

  public sumCart(cart: Cart): number {
    if (cart.products.length === 0) {
      return 0;
    } else {
      let somaCarrinho = 0;
      if(this.empresa === 'logvel') {
        somaCarrinho = cart.precServico;
      } else {
        for (const prod of cart.products) {
          somaCarrinho += prod.quantidade * prod.valor;
        }
      }

      return somaCarrinho;
    }
  }

  public sumCartDiscount(cart: Cart): number {
    return this.sumCart(cart) - cart.discount;
  }

  public sumPayments(cart: Cart): number {
    var total = 0;
    if (cart.payments == null) {
      total = 0;
    } else {
      for (let p of cart.payments) {
        total += p.valor;
      }
    }
    return total;
  }

  public containsBoleto(cart: Cart): boolean {
    for (let p of cart.payments) {
      if (p.paymentForm == FormasPagamentoEnum.BOLETO) {
        return true;
      }
    }
    return false;
  }

  public chekPaymentValue(cart: Cart): Boolean {
    // confere se o valor do pagamento é igual ao total do carrinho
    if (cart.products.length === 0) {
      return false;
    } else {
      if (this.empresa === 'logvel'){
        console.log(cart.precServico, cart.discount);
        return (this.sumCartDiscount(cart) + Number(cart.entrega)).toFixed(2) === this.sumPayments(cart).toFixed(2);
      } else {
        return (this.sumCartDiscount(cart) + Number(cart.entrega)).toFixed(2) === this.sumPayments(cart).toFixed(2);
      }
    }
  }

  public printRestResponse(title: string, obj: any) {
    console.log(title + ' \\/\\/\\/\\/\\/\\/\\/\\/\\/');
    console.log(obj);
  }

  public alertErro(error) {
    let errorStr = '';
    let errorJson = error;
    console.log(errorJson)
    console.log(errorJson.error)
    console.log(errorJson.error != null)

    if (errorJson.error != null && errorJson.error.errors != null && errorJson.error.errors.length > 0) {
      console.log(1)
      for (let i = 0; i < errorJson.error.errors.length; i++) {
        errorStr += errorJson.error.errors[i] + '\n';
      }
    } else {
      console.log(2)
      errorStr = errorJson.message;
    }

    alert(errorStr);
  }

  buscaCEP(cep: string) {
    return this.http.get(`https://viacep.com.br/ws/${cep}/json/`);
  }

  getEmptyCart(): Cart {
    return new Cart({ discount: 0, entrega: 0, products: [], payments: [] });
  }

  getEmptyProduct(): Product {
    return new Product({ id: null, valor: 0, descricao: 'Certificado Indisponível', imagemBase64: null, quantidade: null, tabela: null, consultaInfo: null });
  }

  public getRequiredOptionalFields(obj) {
    var required: Array<String> = [];
    var optional: Array<String> = [];

    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null) {
          optional.push(key);
        } else {
          required.push(key);
        }
      }
    }
    return [required, optional];
  }

  public validateObjectForm(obj, required, optional, combinaCampos) {
    var msgErrors: Array<String> = [];
    var tagName = undefined;
    for (var i = 0; i < required.length; i++) {
      tagName = undefined;
      if (obj[required[i]] == null || obj[required[i]] == '') {
        for (var a = 0; a < combinaCampos.length; a++) {
          if (required[i] == combinaCampos[a]['campo']) {
            tagName = combinaCampos[a]['tag'];
            break;
          }
        }
        msgErrors.push('O campo ' + (tagName !== undefined ? tagName : required[i]) + ' é obrigatório');
      }
    }

    for (var i = 0; i < optional.length; i++) {
      if (obj[optional[i]] === '') {
        obj[optional[i]] = null;
      }
    }

    return msgErrors;
  }

  public validateObjectList(objetos, required, optional) {
    console.log(objetos)
    var msgErrors: Array<String> = [];
    var a = 0;

    for (let obj of objetos) {
      a++;
      for (var i = 0; i < required.length; i++) {
        if ((obj[required[i]] == null || obj[required[i]] == '') && obj.paymentForm !== FormasPagamentoEnum.DINHEIRO && obj.paymentForm !== FormasPagamentoEnum.SALDO) {
          msgErrors.push('O campo ' + required[i] + ' do pagamento ' + (objetos.length === 1 ? '' : a) + ' é obrigatório!');
        }
      }
    }

    a = 0;
    for (let obj of objetos) {
      a++;
      for (var i = 0; i < optional.length; i++) {
        console.log(obj.paymentForm)
        if (optional[i] != 'banco' &&
          optional[i] != 'editable' &&
          optional[i] != 'removible' &&
          (obj[optional[i]] == '' || obj[optional[i]] == 0) &&
          (obj.paymentForm == FormasPagamentoEnum.DEBITO || obj.paymentForm == FormasPagamentoEnum.CREDITO)) {
          msgErrors.push('O campo ' + optional[i] + ' é obrigatório para o pagamento ' + (objetos.length == 1 ? '' : a) + '!');
        } else if (obj[optional[i]] == '') {
          obj[optional[i]] = null;
        }
      }
    }

    return msgErrors;
  }

  formatDate(data) {
    console.log(data)
    return (data.day + '/' + data.month + '/' + data.year).replace(/\//g, '-');
  }

  formatNativeDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1], day = datePart[2];

    return day + '-' + month + '-' + year;
  }

  public adicionaZero(digito) {
    if (digito < 10) {
      return '0' + digito;
    } else {
      return digito;
    }
  }

  public parseStringVazia(str) {
    if (str == '') {
      return null;
    }

    return str;
  }

  decodeBase64(s) {
    var e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
    var A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    for (i = 0; i < 64; i++) { e[A.charAt(i)] = i; }
    for (x = 0; x < L; x++) {
      c = e[s.charAt(x)]; b = (b << 6) + c; l += 6;
      while (l >= 8) { ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a)); }
    }
    return r;
  }

  base64ToUint8(base64PDF) {
    var Base64 = { _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=', encode: function (e) { var t = ''; var n, r, i, s, o, u, a; var f = 0; e = Base64._utf8_encode(e); while (f < e.length) { n = e.charCodeAt(f++); r = e.charCodeAt(f++); i = e.charCodeAt(f++); s = n >> 2; o = (n & 3) << 4 | r >> 4; u = (r & 15) << 2 | i >> 6; a = i & 63; if (isNaN(r)) { u = a = 64 } else if (isNaN(i)) { a = 64 } t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a) } return t }, decode: function (e) { var t = ''; var n, r, i; var s, o, u, a; var f = 0; e = e.replace(/[^A-Za-z0-9+/=]/g, ''); while (f < e.length) { s = this._keyStr.indexOf(e.charAt(f++)); o = this._keyStr.indexOf(e.charAt(f++)); u = this._keyStr.indexOf(e.charAt(f++)); a = this._keyStr.indexOf(e.charAt(f++)); n = s << 2 | o >> 4; r = (o & 15) << 4 | u >> 2; i = (u & 3) << 6 | a; t = t + String.fromCharCode(n); if (u != 64) { t = t + String.fromCharCode(r) } if (a != 64) { t = t + String.fromCharCode(i) } } t = Base64._utf8_decode(t); return t }, _utf8_encode: function (e) { e = e.replace(/rn/g, 'n'); var t = ''; for (var n = 0; n < e.length; n++) { var r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r) } else if (r > 127 && r < 2048) { t += String.fromCharCode(r >> 6 | 192); t += String.fromCharCode(r & 63 | 128) } else { t += String.fromCharCode(r >> 12 | 224); t += String.fromCharCode(r >> 6 & 63 | 128); t += String.fromCharCode(r & 63 | 128) } } return t }, _utf8_decode: function (e) { var t = ''; var n = 0; var r = 0; var c2 = 0; while (n < e.length) { r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r); n++ } else if (r > 191 && r < 224) { c2 = e.charCodeAt(n + 1); t += String.fromCharCode((r & 31) << 6 | c2 & 63); n += 2 } else { c2 = e.charCodeAt(n + 1); var c3 = e.charCodeAt(n + 2); t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63); n += 3 } } return t } }

    var byteCharacters = this.decodeBase64(base64PDF);
    var byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    return new Uint8Array(byteNumbers);
  }

  downloadPDF(base64PDF, filename) {
    var byteArray = this.base64ToUint8(base64PDF);
    var file = new File([byteArray], filename + '.pdf', { type: 'application/pdf' });

    return file;
  }

  base64ToXML(base64XML, filename) {
    var byteArray = this.base64ToUint8(base64XML);
    var file = new File([byteArray], filename, { type: 'text/xml' });

    return file;
  }

  sanitizeBase64(comprovante) {
    return this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + comprovante);
  }

  convertMaquinetaToString(id: number) {
    if (id == 1) {
      return 'Lucree/Bevipag';
    } else if (id == 2) {
      return 'PagSeguro';
    } else if (id == 3) {
      return 'Stone';
    } else if (id == 4) {
      return 'Rede';
    }

    return '';
  }

  convertBancoToString(id: number) {
    if (id == 1) {
      return 'Bradesco';
    } else if (id == 2) {
      return 'Itaú';
    } else if (id == 3) {
      return 'Santander';
    } else if (id == 4) {
      return 'BV';
    } else if (id == 5) {
      return 'Omini';
    } else if (id == 6) {
      return 'Financiamento externo';
    } else if (id == 7) {
      return 'Banco Pan';
    }

    return '';
  }

  convertFormaPagamentoToString(id: number) {
    switch (id) {
      case FormasPagamentoEnum.BOLETO: {
        return 'Boleto';
      }
      case FormasPagamentoEnum.CREDITO: {
        return 'Crédito Maquineta';
      }
      case FormasPagamentoEnum.CREDITO_ONLINE: {
        return 'Crédito Online';
      }
      case FormasPagamentoEnum.CREDITO_PDV: {
        return 'Crédito PDV';
      }
      case FormasPagamentoEnum.DINHEIRO: {
        return 'Dinheiro';
      }
      case FormasPagamentoEnum.DEBITO: {
        return 'Débito Maquineta';
      }
      case FormasPagamentoEnum.LINK_CREDITO: {
        return 'Link Crédito';
      }
      case FormasPagamentoEnum.TRANSFERENCIA: {
        return 'Transferência';
      }
      case FormasPagamentoEnum.CARTA_CREDITO: {
        return 'Carta Crédito';
      }
      case FormasPagamentoEnum.VALOR_LIBERADO_FINANCIADO: {
        return 'Valor Liberado/Financiado';
      }
      case FormasPagamentoEnum.SALDO: {
        return 'Saldo em conta';
      }
      default: {
        return '---';
      }
    }
  }

  formatDateDatable(data) {
    return (data.day + '/' + data.month + '/' + data.year).replace(/\//g, '-');
  }

  public alertErroResponse(error) {
    console.log(error)
    let errorStr = '';
    if (error.errors != null && error.errors.length > 0) {
      console.log(1)
      for (let i = 0; i < error.errors.length; i++) {
        console.log('*')
        errorStr += error.errors[i] + '\n';
      }
    }

    alert(errorStr);
  }
}
