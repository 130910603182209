import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Promocao, ProdutoNovo } from 'app/_models/produtoNovo';
import { RestService } from 'app/_services/rest.service';
import { UtilsService } from 'app/_services/utils.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { forkJoin, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ClasseProduto } from 'app/_models/classeProduto';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';

@Component({
    selector: 'app-produtos-novo',
    templateUrl: './produtos-novo.component.html',
    styleUrls: ['./produtos-novo.component.less']
})
export class ProdutosNovoComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = ['id', 'descricao', 'classe', 'ativo', 'promocoes', 'descontoPorcentagem', 'tipoCert', 'tipoPessoaCert', 'acao'];
    dataSource: MatTableDataSource<ProdutoNovo> = new MatTableDataSource();
    @ViewChild(MatSort, { static: false }) sort!: MatSort;

    filteredClassesProdutos!: Observable<ClasseProduto[]>;
    classesProdutos: ClasseProduto[] = [];
    classeControl = new FormControl('');
    produtoControl = new FormControl('');
    promocaoControl = new FormControl(false);

    constructor(
        private _restService: RestService,
        private router: Router,
        private _utilsService: UtilsService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.getProdutosAndClasseProdutos();
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.createFilter();
        this.classeControl.valueChanges.subscribe(() => this.applyFilter());
        this.produtoControl.valueChanges.subscribe(() => this.applyFilter());
        this.promocaoControl.valueChanges.subscribe(() => this.applyFilter());

        this.dataSource.sortingDataAccessor = (row: ProdutoNovo, columnName: string): string | number => {
            if (columnName === "classe") {
                return row.classe ? row.classe.descricao || '' : '';
            } else if (columnName === "descontoPorcentagem") {
                const promocaoAtiva = row.promocoes.find(promocao => promocao.ativo === 'S');
                return promocaoAtiva ? promocaoAtiva.descontoPorcentagem : 0;
            }
            return row[columnName as keyof ProdutoNovo] as string;
        };
    }

    // Controla a visualização da classe no campo de autocomplete
    displayClasseProduto(classeProduto: any): string {
        return classeProduto && classeProduto.descricao ? classeProduto.descricao : '';
    }

    // Filtro para o autocomplete de Classe Produto
    private _filter(value: any): ClasseProduto[] {
        if (!this.classesProdutos || this.classesProdutos.length === 0) {
            return [];
        }

        let filterValue: string;

        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && value.descricao) {
            filterValue = value.descricao.toLowerCase();
        } else {
            filterValue = '';
        }

        return this.classesProdutos.filter(classe => classe.descricao.toLowerCase().includes(filterValue));
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent) {
        const selectedClasseProduto = event.option.value as ClasseProduto;
        this.classeControl.setValue(selectedClasseProduto);
    }

    sortProdutos(produtos: ProdutoNovo[]) {
        return produtos.sort((a: ProdutoNovo, b: ProdutoNovo) => b.id - a.id);
    }

    getProdutosAndClasseProdutos() {
        const produtos$ = this._restService.getAllProdutos();
        const classesProdutos$ = this._restService.getAllClassesProdutos();

        forkJoin([produtos$, classesProdutos$]).subscribe(([produtosRes, classesProdutosRes]) => {
            const sortedProdutos = this.sortProdutos(produtosRes);
            this.dataSource.data = sortedProdutos;

            this.classesProdutos = classesProdutosRes;
            this.filteredClassesProdutos = this.classeControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filter(value))
            );
        });
    }

    // Sobrescreve o filter predicate do MatTableDataSource
    createFilter(): (data: ProdutoNovo, filter: string) => boolean {
        return (data: ProdutoNovo, filter: string): boolean => {
            const searchTerms = JSON.parse(filter);

            const classeMatch = searchTerms.classe
                ? data.classe && data.classe.descricao && data.classe.descricao.toLowerCase().includes(searchTerms.classe)
                : true;

            const produtoMatch = searchTerms.produto
                ? (
                    data.descricao && data.descricao.toLowerCase().includes(searchTerms.produto) ||
                    data.imagemUrl && data.imagemUrl.toLowerCase().includes(searchTerms.produto) ||
                    data.tabela && data.tabela.toLowerCase().includes(searchTerms.produto) ||
                    (data.classe && data.classe.descricao && data.classe.descricao.toLowerCase().includes(searchTerms.produto)) ||
                    (data.banco && data.banco.descricao && data.banco.descricao.toLowerCase().includes(searchTerms.produto)) ||
                    (data.descricaoEcommerce && data.descricaoEcommerce.toLowerCase().includes(searchTerms.produto)) ||
                    (data.imagemEcommerce && data.imagemEcommerce.toLowerCase().includes(searchTerms.produto)) ||
                    (data.valAdesao && data.valAdesao.toString().toLowerCase().includes(searchTerms.produto)) ||
                    (data.valAtivacao && data.valAtivacao.toString().toLowerCase().includes(searchTerms.produto))
                )
                : true;

            const promocaoMatch = searchTerms.promocao
                ? this.isPromocaoAtiva(data.promocoes) === searchTerms.promocao
                : true;

            return classeMatch && produtoMatch && promocaoMatch;
        };
    }

    // Aplica os filtros sempre que há qualquer mudança nos controles
    applyFilter() {
        const filterValue = {
            classe: this.classeControl.value && typeof this.classeControl.value === 'object'
                ? this.classeControl.value.descricao.toLowerCase()
                : (this.classeControl.value ? this.classeControl.value.toLowerCase() : ''),
            produto: this.produtoControl.value ? this.produtoControl.value.toLowerCase() : '',
            promocao: this.promocaoControl.value
        };

        this.dataSource.filter = JSON.stringify(filterValue);
    }

    isPromocaoAtiva(promocoes: Promocao[]): boolean {
        return promocoes && promocoes.some(promocao => promocao.ativo === 'S') ? true : false;
    }

    getDescontoPorcentagem(promocoes: Promocao[]): string {
        const promocaoAtiva = promocoes.find(promocao => promocao.ativo === 'S');
        return `${promocaoAtiva.descontoPorcentagem}%`;
    }

    getPromocaoClass(promocoes: Promocao[]): string {
        return promocoes && promocoes.some(promocao => promocao.ativo === 'S') ? 'promo-ativa' : 'promo-inativa';
    }

    editarProduto(produto: ProdutoNovo) {
        this.router.navigate(['produto/', produto.id], {
            relativeTo: this.route
        });
    }
}
