import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import {
    Movement, operationSubType, operationType,
    MovementPutBody, MvDetailsPutBody, operationDescription, Operation
} from "app/_models/movimentacoes";
import { RestService } from "./rest.service";
import { map } from "rxjs/operators";
import { Operacao } from "app/_models/operacao";

@Injectable({
    providedIn: "root",
})
export class MovimentacoesService {
    baseApiPath: string = environment.url_api;
    movements: Movement[] = [];
    operationDescription: operationDescription[] = [];
    operationsType: operationType[] = [];
    operationsSubType: operationSubType[] = [];
    operations: Operation[];

    constructor(private http: HttpClient,
        private _restService: RestService) { }

    getMovements(searchParams: string, value: any): Observable<any> {
        const params = new HttpParams().set(searchParams, value);
        return this.http.get<any>(`${this.baseApiPath}/api/movimentacao/consulta`, { params: params });
    }

    getMovementbyId(id: number): Observable<any> {
        return this.http.get<any>(`${this.baseApiPath}/api/movimentacao/${id}`);
    }

    getOperations(): Observable<any> {
        return this.http.get<any>(`${this.baseApiPath}/api/operacao`);
    }

    getOperacaoByTipoPessoa(tipoPessoa: string): Observable<any> {
        return this.http.get<Operacao[]>(`${this.baseApiPath}/api/operacao/tipo/${tipoPessoa}`).pipe(map(res => res['data']));
    }

    getOperationsType(): Observable<any> {
        return this.http.get<any>(`${this.baseApiPath}/api/select/operacao/tipo`);
    }

    getOperationsSubType(): Observable<any> {
        return this.http.get<any>(`${this.baseApiPath}/api/select/operacao/subtipo`);
    }

    delete(id: number) {
        return this.http.delete<any>(`${this.baseApiPath}/api/movimentacao/${id}`)
    }

    putMovement(movement: MovementPutBody): Observable<any> {
        const body = movement;
        return this.http.put<any>(`${this.baseApiPath}/api/movimentacao`, body);
    }

    putMvDetails(mvDetails: MvDetailsPutBody): Observable<any> {
        const body = mvDetails;
        return this.http.put<any>(`${this.baseApiPath}/api/movimentacao/detalhe_mv`, body);
    }

    findMovementbyId(id: number) {
        const movement = this.movements.find(movement => movement.id = id);
        return movement;
    }

    findOperationById(id: number): string {
        const operation = this.operations.find(operation => operation.id == id);
        return operation.descricao ? operation.descricao : "Tipo não informado";
    }

    findOperationTypebyId(id: number): string {
        const operationType = this.operationsType.find(operation => operation.id == id);
        return operationType ? operationType.text : "Tipo não informado";
    }

    findOperationSubTypebyId(id: number): string {
        const operationSubType = this.operationsSubType.find(operation => operation.id == id);
        return operationSubType ? operationSubType.text : "Subtipo não informado";
    }

    // getOperacaoPessoas(): IPeopleOperation[] {
    //     return this.operacaoPessoas;
    // }

    setMovements(movements: Movement[]) {
        this.movements = movements;
    }

    setOperations() {
        this.getOperations().subscribe(
            res => {
                this.operations = res.data;
            }
        )
    }
    
    // setOperacaoPessoas(idOperacao: number) {
    //     this._restService.getOperacaoPessoas(idOperacao).subscribe(
    //         operacaoPessoas => {
    //             this.operacaoPessoas = operacaoPessoas;
    //             console.log(this.operacaoPessoas)
    //         }
    //     )
    // }

    setOperationsType() {
        this.getOperationsType().subscribe(
            res => {
                const operationsType = res.data;
                this.operationsType = operationsType;
            }
        )
    }

    setOperationsSubType() {
        this.getOperationsSubType().subscribe(
            res => {
                const operationsSubType = res.data;
                this.operationsSubType = operationsSubType;
            }
        )
    }
}
