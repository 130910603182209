import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../../../../_services/rest.service';
import { Pessoa } from '../../../../_models/pessoa';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { CepService } from 'app/_services/cep.service';
import { Location } from "@angular/common";
import { MatAutocompleteSelectedEvent, MatSnackBar } from '@angular/material';
import { Unidade } from 'app/_models/unidade';
import { pessoaTipoEnum } from 'app/_enums/pessoaTipo.enum';
import { CustomValidation } from 'app/_utils/form-validations/CustomValidation';
import { TipoPessoa } from 'app/_models/tipoPessoa';
import { TiposPessoaEnum } from 'app/_enums/tiposPessoaEnum';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-pessoa',
  templateUrl: "./pessoa.component.html",
  styleUrls: ["./pessoa.component.less"]
})
export class PessoaComponent implements OnInit {
  public cepResult: any;
  pessoaTipoEnum = pessoaTipoEnum;
  tiposPessoaEnum = TiposPessoaEnum;

  id: string;
  pessoaData: Pessoa;
  isLoading: boolean;
  isSearching: boolean;

  filteredUnidades!: Observable<Unidade[]>;
  unidades: Unidade[] = [];
  myControl = new FormControl('', Validators.required);

  hide: boolean = true;
  pessoaForm: FormGroup;
  todosTiposPessoa: TipoPessoa[];
  private searchTerms = new Subject<string>();
  filteredOptions!: Observable<string[]>;
  idPessoa: number;

  tiposSelect = [
    { value: pessoaTipoEnum.DIRETOR, viewValue: 'DIRETOR' },
    { value: pessoaTipoEnum.GERENTE_COMERCIAL, viewValue: 'GERENTE COMERCIAL' },
    { value: pessoaTipoEnum.SUPERVISOR_COMERCIAL, viewValue: 'SUPERVISOR COMERCIAL' },
    { value: pessoaTipoEnum.GESTOR_FRANQUIA, viewValue: 'GESTOR FRANQUIA' },
    { value: pessoaTipoEnum.PDV, viewValue: 'PDV' },
    { value: pessoaTipoEnum.FORNECEDOR, viewValue: 'FORNECEDOR' },
    { value: pessoaTipoEnum.CLIENTE, viewValue: 'CLIENTE' },
    { value: pessoaTipoEnum.CONTA_CONCILIACAO, viewValue: 'CONTA CONCILIACAO' },
    { value: pessoaTipoEnum.CAIXA, viewValue: 'CAIXA' },
    { value: pessoaTipoEnum.CONTA_CORRENTE_BANCO, viewValue: 'CONTA CORRENTE BANCO' },
    { value: pessoaTipoEnum.FINANCEIRO, viewValue: 'FINANCEIRO' },
    { value: pessoaTipoEnum.SUPORTE_MOTOS, viewValue: 'SUPORTE MOTOS' },
    { value: pessoaTipoEnum.SUPORTE_CERTIFICADO_DIGITAL, viewValue: 'SUPORTE CERTIFICADO DIGITAL' },
    { value: pessoaTipoEnum.CONTROLE_DE_SALDO, viewValue: 'CONTROLE DE SALDO' },
    { value: pessoaTipoEnum.INDICADOR, viewValue: 'INDICADOR' },
  ];

  netSelect = [
    { value: 'S', viewValue: 'SIM' },
    { value: 'N', viewValue: 'NÃO' },
  ];

  tiposPessoa = {
    [pessoaTipoEnum.DIRETOR]: 'DIRETOR',
    [pessoaTipoEnum.GERENTE_COMERCIAL]: 'GERENTE COMERCIAL',
    [pessoaTipoEnum.SUPERVISOR_COMERCIAL]: 'SUPERVISOR COMERCIAL',
    [pessoaTipoEnum.GESTOR_FRANQUIA]: 'GESTOR FRANQUIA',
    [pessoaTipoEnum.PDV]: 'PDV',
    [pessoaTipoEnum.FORNECEDOR]: 'FORNECEDOR',
    [pessoaTipoEnum.CLIENTE]: 'CLIENTE',
    [pessoaTipoEnum.CONTA_CONCILIACAO]: 'CONTA CONCILIACAO',
    [pessoaTipoEnum.CAIXA]: 'CAIXA',
    [pessoaTipoEnum.CONTA_CORRENTE_BANCO]: 'CONTA CORRENTE BANCO',
    [pessoaTipoEnum.FINANCEIRO]: 'FINANCEIRO',
    [pessoaTipoEnum.SUPORTE_MOTOS]: 'SUPORTE MOTOS',
    [pessoaTipoEnum.SUPORTE_CERTIFICADO_DIGITAL]: 'SUPORTE CERTIFICADO DIGITAL',
    [pessoaTipoEnum.CONTROLE_DE_SALDO]: 'CONTROLE DE SALDO',
    [pessoaTipoEnum.INDICADOR]: 'INDICADOR'
  };

  tiposContas = [
    pessoaTipoEnum.CONTA_CORRENTE_BANCO,
    pessoaTipoEnum.CAIXA,
    pessoaTipoEnum.CONTA_CONCILIACAO,
    pessoaTipoEnum.CONTROLE_DE_SALDO,
    pessoaTipoEnum.FORNECEDOR
  ];

  tiposAcessosEntidades = [
    pessoaTipoEnum.GERENTE_COMERCIAL,
    pessoaTipoEnum.SUPERVISOR_COMERCIAL,
    pessoaTipoEnum.GESTOR_FRANQUIA,
    pessoaTipoEnum.DIRETOR,
    pessoaTipoEnum.FINANCEIRO,
    pessoaTipoEnum.SUPORTE_CERTIFICADO_DIGITAL,
    pessoaTipoEnum.SUPORTE_MOTOS
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _restService: RestService,
    private _cepService: CepService,
    private location: Location,
    private _snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.buildFormGroup();

    this.isLoading = false;
    this.tiposSorting();
    this.getTodosTipoPessoa();

    this.route.paramMap.subscribe(params => {
      if (params.get('id') !== null) {
        this.idPessoa = parseInt(params.get('id'));

        this.getUnidadesAndPessoa(this.idPessoa);
      } else {
        this.getUnidades();
      }
    });

    this.pessoaForm.get('tipoPessoa').valueChanges.subscribe((tipoPessoa: string) => {
      const cpfCnpjControl = this.pessoaForm.get('cpfCnpj');

      cpfCnpjControl.clearValidators();

      if (tipoPessoa === TiposPessoaEnum.FISICA) {
        cpfCnpjControl.setValidators([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ]);
      } else if (tipoPessoa === TiposPessoaEnum.JURIDICA) {
        cpfCnpjControl.setValidators([
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(14),
        ]);
      }

      cpfCnpjControl.updateValueAndValidity();
    });
  }

  private _filter(value: string): Unidade[] {
    if (!this.unidades || this.unidades.length === 0) {
      return [];
    }

    const filterValue = value.toLowerCase()

    return this.unidades.filter(unidade => unidade.text.toLowerCase().includes(filterValue));
  }

  displayUnidade(unidade: any): string {
    return unidade && unidade.text ? unidade.text : '';
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedUnidade = event.option.value as Unidade;
    this.pessoaForm.get('idOperacoes').setValue(selectedUnidade);
  }

  showContactRow(): boolean {
    let tipoObjeto;

    if (this.pessoaForm.get('id').value) {
      tipoObjeto = this.pessoaForm.get('tipoPessoaDto').value
    } else {
      const tipoId = this.pessoaForm.get('idTipo').value;
      tipoObjeto = this.todosTiposPessoa.find(tipo => tipo.id === tipoId);
    }

    return tipoObjeto.tipo !== this.pessoaTipoEnum.PDV && !this.tiposContas.includes(tipoObjeto.tipo as pessoaTipoEnum) && !this.tiposAcessosEntidades.includes(tipoObjeto.tipo as pessoaTipoEnum);
  }

  showAddressRow(): boolean {
    let tipoObjeto;

    if (this.pessoaForm.get('id').value) {
      tipoObjeto = this.pessoaForm.get('tipoPessoaDto').value
    } else {
      const tipoId = this.pessoaForm.get('idTipo').value;
      tipoObjeto = this.todosTiposPessoa.find(tipo => tipo.id === tipoId);
    }
    return !this.tiposContas.includes(tipoObjeto.tipo as pessoaTipoEnum) && !this.tiposAcessosEntidades.includes(tipoObjeto.tipo as pessoaTipoEnum);
  }

  showPasswordRow(): boolean {
    let tipoObjeto;

    if (this.pessoaForm.get('id').value) {
      tipoObjeto = this.pessoaForm.get('tipoPessoaDto').value
    } else {
      const tipoId = this.pessoaForm.get('idTipo').value;
      tipoObjeto = this.todosTiposPessoa.find(tipo => tipo.id === tipoId);
    }
    return tipoObjeto.tipo === this.pessoaTipoEnum.PDV || this.tiposAcessosEntidades.includes(tipoObjeto.tipo as pessoaTipoEnum);
  }

  hideControl(): boolean {
    return this.hide = !this.hide
  }

  getPropriedades(object: Pessoa): string[] {
    return Object.keys(object);
  }
  // Autocomplete
  search(term: string): void {
    if (term.length > 2) {
      this.searchTerms.next(term);
    }
  }

  searchCEP() {
    if (this.pessoaForm.get('cep').value.length === 8) {
      let cepValue = this.pessoaForm.get('cep').value
      this._cepService.buscar(cepValue).subscribe(
        cep => {
          if (cep.erro) {
            this.cepResult = {
              logradouro: '',
              localidade: '',
              bairro: '',
              uf: '',
              ibge: ''
            };
          } else {
            // CEP que não existe 55600-000
            this.cepResult = cep;
            // 55125-000
            const cepForm = {
              endereco: this.cepResult.logradouro,
              cidade: this.cepResult.localidade,
              bairro: this.cepResult.bairro,
              uf: this.cepResult.uf,
              cMun: parseInt(this.cepResult.ibge)
            }

            // Preencher apenas os campos vazios
            Object.keys(cepForm).forEach(key => {
              if (cepForm[key]) {
                this.pessoaForm.get(key).setValue(cepForm[key]);
              }
            });
          }
        }, _ => {
          this.pessoaForm.get('cidade').setValue('');
          this.pessoaForm.get('endereco').setValue('');
          this.pessoaForm.get('bairro').setValue('');
          this.pessoaForm.get('uf').setValue('');
          this.cepResult = {
            logradouro: '',
            localidade: '',
            bairro: '',
            uf: '',
            ibge: ''
          };
        }
      )
    } else {
      this.pessoaForm.get('cidade').setValue('');
      this.pessoaForm.get('endereco').setValue('');
      this.pessoaForm.get('bairro').setValue('');
      this.pessoaForm.get('uf').setValue('');
      this.cepResult = {
        logradouro: '',
        localidade: '',
        bairro: '',
        uf: ''
      };
    }
  }

  back() {
    this.location.back();
  }

  buildFormGroup(tipoObjeto?: any, valuesToPatch?, tipo?: number) {
    const customValidation = new CustomValidation();

    this.pessoaForm = new FormGroup({
      id: new FormControl(''),
      idOperacoes: this.myControl,
      codIntegracao: new FormControl(''),
      acessaNet: new FormControl(''),
      cpfCnpj: new FormControl('', Validators.required),
      idTipo: new FormControl(tipo ? tipo : ''),
      tipoPessoaDto: new FormControl(''),
      nome: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]),
      endereco: new FormControl(''),
      nro: new FormControl(''),
      bairro: new FormControl(''),
      cidade: new FormControl(''),
      cMun: new FormControl(''),
      cep: new FormControl(''),
      fone: new FormControl(''),
      celular: new FormControl(''),
      email: new FormControl(''),
      uf: new FormControl(''),
      dataNascimento: new FormControl(''),
      tipoPessoa: new FormControl('', Validators.required),
      sexo: new FormControl(''),
      idIndicador: new FormControl(''),
      inscricaoEstadual: new FormControl(''),
      senhaNet: new FormControl(''),
      comissao: new FormControl(''),
      cpfPessoaReferencia: new FormControl('', [customValidation.isValidCPF()]),
      pessoaReferencia: new FormControl(''),
      telefoneReferencia: new FormControl(''),
    });

    if (valuesToPatch) {
      this.pessoaForm.patchValue(valuesToPatch);
    }

    if (tipoObjeto) {
      this.pessoaForm.get('tipoPessoaDto').setValue(tipoObjeto)
    }
  }

  isUnidade(obj: any): obj is Unidade {
    return obj && typeof obj.id === 'number' && typeof obj.text === 'string';
  }

  onSubmit() {
    if (this.pessoaForm.valid) {
      this.isLoading = true;

      const fieldsToUppercase = ['nome', 'endereco', 'bairro', 'cidade', 'uf'];

      for (const field of fieldsToUppercase) {
        const fieldValue = this.pessoaForm.get(field).value;
        if (fieldValue && typeof fieldValue === 'string') {
          this.pessoaForm.get(field).setValue(fieldValue.toUpperCase());
        }
      }

      const unidade = this.pessoaForm.get('idOperacoes').value;

      if (this.isUnidade(unidade)) {
        this.pessoaForm.get('idOperacoes').setValue(unidade.id);
      }

      if (this.pessoaForm.get('id').value !== undefined && this.pessoaForm.get('id').value !== null && this.pessoaForm.get('id').value !== "") {
        this.updatePessoa();
      } else {
        this.addPessoa();
      }
    } else {
      Object.keys(this.pessoaForm.controls).forEach(field => {
        const control = this.pessoaForm.get(field);

        if (control.invalid) {
          Object.keys(control.errors).forEach(errorType => {
            console.error(`Campo ${field} tem o erro: ${errorType}`);
          });
        }
      });
      this.isLoading = false;
      console.error('Formulário inválido');
      alert('Erro: formulário inválido');
    }
  }

  getTodosTipoPessoa() {
    this._restService.getTodosTipoPessoa().subscribe(
      res => {
        this.todosTiposPessoa = res;
      }, error => {
        console.error('Erro: ', error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    )
  }

  auxGetPessoaGeneric() {
    this.pessoaForm.get('dataNascimento').setValue(this.unformatDate(this.pessoaForm.get('dataNascimento').value));
    const tipoPessoa = this.pessoaForm.get('tipoPessoaDto').value.tipo;

    if (tipoPessoa === pessoaTipoEnum.PDV) {
      this.setPdvValidators();
    } else if (tipoPessoa === pessoaTipoEnum.INDICADOR) {
      this.setIndicadorValidators()
    } else if (tipoPessoa === pessoaTipoEnum.CLIENTE) {
      this.setClienteValidators();
    } else if (this.tiposAcessosEntidades.includes(tipoPessoa as pessoaTipoEnum)) {
      this.setAcessosEntidadesValidators();
    }
  }

  getUnidades() {

    this._restService.getUnidades().subscribe(data => {
      this.unidades = data.data;
    }, error => {
      console.error('Erro: ', error.error.errors[0]);
      alert(`Erro: ${error.error.errors[0]}`);
    });

    this.filteredUnidades = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
        return this._filter(filterValue);
      })
    );
  }

  getUnidadesAndPessoa(id: number) {
    this.isSearching = true;

    const pessoa$ = this._restService.getPessoaGeneric(id);
    const unidades$ = this._restService.getUnidades();

    forkJoin([pessoa$, unidades$]).subscribe(([pessoaRes, unidadesRes]) => {
      this.pessoaData = pessoaRes;
      this.pessoaForm.patchValue(pessoaRes.data);
      this.auxGetPessoaGeneric();

      if (pessoaRes.data.cep) {
        // Atualizar código cMun dos clientes que não tem salvo no banco
        this.searchCEP();
      }

      this.unidades = unidadesRes.data;

      const unidade = this.unidades.find(unidade => unidade.id === this.pessoaForm.get('idOperacoes').value);
      if (unidade) {
        this.myControl.setValue(unidade);
        this.pessoaForm.get('idOperacoes').setValue(unidade);
      }

      this.isSearching = false;
    });

    this.filteredUnidades = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
        return this._filter(filterValue);
      })
    );
  }

  formatDate(date: string): Date {
    if (date && date.length === 10) {
      const ano = parseInt(date.substring(0, 4), 10);
      const mes = parseInt(date.substring(5, 7), 10) - 1; // Meses são baseados em zero no objeto Date
      const dia = parseInt(date.substring(8), 10);
      return new Date(dia, mes, ano);
    } else {
      return null;
    }
  }

  unformatDate(date: string | Date): string {
    if (!date) {
      return null;
    }

    const dateObj = typeof date === 'string' ? new Date(date) : date;

    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
      return null; // Verifica se a data é válida
    }

    const ano = dateObj.getFullYear();
    const mes = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Meses são baseados em zero no objeto Date
    const dia = dateObj.getDate().toString().padStart(2, '0');

    return `${ano}-${mes}-${dia}`;
  }

  setClienteValidators() {
    this.setCustomCpfCnpjValidators();
    this.setAddressValidators();

    if (this.pessoaForm.get('tipoPessoa').value === TiposPessoaEnum.FISICA) {
      this.pessoaForm.get('dataNascimento').setValidators([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]);
    }

    this.pessoaForm.get('sexo').setValidators([
      Validators.required,
    ]);

    this.pessoaForm.get('email').setValidators([
      Validators.required,
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    ]);

    this.pessoaForm.get('cpfCnpj').updateValueAndValidity();
    this.pessoaForm.get('dataNascimento').updateValueAndValidity();
    this.pessoaForm.get('sexo').updateValueAndValidity();
    this.pessoaForm.get('email').updateValueAndValidity();
  }

  setPdvValidators() {
    this.setAddressValidators();

    this.pessoaForm.get('acessaNet').setValidators([
      Validators.required,
    ]);

    if (this.pessoaForm.get('acessaNet').value === 'S') {
      this.pessoaForm.get('senhaNet').setValidators([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(15),
        Validators.pattern(/^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/)
      ]);
    }

    this.pessoaForm.get('acessaNet').updateValueAndValidity();
    this.pessoaForm.get('senhaNet').updateValueAndValidity();
  }

  setAcessosEntidadesValidators() {
    this.setCustomCpfCnpjValidators();

    this.pessoaForm.get('acessaNet').setValidators([
      Validators.required,
    ]);

    if (this.pessoaForm.get('acessaNet').value === 'S') {
      this.pessoaForm.get('senhaNet').setValidators([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(15),
        Validators.pattern(/^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/)
      ]);
    }

    this.pessoaForm.get('cpfCnpj').updateValueAndValidity();
    this.pessoaForm.get('acessaNet').updateValueAndValidity();
    this.pessoaForm.get('senhaNet').updateValueAndValidity();
  }

  setIndicadorValidators() {
    this.setCustomCpfCnpjValidators();
    this.setAddressValidators();

    this.pessoaForm.get('dataNascimento').setValidators([
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10)
    ]);

    this.pessoaForm.get('celular').setValidators([
      Validators.required,
    ]);

    this.pessoaForm.get('email').setValidators([
      Validators.required,
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    ]);

    this.pessoaForm.get('comissao').setValidators([
      Validators.required,
      Validators.min(-100),
      Validators.max(100),
    ]);
  }

  setCustomCpfCnpjValidators() {
    const customValidation = new CustomValidation();

    if (this.pessoaForm.get('tipoPessoa').value === TiposPessoaEnum.FISICA) {
      this.pessoaForm.get('cpfCnpj').setValidators([
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(11),
        customValidation.isValidCPF()
      ]);
    } else if (this.pessoaForm.get('tipoPessoa').value === TiposPessoaEnum.JURIDICA) {
      this.pessoaForm.get('cpfCnpj').setValidators([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14),
        customValidation.isValidCNPJ()
      ]);
    }
  }

  setAddressValidators() {
    this.pessoaForm.get('cep').setValidators([
      Validators.required,
    ]);

    this.pessoaForm.get('endereco').setValidators([
      Validators.required,
    ]);

    this.pessoaForm.get('nro').setValidators([
      Validators.required,
    ]);

    this.pessoaForm.get('bairro').setValidators([
      Validators.required,
    ]);

    this.pessoaForm.get('cidade').setValidators([
      Validators.required,
    ]);

    this.pessoaForm.get('uf').setValidators([
      Validators.required,
    ]);

    this.pessoaForm.get('cep').updateValueAndValidity();
    this.pessoaForm.get('endereco').updateValueAndValidity();
    this.pessoaForm.get('nro').updateValueAndValidity();
    this.pessoaForm.get('bairro').updateValueAndValidity();
    this.pessoaForm.get('cidade').updateValueAndValidity();
    this.pessoaForm.get('uf').updateValueAndValidity();
  }

  onTipoChange(event: any) {
    // Validators dos tiposContas já são padrões no FormGroup

    const novoTipoId = event.value;

    const tipoObjeto = this.todosTiposPessoa.find(tipo => tipo.id === novoTipoId);

    const valuesToPatch = this.pessoaForm.value;

    this.buildFormGroup(tipoObjeto, valuesToPatch, novoTipoId);

    if (tipoObjeto.tipo === pessoaTipoEnum.CLIENTE) {
      this.setClienteValidators();
    }

    if (tipoObjeto.tipo === pessoaTipoEnum.PDV) {
      this.setPdvValidators();
    }

    else if (tipoObjeto.tipo === pessoaTipoEnum.INDICADOR) {
      this.setIndicadorValidators()
    }

    else if (this.tiposAcessosEntidades.includes(tipoObjeto.tipo as pessoaTipoEnum)) {
      this.setAcessosEntidadesValidators();
    }
  }

  onTipoPessoaChange(event: any) {
    const tipoObj = this.pessoaForm.get('tipoPessoaDto').value

    const novoTipoPessoa = event.value;

    if (novoTipoPessoa === TiposPessoaEnum.FISICA) {
      this.pessoaForm.get('dataNascimento').setValidators([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]);

      this.pessoaForm.get('sexo').setValidators([
        Validators.required,
      ]);

      if (this.tiposContas.includes(tipoObj.tipo as pessoaTipoEnum)) {
        this.pessoaForm.get('cpfCnpj').setValidators([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ]);
      } else {
        this.setCustomCpfCnpjValidators();
      }

    } else if (novoTipoPessoa === TiposPessoaEnum.JURIDICA) {
      this.pessoaForm.get('dataNascimento').clearValidators();

      this.pessoaForm.get('dataNascimento').updateValueAndValidity();

      if (this.tiposContas.includes(tipoObj.tipo as pessoaTipoEnum)) {
        this.pessoaForm.get('cpfCnpj').setValidators([
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(14),
        ]);
      } else {
        this.setCustomCpfCnpjValidators();
      }
    }

    this.pessoaForm.get('cpfCnpj').updateValueAndValidity();
  }

  onAcessaNetChange(event: any) {
    const novoAcessaNet = event.value;

    if (novoAcessaNet === 'S') {
      this.setSenhaNetValidators();
    }
    else if (novoAcessaNet === 'N') {
      this.pessoaForm.get('senhaNet').clearValidators();

      this.pessoaForm.get('senhaNet').updateValueAndValidity();
    }
  }

  setSenhaNetValidators() {
    this.pessoaForm.get('senhaNet').setValidators([
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(15),
      Validators.pattern(/^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/)
    ]);
  }

  tiposSorting() {
    this.tiposSelect.sort((a, b) => {
      if (a.viewValue < b.viewValue) {
        return -1;
      }
      if (a.viewValue > b.viewValue) {
        return 1;
      }
      return 0;
    });
  }

  addPessoa() {
    this._restService.addPessoaGeneric(this.pessoaForm.value).subscribe(
      res => {
        const tipoObjeto = this.pessoaForm.get('tipoPessoaDto').value

        console.log(`Pessoa ${tipoObjeto.descricao} cadastrada com sucesso`);
        this._snackBar.open(`A pessoa ${this.pessoaForm.get('nome').value} foi cadastrada com sucesso`, "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/pessoas']);
      },
      error => {
        this.isLoading = false;
        console.error('Error: ', error.error.errors[0]);

        if (error.error.errors[0].defaultMessage) {
          console.error('Error: ', error.error.errors[0].defaultMessage);
          alert(`Erro: ${error.error.errors[0].defaultMessage}`)
        } else {
          console.error('Error: ', error.error.errors[0]);
          alert(`Erro: ${error.error.errors[0]}`);
        }
      }
    );
  }

  updatePessoa() {
    this._restService.updatePessoaGeneric(this.pessoaForm.value, this.idPessoa).subscribe(
      _ => {
        this._snackBar.open(`A pessoa ${this.pessoaForm.get('nome').value} foi atualizada com sucesso`, "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/pessoas']);
      },
      error => {
        this.isLoading = false;
        console.error('Error: ', error.error.errors[0]);

        if (error.error.errors[0].defaultMessage) {
          console.error('Error: ', error.error.errors[0].defaultMessage);
          alert(`Erro: ${error.error.errors[0].defaultMessage}`)
        } else {
          console.error('Error: ', error.error.errors[0]);
          alert(`Erro: ${error.error.errors[0]}`);
        }
      }
    );
  }
}