import { Unidade } from './../../../../_models/unidade';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Pdv } from 'app/_models/pdv';
import { RestService } from 'app/_services/rest.service';
import { UtilsService } from 'app/_services/utils.service';
import { Observable } from 'rxjs';
import { Moto } from 'app/_models/moto';
import { PDVEnum } from 'app/_enums/pdvEnum';
import { Subscription } from 'rxjs';
import { TransferenciaMultipla } from 'app/_models/transferenciaMultipla';

export interface DialogData {
  motos: Moto[];
  matriz: number;
}

@Component({
  selector: 'app-modal-transferencia-multipla',
  templateUrl: './modal-transferencia-multipla.component.html',
})

export class ModalTransferenciaMultiplaComponent implements OnInit {
  displayedColumns: string[] = ['chassi', 'descricao', 'pdv', 'detalhes'];
  dataSource: MatTableDataSource<Moto> = new MatTableDataSource(this.data.motos);
  motosUnicas: Moto[] = [];
  remessaForm: FormGroup;
  isLoading: boolean = false;

  unidades: Unidade[] = [];
  filteredUnidades!: Observable<Unidade[]>;
  unidadeControl = new FormControl({ value: '', disabled: true }, Validators.required);

  pdvs: Pdv[] = [];
  filteredPdvs!: Observable<Pdv[]>;
  pdvControl = new FormControl({ value: '', disabled: true }, Validators.required);

  validPdv: boolean = false;
  formValid: boolean = false;
  showRemessa: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ModalTransferenciaMultiplaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private _restService: RestService,
    private _utilsService: UtilsService
  ) {
    this.remessaForm = this.fb.group({
      motos: [data.motos],
      destinoId: ['', Validators.required],
    });

    this.remessaForm.statusChanges.subscribe(() => this.updateFormValidity());
    this.unidadeControl.statusChanges.subscribe(() => this.updateFormValidity());
    this.pdvControl.statusChanges.subscribe(() => this.updateFormValidity());
  }

  ngOnInit() {
    this.getUnidades(this.data.matriz);

    this.motosUnicas = Array.from(
      new Map(this.data.motos.map(obj => [obj.idClasse, obj])).values()
    );

    this.motosUnicas.forEach(moto => {
      this.remessaForm.addControl(moto.idClasse.toString(), this.fb.control('', Validators.required));
    });

    this.updateFormValidity();
  }

  getColor(cor) {
    if (cor !== undefined && cor.includes('VERME')) {
      return 'red';
    } else if (cor !== undefined && cor.includes('BRAN')) {
      return 'gray';
    } else {
      return 'black';
    }
  }

  getUnidades(idMatriz: number) {
    this.unidadeControl.disable();

    this._restService.getUnidadesByMatriz(idMatriz).subscribe(
      res => {
        this.unidades = res.data;

        this.unidadeControl.enable();

        this.filteredUnidades = this.unidadeControl.valueChanges.pipe(
          startWith(''),
          map((value: string | Unidade) => this._filterUnidades(value))
        );
      },
      error => {
        this._utilsService.alertErro(error);
      }
    );
  }

  getPdvs(idUnidade: number) {
    this.pdvControl.setValue('');
    this.pdvControl.disable();

    this._restService.getPDVsAdm(idUnidade).subscribe(
      res => {
        this.pdvs = res;

        this.pdvControl.enable();

        this.filteredPdvs = this.pdvControl.valueChanges.pipe(
          startWith(''),
          map((value: string | Pdv) => this._filterPdvs(value))
        );
      },
      error => {
        this._utilsService.alertErro(error);
      }
    );
  }

  // Controla a visualização de unidade no campo de autocomplete
  displayUnidade(unidade: Unidade): string {
    return unidade && unidade.text ? unidade.text : '';
  }

  // Controla a visualização de pdv no campo de autocomplete
  displayPdv(pdv: Pdv): string {
    return pdv && pdv.text ? pdv.text : '';
  }

  // Filtro para o autocomplete de Unidade 
  private _filterUnidades(value: string | Unidade): Unidade[] {
    if (!this.unidades || this.unidades.length === 0) {
      return [];
    }

    const filterValue = typeof value === 'string'
      ? value.toLowerCase()
      : value.text.toLowerCase();

    return this.unidades.filter(unidade =>
      unidade.text.toLowerCase().includes(filterValue)
    );
  }

  // Filtro para o autocomplete de Pdv 
  private _filterPdvs(value: string | Pdv): Pdv[] {
    if (!this.pdvs || this.pdvs.length === 0) {
      return [];
    }

    let filterValue = '';
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else if (value && value.text) {
      filterValue = value.text.toLowerCase();
    }

    return this.pdvs.filter(pdv =>
      pdv && pdv.text && pdv.text.toLowerCase().includes(filterValue)
    );
  }

  onUnidadeOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedUnidadeProduto = event.option.value as Unidade;
    this.unidadeControl.setValue(selectedUnidadeProduto);

    this.getPdvs(this.unidadeControl.value.id);
    this.updateFormValidity();
  }

  onPdvOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedPdvProduto = event.option.value as Pdv;
    this.pdvControl.setValue(selectedPdvProduto);

    this.remessaForm.get('destinoId').setValue(this.pdvControl.value.id);

    if (selectedPdvProduto.id === PDVEnum.PEVALORIZA_COMERCIO_DE_MOTOS_E_PECAS) {
      this.showRemessa = false;
      this.setValorRemessaZero();
    } else {
      this.showRemessa = true;
    }

    this.checkPDV();
  }

  setValorRemessaZero() {
    const motosFormArray = this.remessaForm.get('motos').value;

    motosFormArray.forEach(moto => {
      if (moto) {
        moto.valorRemessa = 0;

        const control = this.remessaForm.get(moto.idClasse.toString());
        if (control) {
          control.setValue(0, { emitEvent: false });
        }
      }
    });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  checkPDV() {
    const hasMotosInSelectedPdv = this.data.motos.some(moto => moto.idPdv === this.pdvControl.value.id);

    this.validPdv = !hasMotosInSelectedPdv;
    this.updateFormValidity();
  }

  updateFormValidity() {
    this.formValid = this.remessaForm.valid &&
      this.validPdv &&
      this.pdvControl.valid &&
      this.unidadeControl.valid;
  }

  setForm(): TransferenciaMultipla {
    const motosFormArray = this.remessaForm.get('motos').value;

    motosFormArray.forEach(moto => {
      const valorRemessa = this.remessaForm.get(moto.idClasse.toString()).value;
      if (moto) {
        moto.valorRemessa = parseFloat(valorRemessa);
      }
    });

    const transferenciaForm = {
      motos: motosFormArray,
      destinoId: this.remessaForm.get('destinoId').value
    };

    return transferenciaForm;
  }

  onSubmit(): void {
    if (this.remessaForm.valid) {
      this.isLoading = true;

      const transferenciaForm: TransferenciaMultipla = this.setForm();

      this._restService.transferirMotosEmLote(transferenciaForm).subscribe(
        response => {
          this.isLoading = false;

          if (response.errors && response.errors.length > 0) {
            const errorMessage = response.errors.map((error, index) => 
              `Erro ${index + 1}:\n${error}\n`
            ).join('\n');
      
            alert(`Foram encontrados ${response.errors.length} erros durante a transferência:\n\n${errorMessage}`);
            return;
          }

          const successCount = response.data.filter(item => item && item.resultado).length;

          alert(`Transferência concluída. ${successCount} moto(s) transferida(s) com sucesso.`);
          this.dialogRef.close(response);
        },
        error => {
          this.isLoading = false;
          console.error('Erro na transferência em lote:', error);
          this._utilsService.alertErro('Erro ao realizar a transferência em lote.');
        }
      );
    } else {
      alert("Formulário inválido");
    }
  }
}