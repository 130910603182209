import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmissoesNFeAssincronaDTO } from 'app/_models/emissaoNFeAssincrona';
import { Pageable } from 'app/_models/Pageable';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmissoesNfeAssincronaService {
  baseApiPath: string = environment.url_api;
  
  constructor(
    private http: HttpClient 
  ) { }

  getEmissoes(
    dataInicio: string, 
    dataFim: string, 
    chassi?: string, 
    page: number = 0, 
    size: number = 10
  ): Observable<Pageable<EmissoesNFeAssincronaDTO>> {
    let params = new HttpParams()
        .set('dataInicial', dataInicio)
        .set('dataFinal', dataFim)
        .set('page', page.toString())
        .set('size', size.toString());

    if (chassi) {
        params = params.set('chassi', chassi);
    }

    return this.http.get(`${this.baseApiPath}/api/emissao/nfe`, { params })
                    .pipe(
                      map(response => response['data'])
                    );
  }
}
