export class MotoInfo {
  public chassi: string;
  public comprovanteChassi: string;
  public prazoEntrega: number;

  constructor(json: any) {
      this.chassi = json.chassi;
      this.comprovanteChassi = json.comprovanteChassi;
      this.prazoEntrega = json.prazoEntrega;
  }
}
