import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource, PageEvent } from '@angular/material';
import { EmissoesNFeAssincronaDTO } from 'app/_models/emissaoNFeAssincrona';
import { Pageable } from 'app/_models/Pageable';
import { EmissoesNfeAssincronaService } from 'app/_services/emissoes-nfe-assincrona.service';
import { DateHelper } from 'app/theme/shared/helpers/date.helper';
import * as format from 'date-fns/format';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-emissoes-nfe-assincrona',
  templateUrl: './emissoes-nfe-assincrona.component.html',
  styleUrls: ['./emissoes-nfe-assincrona.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EmissoesNfeAssincronaComponent implements OnInit {
  filtroForm = this.formBuilder.group({
    dataInicio: [
      format(new Date().setDate(1), 'YYYY-MM-DD'), 
      [Validators.required, Validators.pattern(/^\d{4}\-\d{2}\-\d{2}$/)]
    ],
    dataFim: [
      DateHelper.DataHojeFormatada(), 
      [Validators.required, Validators.pattern(/^\d{4}\-\d{2}\-\d{2}$/)]
    ],
    chassi: [""]
  });

  displayedColumns: string[] = ['#', 'dataEmissao', 'matrizEmitente', 'chassi', 'tipo', 'status'];
  dataSource = new MatTableDataSource<EmissoesNFeAssincronaDTO>([]);
  expandedElement: EmissoesNFeAssincronaDTO | null = null;
  pageSizeOptions: number[] = [2, 5, 10, 30, 50, 100, 150, 200, 250, 300];

  readonly DEFAULT_PAGE_SIZE = 150;
  readonly DEFAULT_PAGE_NUMBER = 0;

  emissoes: Pageable<EmissoesNFeAssincronaDTO> = {
      content: [],
      totalElements: 0,
      pageable: {
        pageNumber: this.DEFAULT_PAGE_NUMBER,
        pageSize: this.DEFAULT_PAGE_SIZE
      }
  };

  buscando = false;
  
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly emissoesNFeAssincronaService: EmissoesNfeAssincronaService
  ) { }

  ngOnInit() {
    this.buscar();
  }

  buscar() {
    this.emissoesNFeAssincronaService.getEmissoes(
      this.dataInicio, 
      this.dataFim, 
      this.chassi,
      this.emissoes.pageable.pageNumber,
      this.emissoes.pageable.pageSize
    )
    .pipe(finalize(() => {
          this.buscando = false;
    }))
    .subscribe(res => {
      res.content = res.content.map(emissao => ({
          ...emissao,
          statusClass: this.definirClasseStatus(emissao.status)
      }));
      this.emissoes = res;
      this.dataSource.data = this.emissoes.content;
    })
  }

  onChangePagination(event: PageEvent) {
    this.emissoes.pageable.pageNumber = event.pageIndex;
    this.emissoes.pageable.pageSize = event.pageSize;
    this.buscar();
  }

  definirClasseStatus(status: string): string {
    const statusClasses: { [key: string]: string } = {
        'Em processamento': 'andamento-status',
        'Autorizado o uso': 'done-status',
        'Erro sefaz': 'erro-status',
        'Erro interno': 'erro-status'
    };

    return statusClasses[status] || '';
}

  get dataInicio() {
    return this.filtroForm.get('dataInicio').value;
  }

  get dataFim() {
    return this.filtroForm.get('dataFim').value;
  }

  get chassi() {
    return this.filtroForm.get('chassi').value;
  }
}
