import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { MotoMatriz } from 'app/_models/motoMatriz';
import { RestService } from 'app/_services/rest.service';
import { UtilsService } from 'app/_services/utils.service';
import { ModalTransferenciaMultiplaComponent } from '../modal-transferencia-multipla/modal-transferencia-multipla.component';
import { Moto } from 'app/_models/moto';

@Component({
  selector: 'app-transferencia-multipla',
  templateUrl: './transferencia-multipla.component.html',
})
export class TransferenciaMultiplaComponent implements OnInit {
  displayedColumns: string[] = ['select', 'chassi', 'descricao', 'pdv', 'dataMovimentacao', 'detalhes'];
  dataSource: MatTableDataSource<Moto> = new MatTableDataSource();
  selection = new SelectionModel<Moto>(true, []);

  searching: boolean = false;
  selectedMatriz: number;
  value = '';

  constructor(
    private _restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private _utilsService: UtilsService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const dataInicial = params['dataInicial'];
      const dataFinal = params['dataFinal'];
      const tipo = params['tipo'];
      const pdv = JSON.parse(params['pdv']);
      const unidade = JSON.parse(params['unidade']);
      const matriz = params['matriz'];

      const paramsList = [dataInicial, dataFinal, tipo, pdv, unidade, matriz];
      const allParamsPresent = paramsList.every(param => param !== null && param !== undefined);

      this.selectedMatriz = matriz;

      if (allParamsPresent) {
        this.searching = true;
        this._restService.getListaMotosAdm(dataInicial, dataFinal, tipo, pdv, unidade, matriz).subscribe(
          listaMotos => {
            this.dataSource.data = listaMotos;
            this.searching = false;
          },
          error => {
            this.searching = false;
            this._utilsService.alertErro(error);
          }
        );
      }
    });
  }

  getColor(cor) {
    if (cor != undefined && cor.includes('VERME')) {
      return 'red';
    } else if (cor != undefined && cor.includes('BRAN')) {
      return 'gray';
    } else {
      return 'black';
    }
  }

  /** Whether the number of selected elements matches the total number of filtered rows. */
  isAllSelected() {
    const numRows = this.dataSource.filteredData.length;

    const selectedFiltered = this.selection.selected.filter(row =>
      this.dataSource.filteredData.includes(row)
    ).length;

    return selectedFiltered === numRows;
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.dataSource.filteredData.forEach(row => this.selection.deselect(row));
    } else {
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Moto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onCheckboxChange(row: Moto, filterInput: HTMLInputElement) {
    this.selection.toggle(row);

    if (this.selection.isSelected(row)) {
      this.applyFilter('');
      filterInput.value = '';
    }
  }

  openModal(): void {
    const dialogRef = this.dialog.open(ModalTransferenciaMultiplaComponent, {
      data: {
        motos: this.selection.selected,
        matriz: this.selectedMatriz
      }, 
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        window.location.reload();
      }
    });
  }
}
