import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TipoPessoa } from 'app/_models/tipoPessoa';
import { RestService } from 'app/_services/rest.service';

@Component({
  selector: 'app-tipos-pessoa',
  templateUrl: './tipos-pessoa.component.html',
  styleUrls: ['./tipos-pessoa.component.less']
})
export class TiposPessoaComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable, { read: ElementRef, static: true }) private matTableRef: ElementRef;

  displayedColumns: string[] = [];
  dataSource: TipoPessoa[];

  columns: any[] = [
    { field: 'tipo', width: 20 },
    { field: 'descricao', width: 60 },
    { field: 'extratoCompleto', width: 40 },
    { field: 'lancamentosPedidos', width: 40 },
    { field: 'supervisor', width: 40 },
    { field: 'financeiro', width: 40 },
    { field: 'ativo', width: 40 },
    { field: 'acao', width: 40 }
  ];

  pageSizeOptions: number[] = [10, 25, 50, 100];

  pressed = false;
  currentResizeIndex: number;
  startX: number;
  startWidth: number;
  isResizingRight: boolean;
  resizableMousemove: () => void;
  resizableMouseup: () => void;

  constructor(private _restService: RestService, private renderer: Renderer2, private router: Router) {
  }

  ngOnInit() {
    this.getTodosTipoPessoa();
    this.setDisplayedColumns();
  }

  ngAfterViewInit() {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }

  setTableResize(tableWidth: number) {
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const scale = (tableWidth - 5) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }

  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      column.index = index;
      this.displayedColumns[index] = column.field;
    });
  }

  onResizeColumn(event: any, index: number) {
    this.checkResizing(event, index);
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = event.target.parentElement.clientWidth;
    event.preventDefault();
    this.mouseMove(index);
  }

  private checkResizing(event, index) {
    const cellData = this.getCellData(index);
    if ((index === 0) || (Math.abs(event.pageX - cellData.right) < cellData.width / 2 && index !== this.columns.length - 1)) {
      this.isResizingRight = true;
    } else {
      this.isResizingRight = false;
    }
  }

  private getCellData(index: number) {
    const headerRow = this.matTableRef.nativeElement.children[0].querySelector('tr');
    const cell = headerRow.children[index];
    return cell.getBoundingClientRect();
  }

  mouseMove(index: number) {
    this.resizableMousemove = this.renderer.listen('document', 'mousemove', (event) => {
      if (this.pressed && event.buttons) {
        const dx = (this.isResizingRight) ? (event.pageX - this.startX) : (-event.pageX + this.startX);
        const width = this.startWidth + dx;
        if (this.currentResizeIndex === index && width > 50) {
          this.setColumnWidthChanges(index, width);
        }
      }
    });
    this.resizableMouseup = this.renderer.listen('document', 'mouseup', () => {
      if (this.pressed) {
        this.pressed = false;
        this.currentResizeIndex = -1;
        this.resizableMousemove();
        this.resizableMouseup();
      }
    });
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.columns[index].width;
    const dx = width - orgWidth;
    if (dx !== 0) {
      const j = (this.isResizingRight) ? index + 1 : index - 1;
      const newWidth = this.columns[j].width - dx;
      if (newWidth > 50) {
        this.columns[index].width = width;
        this.setColumnWidth(this.columns[index]);
        this.columns[j].width = newWidth;
        this.setColumnWidth(this.columns[j]);
      }
    }
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(document.getElementsByClassName('mat-column-' + column.field));
    columnEls.forEach((el: HTMLDivElement) => {
      el.style.width = column.width + 'px';
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }

  toTipoPessoa(id: number) {
    this.router.navigate(['/tipo-pessoa', id]);
  }

  getTodosTipoPessoa() {
    this._restService.getTodosTipoPessoa().subscribe(
      res => {
        this.dataSource = res;
      }, error => {
        console.error('Erro: ', error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    )
  }
}
