export class Address {

    public zipCode: string;
    public address: string;
    public number: number;
    public complement: string;
    public neighborhood: string;
    public city: string;
    public state: string;
    public country: string;

    constructor(json: any) {
        this.zipCode = json.zipCode;
        this.address = json.address;
        this.number = json.number;
        this.complement = json.complement;
        this.neighborhood = json.neighborhood;
        this.city = json.city;
        this.state = json.state;
        this.country = json.country;
    }

    public get(attribute: string): any {
        return this[attribute];
    }

    public set(attribute: string, value: any) {
        this[attribute] = value;
    }

    public getEmpty() {
      return new Address({
        zipCode: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        country: ''
      });
    }
}
