import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { MenuPerfil } from 'app/_models/menu_perfil';
import { Perfil } from 'app/_models/perfil';
import { RestService } from 'app/_services/rest.service';
import { UtilsService } from 'app/_services/utils.service';

interface MenuTela {
  name: string,
  idWebMenu: number,
  checked: boolean,
  color: string,
  submenus: SubMenus[]
}

interface SubMenus {
  name: string,
  idWebMenu: number,
  checked: boolean,
  color: string
}


@Component({
  selector: 'app-menu-perfil',
  templateUrl: './menu-perfil.component.html',
  styleUrls: ['./menu-perfil.component.less']
})
export class MenuPerfilComponent implements OnInit {
  idPerfil!: number;

  listaMenus = [];
  listaMenusTela: MenuTela[] = [];
  perfil: Perfil;

  allComplete: boolean[] = [];

  constructor(
    private _restService: RestService,
    private _utilsService: UtilsService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.idPerfil = parseInt(this.route.snapshot.params['perfil']);
    this.getPerfil();
    this.getMenus();
  }

  getPerfil() {
    this._restService.getPerfilById(this.idPerfil).subscribe(
      res => {
        this.perfil = res['data'];
      }
    )
  }

  getMenus() {
    this._restService.getMenus().subscribe(
      menus => {
        this.listaMenus = menus['data'];
        this.criarMenuTela(this.listaMenus);
      }, error => {
        this._utilsService.alertErro(error);
      }
    );
  }

  criarMenuTela(listaMenus) {
    let listaSubMenus: SubMenus[] = [];
    for (let m of listaMenus) {
      if (m.pai == null || m.pai == 0) {
        listaSubMenus = [];
        for (let submenu of listaMenus) {
          if (submenu.pai != null && submenu.pai == m.idWebMenu) {
            var sub = {
              name: submenu.menu,
              idWebMenu: submenu.idWebMenu,
              checked: this.checaAtivo(submenu.idWebMenu),
              color: 'primary'
            }
            listaSubMenus.push(sub);
          }
        }
        var menu: any = {
          name: m.menu,
          idWebMenu: m.idWebMenu,
          checked: this.checaAtivo(m.idWebMenu),
          color: 'primary',
          submenus: listaSubMenus
        }
        this.listaMenusTela.push(menu);
      }
    }
    this.checaAllComplete();
  }

  checaAtivo(id: number): boolean {
    const menu = this.listaMenus.find(menu => menu.idWebMenu === id);
    return menu.perfis.some((perfil: any) => perfil.id === this.idPerfil);
  }

  checaAllComplete() {
    let index = 0;
    for (let m of this.listaMenusTela) {
      this.updateAllComplete(index++);
    }
  }

  updateAllComplete(i) {
    this.allComplete[i] = this.listaMenusTela[i].submenus != null && this.listaMenusTela[i].submenus.every(t => t.checked) && (this.listaMenusTela[i].submenus.length > 0 || this.listaMenusTela[i].checked);
  }

  someComplete(i): boolean {
    return this.listaMenusTela[i].submenus.filter(t => t.checked).length > 0 && !this.allComplete[i];
  }

  setAll(checked: boolean, i) {
    this.listaMenusTela[i].checked = checked;
    this.allComplete[i] = checked;
    if (this.listaMenusTela[i].submenus == null || this.listaMenusTela[i].submenus.length == 0) {
      return;
    }
    this.listaMenusTela[i].submenus.forEach(t => t.checked = checked);
  }

  public salvar() {
    var listaMenusSalvar = {
      idWebPerfil: this.idPerfil,
      sistema: 'VALORIZA_WEB',
      menuList: []
    };

    for (let item of this.listaMenusTela) {
      let temsub: boolean = false;
      for (let sub of item.submenus) {
        if (sub.checked) {
          temsub = true;
          listaMenusSalvar.menuList.push(sub.idWebMenu)
        }
      }
      if (item.checked || temsub) {
        listaMenusSalvar.menuList.push(item.idWebMenu)
      }
    }

    this._restService.updateMenuPerfil(listaMenusSalvar);
  }
}