import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { pessoaTipoEnum } from "app/_enums/pessoaTipo.enum";
import { TiposPessoaEnum } from "app/_enums/tiposPessoaEnum";
import { RestService } from "app/_services/rest.service";
import { MatSnackBar } from "@angular/material";
import { CustomValidation } from "app/_utils/form-validations/CustomValidation";
import { Response } from "app/_models/response";
import { PessoaResponse } from "app/_models/pessoa-response";
import { PessoaRequest } from "app/_models/pessoa-request";

@Component({
  selector: "app-me",
  templateUrl: "./me.component.html",
  styleUrls: ["me.component.less"],
})
export class MeComponent implements OnInit {
  isLoading = false;
  meForm: FormGroup;
  pessoaTipoEnum = pessoaTipoEnum;
  tiposPessoaEnum = TiposPessoaEnum;
  me: PessoaResponse;
  hide = true;

  constructor(
    private location: Location,
    private _restService: RestService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this._restService
      .getMe()
      .subscribe((pessoaRes: Response<PessoaResponse>) => {
        this.me = pessoaRes.data;
        this.me.tipoPessoa = pessoaRes.data.tipoPessoa;

        const customValidation = new CustomValidation();
        this.meForm.patchValue(pessoaRes.data);

        if (this.me.tipoPessoa === this.tiposPessoaEnum.FISICA) {
          this.meForm.get("sexo").setValidators([Validators.required]);
          this.meForm
            .get("cpfCnpj")
            .setValidators([
              Validators.required,
              Validators.minLength(11),
              Validators.maxLength(11),
              customValidation.isValidCPF(),
            ]);
        } else if (this.me.tipoPessoa === TiposPessoaEnum.JURIDICA) {
          this.meForm
            .get("cpfCnpj")
            .setValidators([
              Validators.required,
              Validators.minLength(14),
              Validators.maxLength(14),
              customValidation.isValidCNPJ(),
            ]);
        }
      });

    const customValidation = new CustomValidation();
    this.meForm = new FormGroup({
      cpfCnpj: new FormControl("", Validators.required),
      nome: new FormControl(""),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),
      fone: new FormControl(""),
      celular: new FormControl("", [
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(21),
      ]),
      dataNascimento: new FormControl("", [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      sexo: new FormControl(""),
    });
  }

  onSubmit() {
    if (this.meForm.valid) {
      this.isLoading = true;
      const pessoaRequest: PessoaRequest = this.meForm.value;
      this._restService.editarMe(pessoaRequest).subscribe({
        next: () => {
          this._snackBar.open("Perfil alterado com sucesso ✅", "OK", {
            duration: 4000,
          });
          this.location.back();
        },
        error: (error) => {
          this.isLoading = false;
          alert(error.error.message);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    } else {
      Object.keys(this.meForm.controls).forEach((field) => {
        const control = this.meForm.get(field);

        if (control.invalid) {
          Object.keys(control.errors).forEach((errorType) => {
            console.error(`Campo ${field} tem o erro: ${errorType}`);
          });
        }
      });
      this.isLoading = false;
      console.error("Formulário inválido");
      alert("Erro: formulário inválido");
    }
  }

  back(): void {
    this.location.back();
  }
}
