import { FormasPagamentoEnum } from './../_enums/formaPagamento.enum';
import { Moto } from './../_models/moto';
import { Cart } from './../_models/cart';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService } from '../_services/storage.service';
import { RestService } from '../_services/rest.service';
import { UtilsService } from '../_services/utils.service';
import { FormService } from '../_services/form.service';

import { Payment, Product } from '../_models';
import { MotoInfo } from '../_models/motoinfo';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { CreditOnline } from 'app/_models/creditonline';
import { CartaCredito } from 'app/_models/cartacredito';

@Injectable()
export class CompraService {

  constructor(private _storageService: StorageService,
    private _restService: RestService,
    public _utilsService: UtilsService,
    public _formService: FormService,
    private _router: Router) {
  }

  public retomarVenda(webCompra, isPrevenda?, chassiPrevenda?, liberado?, moto?, webContratoId?, idMoto?) {
    // cartUser
    let cart = this._utilsService.getEmptyCart();
    cart.codigoCRM = webCompra.codigoCRM;
    cart.discount = webCompra.desconto;
    cart.entrega = webCompra.entrega;
    cart.dataContrato = webCompra.dataContrato;

    // Pagamentos
    if (webCompra.web_pagamentos != null) {
      for (let i = 0; i < webCompra.web_pagamentos.length; i++) {
        let pagamento = webCompra.web_pagamentos[i];

        cart.payments.push(new Payment({
          id: pagamento.id,
          paymentForm: pagamento.formaPagamento.id,
          valor: pagamento.valor,
          codigoNSU: pagamento.codigoNsu,
          qntParcelas: pagamento.qntParcelas,
          maquineta: pagamento.maquineta,
          banco: pagamento.banco,
          comprovante: pagamento.comprovante,
          editable: (pagamento.id != null) ? false : true,
          removible: (pagamento.conciliado == 'S' || (isPrevenda && (pagamento.formaPagamento.id == FormasPagamentoEnum.DINHEIRO
              || pagamento.formaPagamento.id == FormasPagamentoEnum.CREDITO_PDV))
              || (pagamento.formaPagamento.id == FormasPagamentoEnum.CREDITO_ONLINE
              || (pagamento.formaPagamento.id == FormasPagamentoEnum.LINK_CREDITO && pagamento.id == null)))  ? false : true
        }));

        if (cart.payments[i].paymentForm == FormasPagamentoEnum.CREDITO_ONLINE) {
          cart.payments[i].comprovante = '0';
          cart.payments[i].online = new CreditOnline({
            name_card: pagamento.online.cardHolder,
            pan: pagamento.online.pan,
            mm: pagamento.online.expiryMm,
            yyyy: pagamento.online.expiryYyyy,
            cvv: ''
          });
        }

        if (cart.payments[i].paymentForm == FormasPagamentoEnum.CARTA_CREDITO) {
          cart.payments[i].cartaCredito = new CartaCredito({
            grupo: pagamento.cartaCredito.grupo,
            cota: pagamento.cartaCredito.cota,
            gravame: pagamento.cartaCredito.gravame,
          });
        }
      }
    }

    // Produtos
    for (let i = 0; i < webCompra.produtos.length; i++) {
      cart.products.push(new Product({
        id: webCompra.produtos[i].id,
        descricao: webCompra.produtos[i].descricao,
        valor: 0,
        quantidade: 1,
        imagemBase64: webCompra.produtos[i].imagemBase64
      }));
      if(chassiPrevenda){
        break;
      }
    }

    cart.preVenda = isPrevenda;
    if (chassiPrevenda) {
      const motoInfo: MotoInfo = new MotoInfo({ chassi: chassiPrevenda });
      cart.products[0].motoInfo = motoInfo;
      if(idMoto){
        cart.idMoto = idMoto + '';
      }
    }else if(isPrevenda){
      var prazo = "ND"
      this._restService.buscaPrazo(webContratoId).subscribe(
        res => {
        prazo = res['data'];
      });
      const motoInfo: MotoInfo = new MotoInfo({ chassi: chassiPrevenda, prazoEntrega: prazo });
      cart.products[0].motoInfo = motoInfo;
    }

    cart.client = webCompra.cliente;
    cart.payer = webCompra.pagador;

    if (cart.client.cep != null) {
      cart.client.cep = this._formService.unMask(cart.client.cep);
      cart.client.cep = this._formService.cep(cart.client.cep.substring(0, 8));
    }

    if (cart.client.dataNascimento != null) {
      cart.client.dataNascimento = this._formService.unMask(cart.client.dataNascimento.toString());
      cart.client.dataNascimento = this._formService.dataNascimento((cart.client.dataNascimento.toString()).substring(0, 8));
    }

    if (cart.client.cpfPessoaReferencia != null) {
      cart.client.cpfPessoaReferencia = this._formService.unMask(cart.client.cpfPessoaReferencia);
      cart.client.cpfPessoaReferencia = this._formService.cpf_mask(cart.client.cpfPessoaReferencia);
    }

    // fone
    cart.client.fone = this._formService.formatPhone(cart.client.fone);
    cart.client.celular = this._formService.formatPhone(cart.client.celular);
    cart.client.telefoneReferencia = this._formService.formatPhone(cart.client.telefoneReferencia);

    localStorage.setItem('cartUser', JSON.stringify(cart));

    const self = this;
    this.getCartProducts(cart).then(function (cartPromise: Cart) {
      self._router.navigate(['/pagamento']);
    }).catch(function (error) {
      self._utilsService.alertErro(error);
    });
  }

  public atualizaPagamentos(cart : Cart, web_pagamentos, isPrevenda?){
    cart.payments = [];
    if (web_pagamentos != null) {
      for (let i = 0; i < web_pagamentos.length; i++) {
        let pagamento = web_pagamentos[i];
        cart.payments.push(new Payment({
          id: pagamento.id,
          paymentForm: pagamento.formaPagamento.id,
          valor: pagamento.valor,
          codigoNSU: pagamento.codigoNsu,
          qntParcelas: pagamento.qntParcelas,
          maquineta: pagamento.maquineta,
          banco: pagamento.banco,
          comprovante: pagamento.comprovante,
          conciliado: pagamento.conciliado,
          editable: (pagamento.id != null || pagamento.formaPagamento.id == FormasPagamentoEnum.CREDITO_ONLINE
            || pagamento.formaPagamento.id == FormasPagamentoEnum.LINK_CREDITO
            || pagamento.conciliado == 'S') ? false : true,
          removible: (pagamento.conciliado == 'S' || (isPrevenda && (pagamento.formaPagamento.id == FormasPagamentoEnum.DINHEIRO
              || pagamento.formaPagamento.id == FormasPagamentoEnum.CREDITO_PDV))
              || (pagamento.formaPagamento.id == FormasPagamentoEnum.CREDITO_ONLINE 
              || (pagamento.formaPagamento.id == FormasPagamentoEnum.LINK_CREDITO && pagamento.id == null)))  ? false : true
        }));

        if (cart.payments[i].paymentForm == FormasPagamentoEnum.CREDITO_ONLINE) {
          cart.payments[i].comprovante = '0';
          cart.payments[i].online = new CreditOnline({
            name_card: pagamento.online.cardHolder,
            pan: pagamento.online.pan,
            mm: pagamento.online.expiryMm,
            yyyy: pagamento.online.expiryYyyy,
            cvv: ''
          });
        }

        if (cart.payments[i].paymentForm == FormasPagamentoEnum.CARTA_CREDITO) {
          cart.payments[i].cartaCredito = new CartaCredito({
            grupo: pagamento.cartaCredito.grupo,
            cota: pagamento.cartaCredito.cota,
            gravame: pagamento.cartaCredito.gravame,
          });
        }

      }
      localStorage.setItem('cartUser', JSON.stringify(cart));
    }

  }

  public listaStrProdutos(cart: Cart) {
    let param = '';
    for (const prod of cart.products) {
      param += prod.id + ',';
    }

    return param;
  }

  public getCartProducts(cart: Cart) {
    let self = this;
    const paramProdutos = self.listaStrProdutos(cart);
    return new Promise(function (resolve, reject) {
      self._restService.getCartProducts(paramProdutos).subscribe(
        res => {
          let products = res['data'];
          self._utilsService.printRestResponse('/api/produto/' + paramProdutos, products);

          for (const prod of products) {
            for (const prodCart of cart.products) {
              if (String(prodCart.id) === String(prod.id)) {
                prodCart.tabela = prod.tabela;
                prodCart.valor = prod.valor;
                prodCart.descricao = prod.descricao;
                prodCart.imagemBase64 = prod['imagemBase64'];
                break;
              }
            }
          }

          localStorage.setItem('cartUser', JSON.stringify(cart));
          resolve(cart);
        }, error => {
          reject(error);
        }
      );
    });
  }

  public getCartMoto(cart: Cart) {
    var self = this;
    const paramProdutos = self.listaStrProdutos(cart);
    return new Promise(function (resolve, reject) {
      self._restService.getCartProducts(paramProdutos).subscribe(
        res => {
          let products = res['data'];
          self._utilsService.printRestResponse('/api/produto/' + paramProdutos, products);

          for (const prod of products) {
            for (const prodCart of cart.products) {
              if (String(prodCart.id) === String(prod.id)) {
                prodCart.tabela = prod.tabela;
                prodCart.valor = prod.valor;
                prodCart.descricao = prod.descricao;
                prodCart.imagemBase64 = prod['imagemBase64'];
                break;
              }
            }
          }

          localStorage.setItem('cartUser', JSON.stringify(cart));
          resolve(cart);
        }, error => {
          reject(error);
        }
      );
    });
  }

  public checkIfProductActiveToCart(paramProdutos: String) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.checkProductActive(paramProdutos).subscribe(
        res => {
          let active = res['data'];
          self._utilsService.printRestResponse('/api/produto/ativo/' + paramProdutos, active);
          if (active) {
            let cart: Cart = self._utilsService.getEmptyCart();
            cart.products.push(new Product({ id: String(paramProdutos), quantidade: 1, valor: 0, descricao: paramProdutos, imagemBase64: '/default.png' }));
            self._storageService.setItem('cartUser', JSON.stringify(cart));
            resolve(cart);
          } else {
            reject(paramProdutos);
          }
          return;
        }, error => {
          reject(error);
        }
      );
      console.log(12)
    });
  }

  public checkIfMotoActiveToCart(moto: Moto) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.checkMotoAtiva(moto.id).subscribe(
        res => {
          let active = res['data'];
          self._utilsService.printRestResponse('/api/veiculo/ativo/' + moto.id, active);
          if (active) {
            let cart: Cart = self._utilsService.getEmptyCart();

            cart.products.push(new Product({ id: moto.id, quantidade: 1, valor: moto.valorVenda, descricao: moto.descricao, imagemBase64: moto.imagemBase64 }));
            const motoInfo: MotoInfo = new MotoInfo({ chassi: moto.chassi, prazoEntrega: moto.prazo });
            cart.products[0].motoInfo = motoInfo;

            self._storageService.setItem('cartUser', JSON.stringify(cart));

            resolve(cart);
          } else {
            reject();
          }
          return;
        }, error => {
          reject(error);
        }
      );
    });
  }
}
