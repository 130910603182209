import { Injectable } from '@angular/core';

@Injectable()
export class DataService {

    constructor() {
    }

    formatDataHora(data, hora) {
        var arrayHora = hora.split(':');
        return new Date(data.year, data.month - 1, data.day, arrayHora[0], arrayHora[1]).toString();
    }

    formatData(data) {
        return new Date(data.year, data.month - 1, data.day, data.getHours(), data.getMinutes).toString();
    }

    setarData(data) {
        let hoje = data;
        let mes = hoje.getMonth() + 1;
        let ano = hoje.getFullYear();
        let dia = hoje.getDate()
    
        return { date: { year: ano, month: mes, day: dia } };
      }
}