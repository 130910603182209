export enum Perfis {
    // GESTÃO
    DIRETOR = "ROLE_DI",
    GERENTE_COMERCIAL = "ROLE_GC",
    SUPERVISOR_COMERCIAL = "ROLE_SC",
    GESTOR_FRANQUIA = "ROLE_GF",
    PDV = "ROLE_PV",
    // SETORES
    FINANCEIRO = "ROLE_FI",
    SUPORTE_MOTOS = "ROLE_SM",
    SUPORTE_CERTIFICADO = "ROLE_SD",
    // CONTAS
    FORNECEDOR = "ROLE_FO",
    INDICADOR = "ROLE_IN",
    CLIENTE = "ROLE_CL",
    CONTA_CONCILIACAO = "ROLE_CC",
    CAIXA = "ROLE_CX",
    CONTA_CORRENTE_BANCO = "ROLE_CB",
    CONTROLE_SALDO = "ROLE_CS",
}