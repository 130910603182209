import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {AuthenticationService} from "../_services/authentication.service";
import {Helpers} from "../../helpers";
import { RestService } from "app/_services/rest.service";

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	encapsulation: ViewEncapsulation.None,
})

export class LogoutComponent implements OnInit {

	constructor(
		private _router: Router,
		private _authService: AuthenticationService,
		private restService: RestService
	) {
	}

	ngOnInit(): void {
		Helpers.setLoading(true);
		// reset login status
		this._authService.logout();
		this.restService.limparPermissoes();
		this._router.navigate(['/login']);
	}
}