import { Address } from "./address";

export class Client_PJ {

    public company_name: string;
    public cnpj: string;
    public email;
    public company_phone_1;
    public company_phone_2;
    public contact_name: string;
    public contact_phone: string;
    public address: Address;

    constructor(json: any) {
        this.company_name = json.company_name;
        this.cnpj = json.cnpj;
        this.email = json.email;
        this.company_phone_1 = json.company_phone_1;
        this.company_phone_2 = json.company_phone_2;
        this.contact_name = json.contact_name;
        this.contact_phone = json.contact_phone;
        this.address = json.address;
    }

    public get(attribute: string): any {
        return this[attribute];
    }

    public set(attribute: string, value: any) {
        this[attribute] = value;
    }
}
