import { Product } from './product';
import { Cliente } from './cliente';
import { Payment } from './payment';
import { TipoVenda } from '../_enums/tipoVenda.enum';

export class Cart {

  public client: Cliente;
  public codigoCRM: string;
  public payer: Cliente;

  public products: Product[];
  public payments: Payment[];

  public entrega: number;
  public discount: number;
  public precServico?: number;
  public dataContrato: string;

  public idMoto: string;
  public preVenda: boolean;
  
  public getTipoVenda(): TipoVenda {
    if (this.products[0].motoInfo != undefined) {
      if (this.products[0].motoInfo.chassi != undefined) {
        return TipoVenda.MOTO;
      } else if (this.products[0].motoInfo.prazoEntrega != undefined) {
        return TipoVenda.PREVENDAMOTO;
      }
    } else {
      return TipoVenda.OUTRO;
    }
  }

  public isVendaMoto(): boolean {
    let tipoVenda: TipoVenda = this.getTipoVenda();
    if(tipoVenda == TipoVenda.MOTO || tipoVenda == TipoVenda.PREVENDAMOTO) {
      return true;
    }

    return false;
  }

  public isPreVendaMoto(): boolean {
    let tipoVenda: TipoVenda = this.getTipoVenda();
    if(tipoVenda == TipoVenda.PREVENDAMOTO) {
      return true;
    }

    return false;
  }

  constructor(json: any) {
    this.products = json.products;
    this.discount = json.discount;
    this.payments = json.payments;
    this.client = json.client;
    this.payer = json.payer;
    this.codigoCRM = json.codigoCRM;
    this.entrega = json.entrega;
    this.precServico = json.precServico!;
    this.dataContrato = json.dataContrato;
    this.idMoto = json.idMoto;
    this.preVenda = json.preVenda;
  }

  public get(attribute: string): any {
    return this[attribute];
  }

  public set(attribute: string, value: any) {
    this[attribute] = value;
  }
}
