import { Address } from "./address";

export class Client_PF {

    public name: string;


    constructor(json: any) {
        this.name = json.nome;

    }

    public get(attribute: string): any {
        return this[attribute];
    }

    public set(attribute: string, value: any) {
        this[attribute] = value;
    }
}
