import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header-checkout',
  templateUrl: './header-checkout.component.html',
  styles: []
})
export class HeaderCheckoutComponent implements OnInit {

  @Input() public selectorActive: string;

  constructor() { }

  ngOnInit() {

  }
}
