import { Injectable } from '@angular/core';
import { Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';
import { UtilsService } from '../../_services/utils.service';
import { Perfis } from 'app/_enums/perfis.enum';

@Injectable()
export class RoleGuard implements CanActivateChild {

    constructor(public _authenticationService: AuthenticationService,
        public _utilsService: UtilsService,
        public _router: Router) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // this will be passed from the route config
        // on the data property
        const expectedRole = route.data.expectedRole;

        const tokenPayload = this._utilsService.getTokenPayload();

        if (this._authenticationService.isAuthenticated()) {
            for (var i = 0; i < expectedRole.length; i++) {
                if (tokenPayload["role"] === expectedRole[i])
                    return true;
            }
        }

        return false;
    }
}
