import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { format } from "date-fns";
import { FormattedMovement, Movement, Operation } from "app/_models/movimentacoes";
import { Router, ActivatedRoute } from "@angular/router";
import { MovimentacoesService } from "app/_services/movimentacoes.service";
import {MatTableDataSource} from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';

export interface Option {
    value: string;
    viewValue: string;
  }

@Component({
    selector: "app-movimentacoes",
    templateUrl: "./movimentacoes.component.html",
    styleUrls: ["./movimentacoes.component.less"],
})
export class MovimentacoesComponent implements OnInit {
    movimentacoesForm = new FormGroup({
        searched_value: new FormControl('')
    })
    selected_value = new FormControl('');
    searched_value = new FormControl('');
    options: Option[] = [
        {value: 'historico', viewValue: 'HISTÓRICO'},
        {value: 'valor', viewValue: 'VALOR'},
        {value: 'data_lancamento', viewValue: 'DATA LANÇAMENTO'}
    ]
    
    operations: Operation[];
    isLoading: boolean = false;
    error_message: string;
    error: boolean = false;
    input_type: string;
    dataSource: MatTableDataSource<FormattedMovement> = new MatTableDataSource();
    @ViewChild(MatSort, { static: false }) sort!: MatSort;
    displayedColumns: string[] = ['date', 'history', 'operation_description', 'value', 'acao'];

    constructor(
        private _movimentacoesService: MovimentacoesService,
        private _router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.selected_value.setValue(params.get('selected_value'));
            this.searched_value.setValue(params.get('searched_value'));
            if (this.selected_value.value && this.searched_value.value) {
                this.getInfo();
            }
        });
        this.getOperations();
        this.setSelectValue();
        this.selected_value.valueChanges.subscribe(() => this.selectOption());
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (row: FormattedMovement, columnName: string): string | number => {
            if (columnName === 'date'){
                if (row.date) {
                    const dateAux = row.date.slice(6, 10) + row.date[5] + row.date.slice(3, 5) + row.date[2] + row.date.slice(0, 2);
                    return dateAux;
                }
                return '';
            } else if (columnName === 'history'){
                return row.history? row.history || '' : '';
            } else if (columnName === 'operation_description'){
                return row.operation_description? row.operation_description || '' : '';
            } else if (columnName === 'value'){
                return row.value? row.value || '' : '';
            } else {
                return row[columnName as keyof FormattedMovement] as string;
            }
        };
        this.cdr.detectChanges();
    }

    onSubmit() {
        this.searched_value.setValue(this.searched_value.value.toUpperCase());
        this._router.navigate(['/movimentacoes/', this.selected_value.value, this.searched_value.value]);
    }

    getInfo() {
        this.dataSource.data = [];
        this.error = false;
        this.setSpinner();
        const upperCase_text = this.searched_value.value.toUpperCase();

        this._movimentacoesService
            .getMovements(this.selected_value.value, upperCase_text)
            .subscribe(
                (_response) => {
                    var movements: Movement[] = _response.data;
                    this._movimentacoesService.setMovements(movements);

                    this.dataSource.data = movements.map((movement) => {
                        const date = new Date(movement.data);
                        const FormattedDate = format(date, "DD/MM/YYYY");

                        const description = this.getOperationDescription(
                            this.operations,
                            movement
                        );
                        return {
                            date: FormattedDate,
                            history: movement.historico,
                            operation_description: description,
                            value: movement.valor,
                            movementId: movement.id
                        };
                    });

                    this.setSpinner();
                },
                (error) => {
                    this.setSpinner();
                    console.log(error);
                    this.error = true;
                    this.error_message = error.error.errors[0];
                    this.dataSource.data = [];
                }
            );
    }

    getOperations() {
        this._movimentacoesService.getOperations().subscribe((_response) => {
            this.operations = _response.data;
        });
    }

    getOperationDescription(_operations: Operation[], _movement: Movement) {
        let operation = _operations.find((operation) => {
            return operation.id == _movement.idOperacao;
        });
        return operation.descricao;
    }

    setSpinner() {
        this.isLoading = !this.isLoading;
    }

    selectOption() {
        this.searched_value.setValue("");
        if (this.selected_value.value === "historico") {
            this.input_type = "text";
        } else if (this.selected_value.value === "valor") {
            this.input_type = "number";
        } else {
            this.input_type = "date";
        }
    }    

    setSelectValue() {
        if (this.selected_value.value === null || this.selected_value.value === 'historico'){
            this.selected_value.setValue('historico');
        } else if (this.selected_value.value === 'valor'){
            this.selected_value.setValue('valor');
        } else if (this.selected_value.value === 'data_lancamento'){
            this.selected_value.setValue('data_lancamento');
        }
    }

    editMovement(movementId: number) {
        this._router.navigate(['/movimentacao'], { queryParams: { movementId: movementId } });
    }
}
