import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, NavigationStart, NavigationEnd} from '@angular/router';
import {Helpers} from "./helpers";
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
	title = 'Valloriza';
	globalBodyClass = 'm-page--loading-non-block m-page--fluid m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';

	constructor(private _router: Router, 
		private http: HttpClient) {
	}

	ngOnInit() {
		this._router.events.subscribe((route) => {
			if (route instanceof NavigationStart) {
				Helpers.setLoading(true);
				Helpers.bodyClass(this.globalBodyClass);
			}
			if (route instanceof NavigationEnd) {
				Helpers.setLoading(false);
			}
		});	  
				
		// this.http.get('url').subscribe((permissions) => {
		   //const perm = ["ADMIN", "EDITOR"]; example of permissions
		//   this.permissionsService.loadPermissions(perm);
		//})
	}
}