import { Injectable, Output, EventEmitter } from '@angular/core';

import { RestService } from '../_services/rest.service'
import { UtilsService } from '../_services/utils.service'
import { FormService } from '../_services/form.service'

import { Select2OptionData } from 'ng2-select2';
import { Matriz } from 'app/_models/matrizesAtivas';
import { Unidade } from 'app/_models/unidade';

@Injectable()
export class SelectService {

  constructor(private _restService: RestService,
    public _utilsService: UtilsService,
    public _formService: FormService) {
  }

  public getPessoaPorTipo(tipo: string): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getPessoaPorTipo(tipo).subscribe(
        res => {
          let pessoas: Array<Select2OptionData> = [{ id: 'undefined', text: "Não definido" }];
          pessoas = pessoas.concat(res);

          resolve(pessoas);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getUnidades(): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getUnidades().subscribe(
        res => {
          let unidades: Array<Select2OptionData> = [{ id: 'undefined', text: "Unidade não definida" }];
          unidades = unidades.concat(res['data']);

          resolve(unidades);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getUnidadesInterface(): Promise<Array<Unidade>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getUnidades().subscribe(
        res => {
          let unidades: Array<Unidade> = [];
          unidades = unidades.concat(res['data']);

          resolve(unidades);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getSupervisores(): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getSupervisores().subscribe(
        res => {
          let supervisores: Array<Select2OptionData> = [{ id: 'undefined', text: "Supervisor não definido" }];
          supervisores = supervisores.concat(res['data']);

          resolve(supervisores);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getConvenios(): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getConvenios().subscribe(
        res => {
          let convenios: Array<Select2OptionData> = [{ id: 'undefined', text: "Convênio não definido" }];
          convenios = convenios.concat(res['data']);

          resolve(convenios);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getOperacoesMatriz(convenio: number): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getMatrizes(convenio).subscribe(
        res => {
          let matrizes: Array<Select2OptionData> = [{ id: 'undefined', text: "Não definido" }];
          matrizes = matrizes.concat(res);

          resolve(matrizes);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getOperacoesMatrizAtivas(convenio: number): Promise<Array<Matriz>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getMatrizAtivas(convenio).subscribe(
        res => {
          let matrizes: Array<Matriz> = [];
          matrizes = matrizes.concat(res);

          resolve(matrizes);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getBancos(): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getBancos().subscribe(
        res => {
          let bancos: Array<Select2OptionData> = [{ id: 'undefined', text: "Não definido" }];
          bancos = bancos.concat(res);

          resolve(bancos);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getOperacoesTipo(): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getOperacoesTipo().subscribe(
        res => {
          let operacao: Array<Select2OptionData> = [{ id: 'undefined', text: "Não definido" }];
          operacao = operacao.concat(res);

          resolve(operacao);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getOperacoesSubtipo(): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getOperacoesSubtipo().subscribe(
        res => {
          let operacao: Array<Select2OptionData> = [{ id: 'undefined', text: "Não definido" }];
          operacao = operacao.concat(res);

          resolve(operacao);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getProdutosConvenio(convenioId): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getProdutosConvenio(convenioId).subscribe(
        res => {
          let produtos: Array<Select2OptionData> = [{ id: 'undefined', text: "Não definido" }];
          produtos = produtos.concat(res);

          resolve(produtos);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getBancoOperacoes(): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getBancoOperacoes().subscribe(
        res => {
          let bancos: Array<Select2OptionData> = [{ id: 'undefined', text: "Não definido" }];
          bancos = bancos.concat(res);

          resolve(bancos);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getOperacoesContas(): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getOperacoesContas().subscribe(
        res => {
          let bancos: Array<Select2OptionData> = [{ id: 'undefined', text: "Não definido" }];
          bancos = bancos.concat(res);

          resolve(bancos);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

  public getContasMatriz(id_contrato): Promise<Array<Select2OptionData>> {
    var self = this;
    return new Promise(function (resolve, reject) {
      self._restService.getContasMatriz(id_contrato).subscribe(
        res => {
          let contas: Array<Select2OptionData> = [{ id: 'undefined', text: "Não definido" }];
          contas = contas.concat(res);

          resolve(contas);
          return;
        }, error => {
          reject(error);
        }
      )
    });
  }

}
