import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CadastroClasseProduto, ClasseProduto, Convenio } from 'app/_models/classeProduto';
import { RestService } from 'app/_services/rest.service';
import { UtilsService } from 'app/_services/utils.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

interface Ativo {
  value: 'S' | 'N',
  nome: 'SIM' | 'NÃO'
}

interface Comissoes {
  value: 'PERCENTUAL' | 'VALOR',
  nome: 'PERCENTUAL' | 'VALOR'
}

@Component({
  selector: 'app-classe-produto',
  templateUrl: './classe-produto.component.html',
  styleUrls: ['./classe-produto.component.less']
})
export class ClasseProdutoComponent implements OnInit {
  pathId: number | null = null;

  convenios: Convenio[] = [];
  filteredConvenios!: Observable<Convenio[]>;
  convenioForm = new FormControl('', Validators.required);

  opcoesAtiva: Ativo[] = [
    { value: 'S', nome: 'SIM'},
    { value: 'N', nome: 'NÃO'},
  ]

  opcoesComissao: Comissoes[] = [
    { value: 'PERCENTUAL', nome: 'PERCENTUAL' },
    { value: 'VALOR', nome: 'VALOR' }
  ];

  classeProdutoForm = new FormGroup({
    id: new FormControl(null),
    descricao: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(60)]),
    convenio: this.convenioForm,
    vigencia: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
    ativa: new FormControl('', Validators.required),
    comissaoSupervisor: new FormControl(null, [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
    comissaoGestor: new FormControl(null, [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
    formaComissao: new FormControl('', [Validators.required]),
  });

  constructor(
    private router: Router,
    private _restService: RestService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _utilsService: UtilsService
  ) { }

  goBack() {
    this.router.navigate(['/classes-produtos']);
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id') !== null) {
        const id = params.get('id');
        this.pathId = parseInt(id, 10);
        this.getClasseProduto(this.pathId);
      }
    });
    
    this.applyUpperCase('descricao');
    this.getConvenios();
  }

  getConvenios() {
    this._restService.getAllConvenios().subscribe(
      convenios => {
        this.convenios = convenios;
        this.filteredConvenios = this.convenioForm.valueChanges.pipe(
          startWith(''),
          map((value: string | Convenio) => this._filterConvenio(value))
        );
      }
    )
  }

  displayConvenio(convenio: Convenio): string {
    return convenio && convenio.descricao ? convenio.descricao : '';
  }

  private _filterConvenio(value: string | Convenio): Convenio[] {
    if (!this.convenios || this.convenios.length === 0) {
      return [];
    }

    const filterValue = typeof value === 'string'
      ? value.toLowerCase()
      : value.descricao.toLowerCase();

    return this.convenios.filter(convenio =>
      convenio.descricao.toLowerCase().includes(filterValue)
    );
  }

  onConvenioOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedConvenio = event.option.value as Convenio;
    this.convenioForm.setValue(selectedConvenio);
  }

  applyUpperCase(fieldName: string) {
    const field = this.classeProdutoForm.get(fieldName);
    if (field) {
      field.valueChanges.subscribe(value => {
        if (value && value !== value.toUpperCase()) {
          field.setValue(value.toUpperCase(), { emitEvent: false });
        }
      });
    }
  }

  onSubmit() {
    if (!this.classeProdutoForm.valid) return;

    const formValues = this.classeProdutoForm.value;

    if (this.pathId) {
      this.updateClasseProduto(formValues);
    } else {
      this.addClasseProduto(formValues);
    }
  }

  getClasseProduto(id: number) {
    this._restService.getClasseProduto(id).subscribe(
      res => {
        this.classeProdutoForm.patchValue(res);
      },
      error => {
        console.error('Erro ao carregar dados: ', error);
      }
    );
  }

  addClasseProduto(formValues: ClasseProduto) {
    const payload: CadastroClasseProduto = {
      descricao: formValues.descricao,
      convenio: formValues.convenio,
      vigencia: Number(formValues.vigencia),
      ativa: formValues.ativa,
      comissaoSupervisor: formValues.comissaoSupervisor,
      comissaoGestor: formValues.comissaoGestor,
      formaComissao: formValues.formaComissao
    };

    this._restService.addClasseProduto(payload).subscribe(
      _ => {
        this._snackBar.open("Classe produto cadastrada com sucesso", "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/classes-produtos']);
      },
      error => {
        this._utilsService.alertErro(error);
      }
    );
  }

  updateClasseProduto(formValues: ClasseProduto) {
    const payload: ClasseProduto = {
      id: formValues.id,
      descricao: formValues.descricao,
      convenio: formValues.convenio,
      vigencia: Number(formValues.vigencia),
      ativa: formValues.ativa,
      comissaoSupervisor: formValues.comissaoSupervisor,
      comissaoGestor: formValues.comissaoGestor,
      formaComissao: formValues.formaComissao
    };

    this._restService.updateClasseProduto(payload).subscribe(
      _ => {
        this._snackBar.open("Classe produto atualizado com sucesso", "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/classes-produtos']);
      },
      error => {
        this._utilsService.alertErro(error);
      }
    );
  }
}
