import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from './_services/alert.service';
import { AlertComponent } from './_directives/alert.component';
import { Helpers } from '../helpers';

import { UtilsService } from '../_services/utils.service';
import { environment } from '../../../src/environments/environment';
import { Perfis } from 'app/_enums/perfis.enum';

declare let $: any;

@Component({
  selector: 'app-auth',
  templateUrl: './templates/login.component.html',
  styleUrls: [
    "./templates/login.component.less"
  ]
})

export class AuthComponent implements OnInit {
  loginForm;
  logo = environment.logo;
  userData: any = { login: environment.login, password: environment.password, remember: true };

  loading = false;
  returnUrl: string;

  @ViewChild('alertSignin', { read: ViewContainerRef, static: true }) alertSignin: ViewContainerRef;


  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    private _alertService: AlertService,
    private cfr: ComponentFactoryResolver,
    private _utilsService: UtilsService) { }

  ngOnInit() {
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    console.log(this.returnUrl)
    this._router.navigate([this.returnUrl]);
  }

  signin() {
    this.loading = true;
    this._authService.login(this.userData).subscribe(
      _ => {
        const tokenPayload = this._utilsService.getTokenPayload();
        if (tokenPayload["role"] == Perfis.PDV)
          this._router.navigate([this.returnUrl]);
        else
          this._router.navigate(["/vendas"]);
      },
      error => {
        this.showAlert('alertSignin');

        if (error.status == 400)
          this._alertService.error(JSON.parse(error._body).errors[0]);
        else
          this._alertService.error("Usuário ou senha incorretos");

        this.loading = false;
      });
  }

  showAlert(target) {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(AlertComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

}
