import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../../_models/user';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let currentUser: User;
        let currentUserJson: String = JSON.parse(sessionStorage.getItem('currentUser'));
      if (currentUserJson != null) {
        currentUser = new User(currentUserJson);
        const authReq = request.clone({
          headers: request.headers.set('Content-Type', 'application/json; charset=utf-8').set('Authorization', 'Bearer ' + currentUser.get('token'))
        });
        return next.handle(authReq);
      } else {
        return next.handle(request);
      }
    }
}
