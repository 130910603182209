import { Injectable } from '@angular/core';

@Injectable()
export class ConstantsService {

  public ID_CONVENIO_CERTIFICACAO: number = 3;
  public ID_CONVENIO_MOTOS: number = 5;

  constructor() {
  }
}
