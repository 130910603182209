import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cart } from 'app/_models';
import { CompraService } from 'app/_services/compra.service';
import { SolarService } from 'app/_services/solar.service';
import { StorageService } from 'app/_services/storage.service';

@Component({
  selector: 'app-solar',
  templateUrl: './solar.component.html',
  styles: []
})
export class SolarComponent implements OnInit {
  listKitSolar: any;

  constructor(private solarService: SolarService, private compraService: CompraService, private _storageService: StorageService, private _router: Router) { }

  ngOnInit() {
    this.solarService.getKitSolar().subscribe(res => {
      console.log(res);
      this.listKitSolar = res;
    });
  }

  adicionarCarrinho(id: String): void {
    const self = this;
    console.log(id);
    this.compraService.checkIfProductActiveToCart(id).then((cart: Cart) => {
      console.log(cart);
      self._storageService.setItem('cartUser', JSON.stringify(cart));
      self._router.navigate(['/carrinho']);
    });
  }

}
