import { Address } from './address';

export class CreditOnline {
  public name_card: string;
  public pan: string;
  public mm: string;
  public yyyy: string;
  public cvv: string;

  public isBuyerOwnsCard: boolean;
  public cpf: string;
  public address: Address;

  public authorized: boolean;
  public messageAuthorization: string;

  constructor(card) {
    this.name_card = card.name_card;
    this.pan = card.pan;
    this.mm = card.mm;
    this.yyyy = card.yyyy;
    this.cvv = card.cvv;
    this.cpf = card.cpf;
    this.address = (new Address({})).getEmpty();
    this.isBuyerOwnsCard = true;
    this.authorized = undefined;
    this.messageAuthorization = undefined;
  }

  public getEmpty(): CreditOnline {
    return new CreditOnline({ name_card: '', pan: '', mm: '', yyyy: '', cvv: '',
                              isBuyerOwnsCard: true, authorized: undefined, messageAuthorization: undefined });
  }
}
