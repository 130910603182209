import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { pessoaTipoEnum } from "app/_enums/pessoaTipo.enum";
import { TiposPessoaEnum } from "app/_enums/tiposPessoaEnum";
import { RestService } from "app/_services/rest.service";
import { MatSnackBar } from "@angular/material";
import { CustomValidation } from "app/_utils/form-validations/CustomValidation";
import { Response } from "app/_models/response";
import { UsuarioRequest } from "app/_models/usuario-request";
import { ActivatedRoute } from "@angular/router";
import { VwUsuario } from "app/_models/vw-usuario";

@Component({
  selector: "app-usuario",
  templateUrl: "./usuario.component.html",
  styleUrls: ["usuario.component.less"],
})
export class UsuarioComponent implements OnInit {
  isLoading = false;
  vwUsuarioForm: FormGroup;
  pessoaTipoEnum = pessoaTipoEnum;
  tiposPessoaEnum = TiposPessoaEnum;
  vwUsuario: VwUsuario;
  hide = true;
  idUsuario: number;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private _restService: RestService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      if (params.get("idUsuario") !== null) {
        this.idUsuario = parseInt(params.get("idUsuario"));

        this._restService
          .findByIdVwUsuario(this.idUsuario)
          .subscribe((vwUsuarioRes: Response<VwUsuario>) => {
            console.log(vwUsuarioRes);
            vwUsuarioRes.data.acessaNet =
              vwUsuarioRes.data.acessaNet === "S" ? true : false;
            this.vwUsuario = vwUsuarioRes.data;
            this.vwUsuario.tipoPessoa = vwUsuarioRes.data.tipoPessoa;

            const customValidation = new CustomValidation();
            this.vwUsuarioForm.patchValue(vwUsuarioRes.data);
            this.vwUsuarioForm
              .get("cpfCnpj")
              .setValidators([
                Validators.required,
                Validators.minLength(11),
                Validators.maxLength(11),
                customValidation.isValidCPF(),
              ]);
          });
      }
    });

    const customValidation = new CustomValidation();
    this.vwUsuarioForm = new FormGroup({
      cpfCnpj: new FormControl("", Validators.required),
      senhaNet: new FormControl("", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(25),
      ]),
      acessaNet: new FormControl(true, Validators.required),
    });
  }

  onSubmit() {
    if (this.vwUsuarioForm.valid) {
      this.isLoading = true;
      this.vwUsuarioForm.value.acessaNet = this.vwUsuarioForm.value.acessaNet
        ? "S"
        : "N";
      const usuarioRequest: UsuarioRequest = this.vwUsuarioForm.value;
      console.log(usuarioRequest);
      this._restService.saveVwUsuarioById(this.idUsuario, usuarioRequest).subscribe({
        next: () => {
          this._snackBar.open(`Usuário ${this.vwUsuario.nome} alterado com sucesso ✅`, "OK", {
            duration: 4000,
          });
          this.location.back();
        },
        error: (error) => {
          this.isLoading = false;
          alert(error.error.message);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    } else {
      let camposErros = "";
      Object.keys(this.vwUsuarioForm.controls).forEach((field) => {
        const control = this.vwUsuarioForm.get(field);
        if (control.invalid) {
          Object.keys(control.errors).forEach((errorType) => {
            console.error(`Campo ${field} tem o erro: ${errorType}`);
            camposErros += `\nCampo ${field} tem o erro: ${errorType}`;
          });
        }
      });
      this.isLoading = false;
      console.error("Formulário inválido");
      alert(`Formulário inválido${camposErros}`);
    }
  }

  back(): void {
    this.location.back();
  }
}
