import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning-bar',
  templateUrl: './warning-bar.component.html',
  styleUrls: ['./warning-bar.component.less']
})
export class WarningBarComponent implements OnInit {
  @Input() message = '';

  constructor() { }

  ngOnInit() {
  }

}
