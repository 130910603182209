export enum FormasPagamentoEnum {
  BOLETO = 1,
  CREDITO = 2,
  CREDITO_ONLINE = 3,
  CREDITO_PDV = 4,
  DINHEIRO = 5,
  DEBITO = 6,
  LINK_CREDITO = 7,
  TRANSFERENCIA = 8,
  CARTA_CREDITO = 9,
  VALOR_LIBERADO_FINANCIADO = 10,
  SALDO = 11
}
