import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'app/_services/rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-tipo-pessoa',
  templateUrl: './tipo-pessoa.component.html',
  styleUrls: ['./tipo-pessoa.component.less']
})
export class TipoPessoaComponent implements OnInit {

  pathId = null;
  isLoading = false;

  boolSelect = [
    { value: true, viewValue: 'SIM' },
    { value: false, viewValue: 'NÃO' },
  ];

  perfilGerenciavelSelect = [
    { value: true, viewValue: 'SIM' },
    { value: false, viewValue: 'NÃO' },
  ];

  tipoPessoaForm = new FormGroup({
    id: new FormControl(''),
    tipo: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
    descricao: new FormControl('', Validators.required),
    perfilGerenciavel: new FormControl('', Validators.required),
    perfil: new FormGroup({
      ativo: new FormControl(''),
      extratoCompleto: new FormControl(''),
      lancamentosPedidos: new FormControl(''),
      supervisor: new FormControl(''),
      financeiro: new FormControl(''),
    }),
  });

  constructor(private _restService: RestService, private location: Location,
    private route: ActivatedRoute, private _snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id') !== null) {
        const id = params.get('id');
        this.pathId = id;

        this.getTipoPessoa(parseInt(id));
      }
    });

    this.tipoPessoaForm.get('tipo').valueChanges.subscribe(value => {
      this.tipoPessoaForm.get('tipo').setValue(value.toUpperCase(), { emitEvent: false });
    });

    this.tipoPessoaForm.get('descricao').valueChanges.subscribe(value => {
      this.tipoPessoaForm.get('descricao').setValue(value.toUpperCase(), { emitEvent: false });
    });
  }

  convertToUppercase(controlName: string) {
    const control = this.tipoPessoaForm.get(controlName);
    if (control) {
      control.setValue(control.value.toUpperCase(), { emitEvent: false });
    }
  }

  back() {
    this.location.back();
  }

  onSubmit() {
    if (this.tipoPessoaForm.valid) {
      this.isLoading = true;

      if (this.pathId) {
        this.updateTipoPessoa();
      } else {
        this.addTipoPessoa();
      }
    } else {
      Object.keys(this.tipoPessoaForm.controls).forEach(field => {
        const control = this.tipoPessoaForm.get(field);

        if (control.invalid) {
          Object.keys(control.errors).forEach(errorType => {
            console.error(`Campo ${field} tem o erro: ${errorType}`);
          });
        }
      });
      this.isLoading = false;
      console.error('Formulário inválido');
      alert('Erro: formulário inválido');
    }
  }

  getTipoPessoa(id: number) {
    this._restService.getTipoPessoa(id).subscribe(
      res => {
        this.tipoPessoaForm.patchValue(res);
      }, error => {
        console.error('Erro: ', error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    )
  }

  addTipoPessoa() {
    this._restService.addTipoPessoa(this.tipoPessoaForm.value).subscribe(
      res => {
        console.log("Tipo de pessoa cadastrado com sucesso", res);
        this._snackBar.open("Tipo de pessoa cadastrado com sucesso", "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/tipos-pessoa']);
      }, error => {
        console.error('Erro: ', error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    )
  }

  updateTipoPessoa() {
    this._restService.updateTipoPessoa(this.tipoPessoaForm.value).subscribe(
      res => {
        this._snackBar.open("Tipo de pessoa atualizado com sucesso", 'fechar', {
          duration: 4000,
        });
        this.router.navigate(['/tipos-pessoa']);
      },
      error => {
        console.error('Erro: ', error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    );
  }

  onPerfilGerenciavelChange(event: any) {
    const value = event.value;

    if (!value) {
      this.disableAtivoValidator();
      this.tipoPessoaForm.get('perfil.ativo').setValue(false);
    } else {
      this.setAtivoValidator();
    }
  }

  setAtivoValidator() {
    const ativoControl = this.tipoPessoaForm.get('perfil.ativo');
    if (ativoControl) {
      ativoControl.setValidators([
        Validators.required,
      ]);
      ativoControl.updateValueAndValidity();
    }
  }

  disableAtivoValidator() {
    const ativoControl = this.tipoPessoaForm.get('perfil.ativo');
    if (ativoControl) {
      ativoControl.setValue(false);
      ativoControl.clearValidators();
      ativoControl.updateValueAndValidity();
    }
  }

  onAtivoChange(event: any) {
    const value = event.value;

    if (!value) {
      this.disablePerfilValidators();
    } else {
      this.setPerfilValidators();
    }
  }

  setPerfilValidators() {
    const perfilGroup = this.tipoPessoaForm.get('perfil');
    if (perfilGroup) {
      perfilGroup.get('extratoCompleto').setValidators([
        Validators.required,
      ]);
      perfilGroup.get('lancamentosPedidos').setValidators([
        Validators.required,
      ]);
      perfilGroup.get('supervisor').setValidators([
        Validators.required,
      ]);
      perfilGroup.get('financeiro').setValidators([
        Validators.required,
      ]);
      perfilGroup.updateValueAndValidity();
    }
  }

  disablePerfilValidators() {
    const perfilGroup = this.tipoPessoaForm.get('perfil');
    if (perfilGroup) {
      perfilGroup.get('extratoCompleto').setValue(false);
      perfilGroup.get('lancamentosPedidos').setValue(false);
      perfilGroup.get('supervisor').setValue(false);
      perfilGroup.get('financeiro').setValue(false);

      perfilGroup.get('extratoCompleto').clearValidators();
      perfilGroup.get('lancamentosPedidos').clearValidators();
      perfilGroup.get('supervisor').clearValidators();
      perfilGroup.get('financeiro').clearValidators();
      perfilGroup.updateValueAndValidity();
    }
  }
}
