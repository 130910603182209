import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.less']
})
export class IndexComponent implements OnInit {
  empresa = environment.empresa;

  constructor(private _router: Router) {
    if(this.empresa === 'logvel') {
      _router.navigate(['/logistica']);
    }
  }

  ngOnInit() {
  }

}
