import { MotoInfo } from './motoinfo';
import { ConsultaInfo } from './consultainfo';

export class Product {

    public id: string;
    public tabela: string;
    public descricao: string;
    public valor: number;
    public quantidade: number;
    public imagemBase64: string;

    public consultaInfo: ConsultaInfo;
    public motoInfo: MotoInfo;

    constructor(json: any) {
        this.id = json.id;
        this.tabela = json.tabela;
        this.quantidade = json.quantidade;
        this.valor = json.valor;
        this.descricao = json.descricao;
        this.imagemBase64 = json.imagemBase64;
        this.consultaInfo = json.consultaInfo;
    }
}
