
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';

import { Cep } from "../_models/cep"

import { environment } from '../../environments/environment';

@Injectable()
export class CepService {
  resultado: Cep;

  baseApiPath: string = environment.url_api;

  constructor(private http: HttpClient) { }

  buscar(cep: string): Observable<any> {
    return this.http
      .get(`https://viacep.com.br/ws/${cep}/json/`);
  }

  private converterRespostaParaCep(cepNaResposta): Cep {
    let cep = new Cep();
    cep.cep = cepNaResposta.cep;
    cep.logradouro = cepNaResposta.logradouro;
    cep.complemento = cepNaResposta.complemento;
    cep.bairro = cepNaResposta.bairro;
    cep.cidade = cepNaResposta.localidade;
    cep.estado = cepNaResposta.uf;
    return cep;
  }
}
