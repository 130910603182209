
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";

import { JwtHelperService } from '@auth0/angular-jwt';

import { User } from "../../_models/user";
import { Cart } from "../../_models/cart";

import { UtilsService } from "../../_services/utils.service"

import { environment } from '../../../environments/environment';

@Injectable()
export class AuthenticationService {

  baseApiPath: string = environment.url_api;

  constructor(private http: HttpClient,
    private _utilsService: UtilsService) {
  }

  public isAuthenticated(): boolean {
    let currentUser: User = null;
    let currentUserJson: String = JSON.parse(sessionStorage.getItem('currentUser'));

    if (currentUserJson != null) {
      currentUser = new User(currentUserJson);

      // Check whether the token is expired and return
      // true or false
      const helper = new JwtHelperService();
      return !helper.isTokenExpired(currentUser.get("token"));
    }
    else {
      return false;
    }
  }

  login(userData) {
    let params = JSON.stringify({ login: userData.login, senha: userData.password });
    let headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    })

    return this.http.post(this.baseApiPath + "/api/usuario/auth", params, { headers: headers }).pipe(
      map((res: Response) => {
        let user = res['data'];

        let cart: Cart = this._utilsService.getEmptyCart();
        if (user && user.token) {
          if (!userData.remember) user.remember = "RememberAfterLogin";
          else user.remember = true;

          sessionStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('cartUser', JSON.stringify(cart));
        }
      }));
  }

  logout() {
    // remove user from local storage to log user out
    if (!(sessionStorage.getItem('currentUser') === null)) {
      sessionStorage.removeItem('currentUser');
    }

    if (!(localStorage.getItem('cartUser') === null)) {
      localStorage.removeItem('cartUser');
    }
  }
}
