import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Canal } from 'app/_models/canal';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";
import { RestService } from 'app/_services/rest.service';
import { forkJoin, Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent, MatSnackBar } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { ProdutoNovo } from 'app/_models/produtoNovo';
import { UtilsService } from 'app/_services/utils.service';
import { PoliticaProduto } from 'app/_models/politicaProdutos';

@Component({
  selector: 'app-politica-produto',
  templateUrl: './politica-produto.component.html',
  styleUrls: ['./politica-produto.component.less']
})
export class PoliticaProdutoComponent implements OnInit {
  pathId = null;
  isLoading = false;

  canais: Canal[] = [];
  filteredCanais!: Observable<Canal[]>;
  canalControl = new FormControl('', Validators.required);

  produtos: ProdutoNovo[] = [];
  filteredProdutos!: Observable<ProdutoNovo[]>;
  produtoControl = new FormControl('', Validators.required);

  politicaForm = new FormGroup({
    id: new FormControl(''),
    canal: this.canalControl,
    produto: this.produtoControl,
    valAdesao: new FormControl(0.0),
    valAtivacao: new FormControl(0.0),
  });

  constructor(
    private _restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private _snackBar: MatSnackBar,
    private _utilsService: UtilsService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id') !== null) {
        const id = params.get('id');
        this.pathId = id;

        this.getPolitica(parseInt(id));
      }

      this.getCanaisAndProdutos();
    });
  }

  getCanaisAndProdutos() {
    const canais$ = this._restService.getCanais();
    const produtos$ = this._restService.getAllProdutos();

    forkJoin([canais$, produtos$]).subscribe(([canaisRes, produtosRes]) => {
      this.canais = canaisRes;
      this.filteredCanais = this.canalControl.valueChanges.pipe(
        startWith(''),
        map((value: string | Canal) => this._filterCanais(value))
      );

      this.produtos = produtosRes;
      this.filteredProdutos = this.produtoControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterProdutos(value))
      );
    }, error => {
      console.error('Erro: ', error.error.errors[0]);
      alert(`Erro: ${error.error.errors[0]}`);
    })
  }

  // Controla a visualização de canal no campo de autocomplete
  displayCanal(canal: Canal): string {
    return canal && canal.descricao ? canal.descricao : '';
  }

  // Filtro para o autocomplete de Canal 
  private _filterCanais(value: string | Canal): Canal[] {
    if (!this.canais || this.canais.length === 0) {
      return [];
    }

    const filterValue = typeof value === 'string'
      ? value.toLowerCase()
      : value.descricao.toLowerCase();

    return this.canais.filter(canal =>
      canal.descricao.toLowerCase().includes(filterValue)
    );
  }

  onCanalOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedCanalProduto = event.option.value as Canal;
    this.canalControl.setValue(selectedCanalProduto);
    this.politicaForm.get('canal').setValue(this.canalControl.value);
  }

  // Controla a visualização de canal no campo de autocomplete
  displayProduto(produto: ProdutoNovo): string {
    return produto && produto.descricao ? produto.descricao : '';
  }

  // Filtro para o autocomplete de Produto 
  private _filterProdutos(value: string | ProdutoNovo): ProdutoNovo[] {
    if (!this.produtos || this.produtos.length === 0) {
      return [];
    }

    const filterValue = typeof value === 'string'
      ? value.toLowerCase()
      : value.descricao.toLowerCase();

    return this.produtos.filter(produto =>
      produto.descricao.toLowerCase().includes(filterValue)
    );
  }

  onProdutoOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedProduto = event.option.value as ProdutoNovo;
    this.produtoControl.setValue(selectedProduto);
    this.politicaForm.get('produto').setValue(this.produtoControl.value);
  }

  onSubmit() {
    if (this.politicaForm.valid) {
      this.isLoading = true;
      
      if (this.pathId) {
        this.atualizarPolitica();
      } else {
        this.criarPolitica();
      }
    } else {
      Object.keys(this.politicaForm.controls).forEach(field => {
        const control = this.politicaForm.get(field);

        if (control.invalid) {
          Object.keys(control.errors).forEach(errorType => {
            console.error(`Campo ${field} tem o erro: ${errorType}`);
          });
        }
      });
      this.isLoading = false;
      console.error('Formulário inválido');
      alert('Erro: formulário inválido');
    }
  }

  criarPolitica() {
    this._restService.addPolitica(this.politicaForm.value as PoliticaProduto).subscribe(
      res => {
        console.log('Política cadastrada com sucesso:', res);

        this._snackBar.open("Política cadastrada com sucesso", "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });

        this.router.navigate(['/politica-produtos']);
      }, error => {
        this._utilsService.alertErro(error);
      }
    )
  }

  atualizarPolitica() {
    this._restService.atualizarPolitica(this.politicaForm.value as PoliticaProduto).subscribe(
      _ => {
        this._snackBar.open("Política atualizada com sucesso", "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/politica-produtos']);
      },
      error => {
        this._utilsService.alertErro(error);
      }
    )
  }

  getPolitica(id: number) {
    this._restService.getPoliticaPorId(id).subscribe(
      res => {
        this.politicaForm.patchValue(res);

        this.canalControl.setValue(res.canal);
        this.produtoControl.setValue(res.produto);
      }, error => {
        console.error('Erro: ', error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    )
  }

  back() {
    this.location.back();
  }
}
