import { Location } from "@angular/common";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router} from '@angular/router';
import { RestService } from "../../../../../_services/rest.service";
import { UtilsService } from "../../../../../_services/utils.service";
import { FormService } from "../../../../../_services/form.service";
import { SelectService } from "../../../../../_services/select.service";
import { ConstantsService } from "../../../../../_services/constants.service";
import { Select2OptionData } from "ng2-select2";
import { INgxMyDpOptions } from "ngx-mydatepicker";
import { forkJoin, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { pessoaTipoEnum } from "app/_enums/pessoaTipo.enum";
import { ActivatedRoute } from "@angular/router";
import { startWith, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emptyToNullValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value === '') {
      control.setValue(null, { emitEvent: false });
    }
    return null;
  };
}
@Component({
  selector: "app-operacao-empresa-show",
  templateUrl: "./operacao-empresa-show.component.html",
  styleUrls: ["./operacao-empresa-show.component.less"],
  
})
export class OperacaoEmpresaShowComponent implements OnInit {
  operacoesId: number | null = null;
  pessoaTipoEnum = pessoaTipoEnum;

  // selectedValues: string[];
  public loading = { table: false, rest: false };
  public pdvs: Array<Select2OptionData>;
  public fornecedores:  Array<Select2OptionData>;
  public codigosConta: Array<Select2OptionData>;
  public gestores: Array<Select2OptionData>;
  public matrizesCertificacao: Array<Select2OptionData>;
  public matrizesMoto: Array<Select2OptionData>;
  public cidadesEmissao: Array<Select2OptionData>;
  public convenios: Array<Select2OptionData>;
  public contas: Array<Select2OptionData>;
  public vendedores: Array<Select2OptionData>;
  public operacoesSucessora: Array<Select2OptionData>;

  isLoading = false;

  matrizOperacao = new FormGroup ({
    id: new FormControl(''),
    convenio:  new FormControl('', emptyToNullValidator()),
    linkEmail:  new FormControl('',  emptyToNullValidator()),
    conta: new FormControl('', emptyToNullValidator())
  });

  operacaoEmpresaForm = new FormGroup({
    id: new FormControl(''),
    descricao: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    cnpj: new FormControl(null),
    email: new FormControl(null),
    codContaCaixa: new FormControl('', Validators.required),
    codContaConciliar: new FormControl('', Validators.required),
    codControleSaldos: new FormControl('', Validators.required),
    codGestor: new FormControl('', Validators.required),
    codSupervisorArea: new FormControl('', Validators.required),
    codPdv: new FormControl(null,  emptyToNullValidator()),
    codFornecedor: new FormControl(null, emptyToNullValidator()),
    matrizMoto: new FormControl(null, emptyToNullValidator()),
    matrizCertificacao: new FormControl(null, emptyToNullValidator()),
    nfs: new FormControl(null, emptyToNullValidator()),
    codOperacaoSucessora: new FormControl(null, emptyToNullValidator()),

    matriz: this.matrizOperacao,

    diretor: new FormControl("", Validators.required),
    gerenteComercial: new FormControl("", Validators.required),
    supervisorComercial: new FormControl("", Validators.required),
    gestorFranquia: new FormControl(null, emptyToNullValidator()),
  });


  filteredcodContaCaixa!: Observable<Array<Select2OptionData>>;
  filteredcodContaConciliar!:Observable<Array<Select2OptionData>>;
  filteredPdvIntegracao!:Observable<Array<Select2OptionData>>;
  filteredFornecedorIntegracao!:Observable<Array<Select2OptionData>>;
  filteredMatrizCertificacao!:Observable<Array<Select2OptionData>>;
  filteredMatrizMotos!:Observable<Array<Select2OptionData>>;
  filteredCidadeEmissaoNF!:Observable<Array<Select2OptionData>>;
  filteredConvenios!:Observable<Array<Select2OptionData>>;
  filteredConveniosBoleto!:Observable<Array<Select2OptionData>>;
  filteredCodControleSaldos!: Observable<Array<Select2OptionData>>;
  filteredControleSaldosGestor!: Observable<Array<Select2OptionData>>;
  filteredControlesupervisorArea!: Observable<Array<Select2OptionData>>;
  filteredOperacaoSucessora!: Observable<Array<Select2OptionData>>;
  filteredOptionsDiretor!:  Observable<Array<Select2OptionData>>; //Observable<string[]>;
  filteredOptionsGerenteComercial!:  Observable<Array<Select2OptionData>>;  //Observable<string[]>;
  filteredOptionsSupervisorComercial!:  Observable<Array<Select2OptionData>>; //Observable<string[]>;
  filteredOptionsGestorFranquia!:  Observable<Array<Select2OptionData>>;//Observable<string[]>;

  private searchTermsDiretor = new Subject<string>();
  private searchTermsGerenteComercial = new Subject<string>();
  private searchTermsSupervisorComercial = new Subject<string>();
  private searchTermsGestorFranquia = new Subject<string>();

  public operacaoEmpresa: any = {};

  switchM: boolean = false;

  public msgModal: string = "";

  constructor(
    private route: ActivatedRoute,
    private _restService: RestService,
    private _formService: FormService,
    private _utilsService: UtilsService,
    private _selectService: SelectService,
    private _constantsService: ConstantsService,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      if (params.get("operacoesId") != null) {
        this.operacoesId = parseInt(params.get("operacoesId"));
      } else {
        this.operacoesId = null;
      }
      this.getGestao();
      this.loading.rest = true;
      this.getOperacaoEmpresa();
    });
  }

  getOperacaoEmpresa(){
    const pdvs$ = this._selectService.getPessoaPorTipo(pessoaTipoEnum.PDV);
    const fornecedores$ = this._selectService.getPessoaPorTipo(
      pessoaTipoEnum.FORNECEDOR
    );
    const codigosConta$ = this._selectService.getPessoaPorTipo(
      pessoaTipoEnum.CONTA_QUALQUER
    );
    const matrizesCertificacao$ = this._selectService.getOperacoesMatriz(
      this._constantsService.ID_CONVENIO_CERTIFICACAO
    );
    const matrizesMoto$ = this._selectService.getOperacoesMatriz(
      this._constantsService.ID_CONVENIO_MOTOS
    );
    const cidadesEmissao$ = this._restService.getCidadesNfse();
    const convenios$ = this._selectService.getConvenios();
    const operacoesContas$ = this._selectService.getOperacoesContas();
    const gestores$ = this._selectService.getPessoaPorTipo(
      pessoaTipoEnum.CONTROLE_DE_SALDO
    );
    const vendedores$ = this._selectService.getPessoaPorTipo(
      pessoaTipoEnum.CONTROLE_DE_SALDO
    );
    const operacoesSucessora$ = this._selectService.getUnidades();

    forkJoin([
      pdvs$, 
      fornecedores$,
      codigosConta$,
      matrizesCertificacao$,
      matrizesMoto$,
      cidadesEmissao$,
      convenios$,
      operacoesContas$,
      gestores$,
      vendedores$,
      operacoesSucessora$,
    ]).subscribe(
      ([
        pdvsRes,
        fornecedoresRes,
        codigosContaRes,
        matrizesCertificacaoRes,
        matrizesMotoRes,
        cidadesEmissaoRes,
        conveniosRes,
        operacoesContasRes,
        gestoresRes,
        vendedoresRes,
        operacoesSucessoraRes,
      ]) => {
        this.pdvs = pdvsRes;
        this.fornecedores = fornecedoresRes;
        this.codigosConta = codigosContaRes;
        this.matrizesCertificacao = matrizesCertificacaoRes;
        this.matrizesMoto = matrizesMotoRes;
        this.cidadesEmissao = cidadesEmissaoRes;
        this.convenios = conveniosRes;
        this.contas = operacoesContasRes;
        this.gestores = gestoresRes;
        this.vendedores = vendedoresRes;
        this.operacoesSucessora = operacoesSucessoraRes;

        if (this.operacoesId) {
          this._restService
            .getOperacaoEmpresa(this.operacoesId)
            .subscribe((operacaoEmpresaRes) =>{
              this.verCadastro(operacaoEmpresaRes) ;
              this.loading.rest = false;

            });
            
          }
        /////// definição dos filtros para o autocomplete

        this.filteredPdvIntegracao = this.operacaoEmpresaForm.get('codPdv').valueChanges.pipe(
            startWith(''),
            map((value: any ) => this._filteredPdv(value) ));

        this.filteredFornecedorIntegracao = this.operacaoEmpresaForm.get('codFornecedor').valueChanges.pipe(
          startWith(''),
          map((value: any ) => this._filteredFornecedor(value)));

        this.filteredcodContaCaixa = this.operacaoEmpresaForm.get('codContaCaixa').valueChanges.pipe(
          startWith(''),  // Inicia com o valor atual do controle
          map((value: any ) => this._filteredCodConta(value))  // Aplica a lógica de filtragem
        );

        this.filteredcodContaConciliar = this.operacaoEmpresaForm.get('codContaConciliar').valueChanges.pipe(
          startWith(''),  // Inicia com o valor atual do controle
          map((value: any ) => this._filteredCodConta(value))  // Aplica a lógica de filtragem
        );

        this.filteredMatrizCertificacao = this.operacaoEmpresaForm.get('matrizCertificacao').valueChanges.pipe(
          startWith(''),
          map((value: any ) => this._filteredMatrizCertificao(value)));
        
        this.filteredMatrizMotos = this.operacaoEmpresaForm.get('matrizMoto').valueChanges.pipe(
          startWith(''),
          map((value: any ) => this._filteredMatrizMotos(value))
        );

        this.filteredCidadeEmissaoNF = this.operacaoEmpresaForm.get('nfs').valueChanges.pipe(
          startWith(''),
          map((value: any ) => this._filteredcidadeEmissao(value))
        );

        this.filteredConvenios = this.operacaoEmpresaForm.get('matriz.convenio').valueChanges.pipe(
          startWith(''),
          map((value: any ) => this._filteredConvenios(value))
        );

        this.filteredConveniosBoleto = this.operacaoEmpresaForm.get('matriz.conta').valueChanges.pipe(
          startWith(''),
          map((value: any ) => this._filteredConveniosBoleto(value))
        );

        this.filteredControleSaldosGestor = this.operacaoEmpresaForm.get('codGestor').valueChanges.pipe(
          startWith(''),
          map((value: any ) => this._filteredControleSaldosGestor(value))
        );

        this.filteredCodControleSaldos = this.operacaoEmpresaForm.get('codControleSaldos').valueChanges.pipe(
          startWith(''),
          map((value: any ) => this._filteredControleSaldos(value))
        );

        this.filteredOperacaoSucessora = this.operacaoEmpresaForm.get('codOperacaoSucessora').valueChanges.pipe(
          startWith(''),
          map((value: any ) => this._filteredOperacaoSucessora(value))
        );

        this.filteredControlesupervisorArea = this.operacaoEmpresaForm.get('codSupervisorArea').valueChanges.pipe(
          startWith(''),
          map((value: any ) => this._filteredSupervisorArea(value))
        );



      }, error =>{
        console.log(error)
      })
  }

  private _filteredPdv(value:any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

  return this.pdvs.filter(option => 
    option.text.toLowerCase().includes(filterValue)
  );
  }

  private _filteredFornecedor(value:any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.fornecedores.filter(option => 
    option.text.toLowerCase().includes(filterValue)
    );
  }

  private _filteredCodConta(value: any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.codigosConta.filter(option => 
    option.text.toLowerCase().includes(filterValue)
    );
  }

  private _filteredMatrizCertificao(value:any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.matrizesCertificacao.filter(option => 
    option.text.toLowerCase().includes(filterValue)
    );
  }

  private _filteredMatrizMotos(value:any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.matrizesMoto.filter(option => 
    option.text.toLowerCase().includes(filterValue)
    );
  }

  private _filteredcidadeEmissao(value:any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.cidadesEmissao.filter(option => 
    option.text.toLowerCase().includes(filterValue)
    );
  }

  private _filteredConvenios(value:any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.convenios.filter(option => 
    option.text.toLowerCase().includes(filterValue)
    );
  }

  private _filteredConveniosBoleto (value:any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.contas.filter(option => 
    option.text.toLowerCase().includes(filterValue)
    );
  }

  private _filteredControleSaldosGestor (value:any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.gestores.filter(option => 
    option.text.toLowerCase().includes(filterValue)
    );
  }

  private _filteredControleSaldos (value:any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.vendedores.filter(option => 
    option.text.toLowerCase().includes(filterValue)
    );
  }

  private _filteredSupervisorArea (value:any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.vendedores.filter(option => 
    option.text.toLowerCase().includes(filterValue)
    );
  }

  private _filteredOperacaoSucessora (value:any){
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.operacoesSucessora.filter(option => 
    option.text.toLowerCase().includes(filterValue)
    );
  }

  //AUTOCOMPLETE E DISPLAY  DO FORMULARIO OPERACAO EMPRESA E MATRIZ

  onCodigoContaSelected(event: MatAutocompleteSelectedEvent){
    const selectedCodigoConta = event.option.value;
    this.operacaoEmpresaForm.get('codContaCaixa').setValue(selectedCodigoConta);
  }

  displayCodigoConta(codContaCaixa: any){
    return codContaCaixa && codContaCaixa.text? codContaCaixa.text: '';
  }

  oncodContaConciliarSelected(event: MatAutocompleteSelectedEvent){
    const selectedCodigoConta = event.option.value;
    this.operacaoEmpresaForm.get('codContaConciliar').setValue(selectedCodigoConta);
  }

  displayCodigoContaConciliar(codContaConciliar: any){
    return codContaConciliar && codContaConciliar.text? codContaConciliar.text: '';
  }

  onCodControleSaldosSelected(event: MatAutocompleteSelectedEvent){
    const selectedControleSaldo = event.option.value;
    this.operacaoEmpresaForm.get('codControleSaldos').setValue(selectedControleSaldo);
  }

  displayControleSaldos(codControleSaldos: any){
    return codControleSaldos && codControleSaldos.text? codControleSaldos.text: '';
  }

  onControleSaldosGestorSelected(event: MatAutocompleteSelectedEvent){
    const selectedControleSaldo = event.option.value;
    this.operacaoEmpresaForm.get('codGestor').setValue(selectedControleSaldo);
  }

  displayControleSaldosGestor(controleSaldosGestor: any){
    return controleSaldosGestor && controleSaldosGestor.text? controleSaldosGestor.text: '';
  }

  onSupervisorAreaSelected(event: MatAutocompleteSelectedEvent){
    const selectedsupervisorArea = event.option.value;
    this.operacaoEmpresaForm.get('codSupervisorArea').setValue(selectedsupervisorArea);
  }

  displaySupervisorArea(supervisorArea: any){
    return supervisorArea && supervisorArea.text? supervisorArea.text: '';
  }

  onPdvIntegracaoSelected(event: MatAutocompleteSelectedEvent){
    const selectedControleSaldo = event.option.value;
    this.operacaoEmpresaForm.get('codPdv').setValue(selectedControleSaldo);
  }

  displaypdvIntegracao(pdv: any){
    return pdv && pdv.text? pdv.text: '';
  }

  onFornecedorIntegracaoSelected(event: MatAutocompleteSelectedEvent){
    const selectedFornecedorIntegracao = event.option.value;
    this.operacaoEmpresaForm.get('codFornecedor').setValue(selectedFornecedorIntegracao);
  }

  displayFornecedorIntegracao(fornecedor: any){
    return fornecedor && fornecedor.text? fornecedor.text: '';
  }
  
  onMatrizMotosSelected(event: MatAutocompleteSelectedEvent){
    const selectedMatrizMotos = event.option.value;
    this.operacaoEmpresaForm.get('matrizMoto').setValue(selectedMatrizMotos);
  }

  displayMatrizMotos(matriz: any){
    return matriz && matriz.text? matriz.text: '';
  }

  onMatrizCertificaoSelected(event: MatAutocompleteSelectedEvent){
    const selectedMatrizCertificao = event.option.value;
    this.operacaoEmpresaForm.get('matrizCertificacao').setValue(selectedMatrizCertificao);
  }

  displayMatrizCertificao(matriz: any){
    return matriz && matriz.text? matriz.text: '';
  }

  onCidadeEmissaoSelected(event: MatAutocompleteSelectedEvent){
    const selectedCidadeEmissao= event.option.value;
    this.operacaoEmpresaForm.get('nfs').setValue(selectedCidadeEmissao);
  }

  displayCidadeEmissao(cidade: any){
    return cidade && cidade.text? cidade.text: '';
  }

  onOperacaoSucessoraSelected(event: MatAutocompleteSelectedEvent){
    const selectedOperacaoSucessora = event.option.value;
    this.operacaoEmpresaForm.get('codOperacaoSucessora').setValue(selectedOperacaoSucessora);
  }

  displayOperacaoSucessora(operacao: any){
    return operacao && operacao.text? operacao.text: '';
  }

  onConveniosSelected(event: MatAutocompleteSelectedEvent){
    const selectedConvenios = event.option.value;
    this.operacaoEmpresaForm.get('matriz.convenio').setValue(selectedConvenios);
  }

  displayConvenios(convenio: any){
    return convenio && convenio.text? convenio.text: '';
  }

  onConveniosBoletosSelected(event: MatAutocompleteSelectedEvent){
    const selectedConveniosBoletos = event.option.value;
    this.operacaoEmpresaForm.get('matriz.conta').setValue(selectedConveniosBoletos);
  }

  displayConveniosBoletos(boleto: any){
    return boleto && boleto.text? boleto.text: '';
  }
  

  /////////////////////////////////////////////////////////////////////////////////////
  getGestao() {
    this.filteredOptionsDiretor = this.searchTermsDiretor.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((term: string) =>
        this._restService.buscarPessoaSelect(term).map((item) => item.results)
      )
    );
    this.filteredOptionsGerenteComercial =
      this.searchTermsGerenteComercial.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap((term: string) =>
          this._restService.buscarPessoaSelect(term).map((item) => item.results)
        )
      );
    this.filteredOptionsSupervisorComercial =
      this.searchTermsSupervisorComercial.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap((term: string) =>
          this._restService.buscarPessoaSelect(term).map((item) => item.results)
        )
      );
    this.filteredOptionsGestorFranquia = this.searchTermsGestorFranquia.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((term: string) =>
        this._restService.buscarPessoaSelect(term).map((item) => item.results)
      )
    );
  }

  searchGestao(text: string, tipo: string): void {
    if (text.length > 2) {
      const term = `texto=${text.toUpperCase()}&all=${tipo}`;
      switch (tipo) {
        case pessoaTipoEnum.DIRETOR:
          this.searchTermsDiretor.next(term);
          break;
        case pessoaTipoEnum.GERENTE_COMERCIAL:
          this.searchTermsGerenteComercial.next(term);
          break;
        case pessoaTipoEnum.SUPERVISOR_COMERCIAL:
          this.searchTermsSupervisorComercial.next(term);
          break;
        case pessoaTipoEnum.GESTOR_FRANQUIA:
          this.searchTermsGestorFranquia.next(term);
          break;

        default:
          break;
      }
    }
  }

  displayFn = (pessoa: any): string => {
    return pessoa && pessoa.text ? pessoa.text : "";
  };


  public verCadastro(operacaoEmpresa: any) {
    // Resetar o formulário antes de preenchê-lo
    this.operacaoEmpresaForm.reset();

    // Atribuir a operacaoEmpresa recuperada do backend
    this.operacaoEmpresa = operacaoEmpresa;

    // extrair e mapear valores do objeto retornado pelo backend para o formulário
    const id = this.operacaoEmpresa.id;
    const descricao = this.operacaoEmpresa.descricao;
    const cnpj =  this.operacaoEmpresa.cnpj;
    const email = this.operacaoEmpresa.email || '';
    const diretor = this.operacaoEmpresa.diretor  || '';
    const gerenteComercial = this.operacaoEmpresa.gerenteComercial;
    const supervisorComercial = this.operacaoEmpresa.supervisorComercial  || '';
    const gestorFranquia = this.operacaoEmpresa.gestorFranquia || null;
    const codFornecedor = this.operacaoEmpresa.codFornecedor;
    const codPdv = this.operacaoEmpresa.codPdv;
    const codContaCaixa = this.operacaoEmpresa.codContaCaixa;
    const codContaConciliar = this.operacaoEmpresa.codContaConciliar
    const codControleSaldos = this.operacaoEmpresa.codControleSaldos;
    const codGestor = this.operacaoEmpresa.codGestor;
    const codSupervisorArea = this.operacaoEmpresa.codSupervisorArea;
    const matrizMoto = this.operacaoEmpresa.matrizMoto;
    const matrizCertificacao = this.operacaoEmpresa.matrizCertificacao;
    const nfs = this.operacaoEmpresa.nfs;
    const codOperacaoSucessora = this.operacaoEmpresa.codOperacaoSucessora;
  
    const matriz = this.operacaoEmpresa.matriz;

    if (matriz && matriz.convenio) {
      // Mapeia o objeto de convênio para o formato esperado, dtoGenérico
      matriz.convenio = {
          id: matriz.convenio.id,
          text: matriz.convenio.descricao || '' 
        };
    }

    if (matriz && matriz.conta){
      matriz.conta = {
        id: matriz.conta.id,
        text: matriz.conta.nomeCedente || ''
      };
    }

    this.operacaoEmpresaForm.patchValue({
        id,
        descricao,
        cnpj,
        email,
        codContaCaixa,
        codContaConciliar,
        codControleSaldos,
        codGestor,
        codSupervisorArea,
        codPdv,
        codFornecedor,
        matrizMoto,
        matrizCertificacao,
        nfs,
        codOperacaoSucessora,
        diretor,
        gerenteComercial,
        supervisorComercial,
        gestorFranquia
    });

    this.operacaoEmpresaForm.get('matriz').patchValue({
      convenio: matriz.convenio,
      conta: matriz.conta,
      linkEmail: matriz.linkEmail
    });

    console.log("forms matriz ", this.operacaoEmpresaForm.get('matriz').value)

    if (matriz.convenio.id !== null || matriz.conta.id !== null){
      this.switchM = true;
    } else {
      this.switchM = false
    }

    // rola ate o topo da página
    scrollTo(0, 0);
    // vai foçaar detecção de mudanças caso o Angular não perceba a atualização
    this.cdr.detectChanges();
}

  OnSubmit(){
    if(!this.operacaoEmpresaForm.valid){
      return;
    }

    if (this.operacoesId){
      this.updateOperacaoEmpresa();
    } else {
      this.addOperacaoEmpresa();
    }
  }

  addOperacaoEmpresa(){
    console.log("cadastro op", this.operacaoEmpresaForm.value)
    this._restService.addOperacaoEmpresa(this.operacaoEmpresaForm.value).subscribe(
      res =>{
        console.log("Operacao empresa cadastrada com sucesso", res);
        this._snackBar.open("Operacao Empresa cadastrada com sucesso", "Fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });

        this.router.navigate(['/operacao-empresa']);
      },  error => {
        this._utilsService.alertErro(error);
      }
    )
  }

  updateOperacaoEmpresa(){
      console.log("update", this.operacaoEmpresaForm.value)
      this._restService.updateOperacaoEmpresa(this.operacaoEmpresaForm.value).subscribe(
      _ =>{
        this._snackBar.open("Operacao Empresa atualizada com sucesso", "fechar", {
          duration:4000,
          panelClass:['success-snackbar']
        });
        this.router.navigate(['/operacao-empresa']);
      }, error => {
          this._utilsService.alertErro(error);
      }
    );
  }


  public keyUpCpfCnpj(valString, event) {
    return this._formService.format(valString);
  }


  formatArrayMsgModal(array) {
    this.msgModal = "";
    for (var i = 0; i < array.length; i++) {
      this.msgModal += array[i] + "\n";
    }

    this.showModal(this.msgModal);
  }

  showModal(msg: string) {
    jQuery.noConflict();
    this.msgModal = msg;
    console.warn(msg);

  }
  

  verLista(): void {
    this.location.back();
  }

  goBack() {
    this.router.navigate(['/operacao-empresa']);
  }
}


