import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';

import { Cart } from '../../../_models/cart';
import { User } from '../../../_models/user';

import { RestService } from '../../../_services/rest.service';
import { UtilsService } from '../../../_services/utils.service';
import { CompraService } from '../../../_services/compra.service';
import { StorageService } from '../../../_services/storage.service';
import { environment } from '../../../../environments/environment';
import { Menu } from 'app/_models/menu';
import { Perfis } from 'app/_enums/perfis.enum';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './header-nav.component.less'
  ]
})
export class HeaderNavComponent implements OnInit {
  logo = environment.logo;
  empresa = environment.empresa;
  public cart: Cart = undefined;
  public currentUser: User;

  readonly permissoes$ = this._restService.controleDeAcesso$;

  permissions: Menu = {
    relatorios: false,
    relatorioDeVendas: false,
    relatorioDeIndicacoes: false,
    oportunidadeDeVendas: false,
    oportunidades: false,
    financeiro: false,
    extrato: false,
    lancamentos: false,
    pesquisar: false,
    conciliacao: false,
    tarifasCartoes: false,
    cadastros: false,
    produtos: false,
    operacaoEmpresa: false,
    menusPorPerfil: false,
    pessoas: false,
    pontoDeVenda: false,
    vendas: false,
    certificadosDigitais: false,
    consultasCadastrais: false,
    motos: false,
    servicosLogisticos: false,
    tipoPessoa: false,
    motos_adm: false,
    importacao: false,
    bancos: false,
    canais: false,
    classes: false,
    convenios: false,
    politicas: false,
  };

  Perfis = Perfis;

  public idContrato: string;

  public roleUser: string;

  constructor(public _utilsService: UtilsService,
              private _storageService: StorageService,
              private _restService: RestService,
              private _compraService: CompraService) 
  {
    this.cart = this._utilsService.getEmptyCart();
  }

  ngOnInit() {
    this.roleUser = <string>this._utilsService.getTokenPayload()['role'];

    this._restService.controleAcesso(this._utilsService.getTipoByRole(this.roleUser));

    // setInterval(function () {
    //   this.cart = JSON.parse(localStorage.getItem('cartUser'));
    // }, 500);

    this.cart = JSON.parse(localStorage.getItem('cartUser'));

    this.currentUser = new User(JSON.parse(sessionStorage.getItem('currentUser')));

    this._storageService.watchStorage().subscribe((data: string) => {});
  }

  updateCartPop() {
    this.cart = JSON.parse(localStorage.getItem('cartUser'));
  }

  retomarVenda() {
    if (this.idContrato != null && this.idContrato !== '') {
      this._restService.checkPreVendaAtiva(this.idContrato).subscribe(
        res => {
          let prevenda = res['data'];
          this._restService.retomarCompra(this.idContrato).subscribe(
            res => {
              let webCompra = res['data'];
              this._compraService.retomarVenda(webCompra, prevenda);
              this.idContrato = null;
            }, error => {
              console.log(error)
              this._utilsService.alertErro(error);
            }
          );
        }, error => {
          console.log(error)
          this._utilsService.alertErro(error);
        }
      );
    }
  }

}
