import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { installmentMovement } from 'app/_models/installments';
import { PlanilhaImportacaoLancamento } from 'app/_models/planilhaImportacaoLancamento';
import { DadosFiltradosImportacaoLancamento } from 'app/_models/dadosFiltradosImportacaoLancamento';
import { map } from 'rxjs/operators';
import { Lancamento } from 'app/_models/lancamento';


@Injectable({
  providedIn: 'root'
})
export class LancamentoService {
  baseApiPath: string = environment.url_api;

  constructor(private http: HttpClient) { }

  postMovementWithInstallments(movements: installmentMovement): Observable<any> {
    const body = movements

    return this.http.post<any>(`${this.baseApiPath}/api/movimentacao/parcelamento`, body);
  }

  obterDadosFiltradosImportacaoLancamentos(dadosImportacao: PlanilhaImportacaoLancamento[]) {
    return this.http.post<DadosFiltradosImportacaoLancamento[]>(`${this.baseApiPath}/api/financeiro/verificar-dados/importacao-lancamento`, dadosImportacao).pipe(
                                                                                                                                                    map(res => res['data']));
  }

  postImportacaoLancamentos(lancamentos: Lancamento[]) {
    return this.http.post<Lancamento[]>(`${this.baseApiPath}/api/financeiro/importacao/gerar-lancamentos`, lancamentos).pipe(map(res => res['data']));
  }
}