import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cart } from 'app/_models';
import { ClasseProduto } from 'app/_models/classe-produto';
import { CompraService } from 'app/_services/compra.service';
import { LogisticaService } from 'app/_services/logistica.service';
import { StorageService } from 'app/_services/storage.service';

@Component({
  selector: 'app-logistica',
  templateUrl: './logistica.component.html',
  styles: []
})
export class LogisticaComponent implements OnInit {
  list: ClasseProduto[] = [];

  constructor(private logisticaService: LogisticaService, private compraService: CompraService, private _storageService: StorageService, private _router: Router) { }

  ngOnInit() {
    this.logisticaService.getLogistica().subscribe((res: any) => {
      console.log(res.data);
      this.list = res.data;
    });
  }

  adicionarCarrinho(id: String): void {
    const self = this;
    console.log(id);
    this.compraService.checkIfProductActiveToCart(id).then((cart: Cart) => {
      console.log(cart);
      self._storageService.setItem('cartUser', JSON.stringify(cart));
      self._router.navigate(['/carrinho']);
    });
  }

}
