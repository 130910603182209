import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-exportation-button',
  templateUrl: './exportation-button.component.html',
  styleUrls: ['./exportation-button.component.less']
})
export class ExportationButtonComponent implements OnInit {
  @Input() dadosExportacao: any[] = [];
  @Input() exportandoDados: boolean = false;
  @Input() tituloBotao: string = '';
  @Output() exportar = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  exportarDados() {
    this.exportar.emit();
  }
}
