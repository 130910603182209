import { Address } from "./address";

export class Client{

    public name: string;
    public cpf_cnpj: string;
    public gender: string;
    public date_of_birth: Date;
    public email: string;
    public phone: string;
    public cell_phone: string;
    public address: Address;
    public contact_name: string;
    public contact_phone: string;

    constructor(json: any) {
        this.name = json.nome;
        this.cpf_cnpj = json.cpfCnpj;
        this.gender = json.sexo;
        this.date_of_birth = json.dataNascimento;
        this.email = json.email;
        this.phone = json.phone;
        this.cell_phone = json.cell_phone;
        this.address = json.address;
        this.contact_name = json.contact_name;
        this.contact_phone = json.contact_phone;
    }

    public get(attribute: string): any {
        return this[attribute];
    }

    public set(attribute: string, value: any) {
        this[attribute] = value;
    }
}
